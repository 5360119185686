import { get_bank_list } from '@/api/commonList';
import { get_company_list, get_company_item } from '@/api/crm-customer';
import { get_we_company_item } from '@/api/erp-bills';
import { edit_invoice, delete_invoice } from '@/api/erp-invoice';
import { DialogFormItem } from '@/components/WeDialog/types';
import { useAppInfo } from '@/hooks/useAppInfo';
import { useJump } from '@/hooks/useJump';
import { appStore } from '@/store/modules/app';
import { findDialogInput, isEmptyData } from '@/utils/common';
import { formatTime } from '@/utils/time';
import { isInt, isNumber, required } from '@/utils/validate';
import round from 'lodash-es/round';
import { unref } from 'vue';

export function useInvoice() {
  const { weCompanyList, currencyList, appInfo, model } = useAppInfo();
  const { jumpToInvoice } = useJump();

  function deleteInvoice(vue) {
    vue.$showAlert('Warning', '确定删除这条发票?', () => {
      delete_invoice({ id: vue.invoice.id }).then(() => {
        appStore.SET_ALERT_CONFIG(null);
        vue.getInvoiceDetail(vue.invoice.id);
      });
    });
  }

  async function editInvoice(vue, mode, pageConfig, invoice, bill_ids: any[] = []) {
    const isAdd = mode === 'add';
    const title = isAdd ? '新增发票' : '编辑发票';
    const weCompanyItmes = unref(weCompanyList)
      .filter((x) => !x.is_history)
      .map((c) => ({ text: c.name, value: c.id }));
    const chinaWeCompanyIds = unref(weCompanyList)
      .filter((x) => x.region_type == model.value.WeCompany.REGION_TYPE.CHINA)
      .map((c) => c.id);
    const bankList = await get_bank_list();
    let weBankList = bankList
      .filter((b) => isEmptyData(b.company_id))
      .map((b) => ({ text: `${b.bf_bank} (${b.bf_account_number})`, value: b.id }));
    let csBankList = bankList
      .filter((b) => isEmptyData(b.we_company_id))
      .map((b) => ({ text: `${b.bf_bank} (${b.bf_account_number})`, value: b.id }));
    const company_list = (await get_company_list({ get_all: true, pick_fields: ['id', 'name'] })).company_list.map(
      (c) => ({ text: c.name, value: c.id })
    );
    let initWeBank = '';
    let initCsBank = '';
    if (invoice && invoice.we_company_id) {
      weBankList = bankList
        .filter((b) => b.we_company_id === invoice.we_company_id)
        .map((b) => ({ text: `${b.bf_bank} (${b.bf_account_number})`, value: b.id }));
      if (invoice.contract && invoice.contract.we_company_bank_id) {
        initWeBank = invoice.contract.we_company_bank_id;
      } else {
        const WeBankList = bankList.filter((b) => b.we_company_id === invoice.we_company_id && !!b.is_collect);
        initWeBank = invoice
          ? invoice.we_bank_id
            ? invoice.we_bank_id
            : WeBankList!.length
            ? WeBankList[0].id
            : ''
          : '';
      }
    }
    if (invoice && invoice.company_id) {
      csBankList = bankList
        .filter((b) => b.company_id === invoice.company_id)
        .map((b) => ({ text: `${b.bf_bank} (${b.bf_account_number})`, value: b.id }));
      const CsBankList = bankList.filter((b) => b.company_id === invoice.company_id && !!b.is_collect);
      initCsBank = invoice ? (invoice.cs_bank_id ? invoice.cs_bank_id : CsBankList.length ? CsBankList[0].id : '') : '';
    }

    const isPrepayItems = [
      { text: 'Normal', value: false },
      { text: 'Prepay', value: true },
    ];
    const isChinaItems = [
      { text: 'China', value: true },
      { text: 'Global', value: false },
    ];
    const categoryList = [
      {
        text: 'Normal',
        value: 'Normal',
      },
      {
        text: 'Special',
        value: 'Special',
      },
      {
        text: 'Virtual',
        value: 'Virtual',
      },
    ];

    let initIsChina = invoice ? ([undefined, null].includes(invoice.is_china) ? true : !!invoice.is_china) : true;
    const initIsPrepay = invoice ? !!invoice.is_prepay : false;
    let initCategory = invoice ? (invoice.is_blank ? 'Virtual' : invoice.is_special ? 'Special' : 'Normal') : 'Normal';
    let initCurrency = null;
    const initTaxRate = invoice ? invoice.tax_rate || (initIsChina ? 6 : 0) : initIsChina ? 6 : 0;
    if (initIsChina && isEmptyData(invoice)) {
      initCategory = 'Special';
    }
    const getDefaultCurrency = () => {
      if (initIsChina) {
        const initCNY = unref(currencyList).filter((x) => x.name === 'CNY');
        return initCNY.length > 0 ? initCNY[0].id : initCurrency;
      } else {
        const initUSD = unref(currencyList).filter((x) => x.name === 'USD');
        return initUSD.length > 0 ? initUSD[0].id : initCurrency;
      }
    };
    initCurrency = invoice && !!invoice.currency_id ? invoice.currency_id : getDefaultCurrency();
    const initDate = invoice
      ? invoice.date
      : pageConfig.type === vue.model.Invoice.TYPE.SUPPLY
      ? null
      : formatTime('now', 'YYYY-MM-DD');

    if (invoice) {
      invoice.date = formatTime(invoice ? invoice.date || Date() : Date(), 'YYYY-MM-DD');
    }

    const computeAmount = (tax_rate, amount) => {
      if (isNaN(Number(tax_rate)) || !amount) {
        return '';
      } else {
        return `${round(amount / (Number(tax_rate) / 100 + 1), 2)}`;
      }
    };

    if (invoice && invoice.it_amount) {
      invoice.et_amount = computeAmount(initTaxRate, invoice.it_amount);
    }
    const canShow = (result, is_china?) => {
      const res = result.invoice_category !== 'Virtual' && pageConfig.type === vue.model.Invoice.TYPE.DEMAND;
      if (is_china === undefined) {
        return res;
      } else if (is_china) {
        return res && result.is_china;
      } else {
        return res && !result.is_china;
      }
    };

    const makeResult = (result) => {
      result.is_blank = result.invoice_category === 'Virtual';
      result.is_special = result.invoice_category === 'Special';
      result.invoice_type = pageConfig.type;
      result.is_purchase = pageConfig.pathSuffix === 'purchase';
      delete result.invoice_category;
      return result;
    };

    let inputs: DialogFormItem[] = [
      {
        type: 'radio',
        label: '是否预付',
        result: 'is_prepay',
        width: 12,
        items: isPrepayItems,
        init: initIsPrepay,
        rules: [
          {
            type: 'boolean',
            required: true,
            trigger: 'change',
            message: '请选择',
          },
        ],
      },
      {
        type: 'select',
        label: '发票种类',
        result: 'invoice_category',
        width: 12,
        items: categoryList,
        init: initCategory,
        rules: [required('string', '请选择发票种类')],
        change: (result, _input, config) => {
          const csAddressInput = findDialogInput(config, 'cs_address');
          const csPhoneInput = findDialogInput(config, 'cs_phone');
          const csBankInput = findDialogInput(config, 'cs_bank_id');
          const csAccountInput = findDialogInput(config, 'cs_account_number');
          const entityInputList = [csAddressInput, csPhoneInput, csBankInput, csAccountInput];

          if (result.invoice_category !== 'Special') {
            entityInputList.forEach((i) => {
              i.rules = [];
            });
          } else {
            entityInputList.forEach((i) => {
              i.rules = [required('string', '请选择')];
            });
          }
        },
      },
      {
        type: 'radio',
        label: '地区',
        result: 'is_china',
        width: 12,
        items: isChinaItems,
        init: initIsChina,
        rules: [
          {
            type: 'boolean',
            required: true,
            trigger: 'change',
            message: '请选择',
          },
        ],
        change: (result) => {
          initIsChina = result.is_china;
          result.currency_id = getDefaultCurrency();
          result.tax_rate = result.is_china ? 6 : 0;
          result.invoice_category = result.is_china ? 'Special' : 'Normal';
          result.et_amount = computeAmount(result.tax_rate, result.it_amount);
        },
      },
      {
        width: 12,
        result: 'date',
        type: 'date',
        label: '发票日期',
        init: initDate,
        rules: [required('string', '请选择发票日期')],
      },
    ];

    const customer_input: DialogFormItem = {
      type: 'auto-select',
      label: '客户主体',
      result: 'company_id',
      items: company_list,
      rules: [required('string', '请选择客户')],
    };
    const webeye_input: DialogFormItem = {
      type: 'auto-select',
      label: '我方主体',
      result: 'we_company_id',
      items: weCompanyItmes,
      rules: [required('string', '请选择Webeye')],
    };
    inputs.push(customer_input);
    if (pageConfig.type === vue.model.Invoice.TYPE.DEMAND) {
      customer_input.change = (result, _input, config) => {
        const entityInput = findDialogInput(config, 'cs_bank_id');
        entityInput.items = bankList
          .filter((b) => b.company_id === result.company_id)
          .map((b) => ({ text: `${b.bf_bank} (${b.bf_account_number})`, value: b.id }));
        const invoice_bank = bankList.filter((b) => b.company_id === result.company_id && !!b.is_collect);
        result.cs_bank_id = invoice_bank.length ? invoice_bank[0].id : '';
        result.cs_account_number = result.cs_bank_id
          ? bankList.filter((b) => b.id === result.cs_bank_id)[0].bf_account_number
          : '';
        get_company_item({ company_id: result.company_id }).then((res) => {
          result.cs_tax_number = res.company.tax_number;
          result.cs_address = res.company.invoice_address;
          result.cs_phone = res.company.invoice_phone;
        });
      };
      inputs = inputs.concat([
        {
          type: 'text',
          label: '客户税号',
          result: 'cs_tax_number',
          width: 12,
          // disabled: true,
          rules: [required()],
          show: (result) => canShow(result, true),
        },
        {
          type: 'text',
          label: '客户电话',
          result: 'cs_phone',
          width: 12,
          // disabled: true,
          rules: initCategory === 'Special' ? [required()] : [],
          show: (result) => canShow(result, true),
        },
        {
          type: 'text',
          label: '客户地址',
          result: 'cs_address',
          // disabled: true,
          rules:
            initCategory === 'Special'
              ? [
                  {
                    type: 'string',
                    required: true,
                    trigger: 'change',
                    message: '请输入',
                  },
                ]
              : [],
        },
        {
          type: 'auto-select',
          label: '客户银行',
          result: 'cs_bank_id',
          width: 24,
          items: csBankList,
          init: initCsBank,
          rules: initCategory === 'Special' ? [required()] : [],
          show: (result) => canShow(result, true),
          change: (result) => {
            result.cs_account_number = bankList.filter((b) => b.id === result.cs_bank_id)[0].bf_account_number;
          },
        },
      ]);
    }
    inputs.push(webeye_input);
    if (pageConfig.type === vue.model.Invoice.TYPE.DEMAND) {
      webeye_input.change = (result, _input, config) => {
        const entityInput = findDialogInput(config, 'we_bank_id');
        entityInput.items = bankList
          .filter((b) => b.we_company_id === result.we_company_id)
          .map((b) => ({ text: `${b.bf_bank} (${b.bf_account_number})`, value: b.id }));
        const invoice_bank = bankList.filter((b) => b.we_company_id === result.we_company_id && !!b.is_collect);
        if (result.we_company_bank_id) {
          result.we_bank_id = result.we_company_bank_id;
        } else {
          result.we_bank_id = invoice_bank.length ? invoice_bank[0].id : '';
        }
        get_we_company_item({ we_company_id: result.we_company_id }).then((res) => {
          result.we_tax_number = res.tax_number;
          result.we_address = res.address;
          result.we_phone = res.phone;
        });
      };
      inputs = inputs.concat([
        {
          type: 'auto-select',
          label: '我方银行',
          result: 'we_bank_id',
          items: weBankList,
          init: initWeBank,
          rules: [required()],
          show: (result) => canShow(result),
        },
        {
          type: 'text',
          label: '我方地址',
          result: 'we_address',
          rules: [required()],
          disabled: true,
          show: (result) => canShow(result, false),
        },
      ]);
    }
    inputs = inputs.concat([
      {
        width: 12,
        result: 'currency_id',
        type: 'select',
        label: '币种',
        items: 'currency',
        init: initCurrency,
        disabled: bill_ids.length > 0,
        rules: [required('string', '请选择币种')],
      },
      {
        type: 'text',
        label: '含税金额',
        result: 'it_amount',
        width: 12,
        rules: [required('string', '请输入含税金额'), isNumber],
        change: (result) => {
          result.et_amount = computeAmount(result.tax_rate, result.it_amount);
        },
      },
      {
        type: 'text',
        label: '税率',
        result: 'tax_rate',
        width: 12,
        suffix: '%',
        init: initTaxRate,
        rules: [required(), isInt],
        change: (result) => {
          result.et_amount = computeAmount(result.tax_rate, result.it_amount);
        },
      },
      {
        type: 'text',
        label: '不含税金额',
        result: 'et_amount',
        width: 12,
        rules: [required(), isNumber],
      },
    ]);
    console.log(inputs);
    if (pageConfig.pathSuffix !== 'purchase') {
      const serviceItems = appInfo.value.key_vals.invoice_service_names.split('\t').map((s) => ({ text: s, value: s }));
      const serviceInput = {
        type: 'auto-select',
        label: '开票品目',
        result: 'service_name',
        width: '12',
        items: serviceItems,
        rules: [required('string', '请选择开票品目')],
        show: (result) => chinaWeCompanyIds.includes(result.we_company_id),
      };
      inputs.push(serviceInput);
    }

    vue.$showDialog({
      title: title,
      width: '1000px',
      labelCol: '120px',
      init: invoice,
      blocks: [
        { type: 'input', value: inputs },
        { type: 'note', label: '描述', result: 'description', width: 24 },
      ],
      callback: (result) => {
        result = makeResult(result);
        result.description = result.description || '';
        delete result.cs_account_number;
        const edit_data = { id: isAdd ? '' : invoice.id, bill_ids: bill_ids, ...result };
        if (invoice.contract) {
          edit_data.contract_id = invoice.contract.id;
        }
        edit_invoice(edit_data).then((res) => {
          appStore.SET_WE_DIALOG(null);
          if (isAdd) {
            jumpToInvoice(res.invoice_id);
          } else {
            vue.getInvoiceDetail(Number(res.invoice_id));
          }
        });
      },
    });
  }
  return {
    editInvoice,
    deleteInvoice,
  };
}
