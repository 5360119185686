import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, createSlots as _createSlots, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hw_detail_table_container card-white-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_we_table = _resolveComponent("we-table")!

  return (!(_ctx.items && _ctx.items.length === 0 && _ctx.hideNoItems))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_we_table, _mergeProps(_ctx.config, {
          dataSource: _ctx.items,
          loading: false,
          title: _ctx.title
        }), _createSlots({
          name: _withCtx(({ record }) => [
            _createVNode(_component_a_button, {
              type: "link",
              onClick: ($event: any) => (_ctx.clickEntity(record))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.entity_name || record.show_name || record.name || ''), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          headerButton: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerButtonConfig, (buttton, index) => {
              return (_openBlock(), _createBlock(_component_a_button, {
                type: "primary",
                key: index,
                onClick: buttton.click
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(buttton.title), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 2
        }, [
          _renderList(_ctx.getColorFieldItem, (column) => {
            return {
              name: column.dataIndex,
              fn: _withCtx(({ text, record }) => [
                _createElementVNode("span", {
                  style: _normalizeStyle({ color: record[column.colorField] })
                }, _toDisplayString(text), 5)
              ])
            }
          })
        ]), 1040, ["dataSource", "title"])
      ]))
    : _createCommentVNode("", true)
}