
  import { defineComponent, ref } from 'vue';
  import { appStore } from '@/store/modules/app';
  import { edit_person, get_company_person_list, remove_person } from '@/api/meta';
  import { required } from '@/utils/validate';
  import { useUser } from '@/hooks/useUser';
  export default defineComponent({
    name: 'EmailCards',
    props: {
      contract: {
        type: Object,
        required: true,
      },
    },
    emits: ['onUpdate'],
    setup() {
      const showBillingEmailIcon = ref<Boolean>(false);
      const { user, isFinance, isGod, isLegal } = useUser();
      return {
        showBillingEmailIcon,
        user,
        isFinance,
        isGod,
        isLegal,
      };
    },
    computed: {
      canEditPerson(): boolean {
        const c = this.contract;
        return [c.owner_id, c.am_id].includes(this.user.id) || this.isGod || this.isLegal || this.isFinance;
      },
    },
    methods: {
      afterEdit() {
        appStore.SET_WE_DIALOG(null);
        appStore.SET_ALERT_CONFIG(null);
        this.$emit('onUpdate');
      },
      getPersonParams() {
        return { entity_type: 'Contract', entity_id: this.contract.id, company_id: this.contract.company_id };
      },
      addPerson() {
        const blocks = [
          {
            type: 'input',
            value: [
              { type: 'text', label: '姓名', result: 'name', rules: [required()] },
              { type: 'text', label: '职务', result: 'title', rules: [required()] },
              { type: 'text', label: '邮箱', result: 'email', rules: [required()] },
              { type: 'text', label: '手机', result: 'phone' },
              { type: 'text', label: '微信', result: 'wechat' },
            ],
          },
        ];
        this.$showDialog({
          title: '新增名片',
          width: '500px',
          blocks: blocks,
          callback: (result) => {
            edit_person({ ...this.getPersonParams(), data: result }).then(() => {
              this.afterEdit();
            });
          },
        });
      },
      async attachPerson() {
        const thisContractPersonIds = this.contract.person_list.map((x) => x.id);
        const personItems: any = await new Promise((re) => {
          get_company_person_list({
            company_id: this.contract.company_id,
          }).then((res) => {
            re(
              res.person_list
                .filter((u) => u.email && !thisContractPersonIds.includes(u.ie))
                .map((u) => ({ text: `${u.name} - ${u.email}`, value: u.id }))
            );
          });
        });
        this.$showDialog({
          title: '关联已有联系人',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'select',
                  result: 'person_ids',
                  mode: 'multiple',
                  items: personItems,
                  label: '联系人',
                  width: 24,
                },
              ],
            },
          ],
          callback: (result) => {
            edit_person({ ...this.getPersonParams(), data: { choose_type: 'choose', ...result } }).then(() =>
              this.afterEdit()
            );
          },
        });
      },
      removePerson(person) {
        this.$showAlert('Warning', 'Are you sure to REMOVE this Card?', () => {
          remove_person({ id: person.id, entity_type: 'Contract', entity_id: this.contract.id }).then(() => {
            this.afterEdit();
          });
        });
      },
    },
  });
