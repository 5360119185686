
  import { BasicColumn, useTable } from '@/components/WeTable';
  import { defineComponent, computed, unref, ref, watch } from 'vue';
  import { get_contract_page } from '@/api/erp-contracts';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useApp } from '@/hooks/useApp';
  import { useUser } from '@/hooks/useUser';
  import { useJump } from '@/hooks/useJump';
  import { useContract } from '@/views/erp/contracts/hooks/useContract';

  export default defineComponent({
    name: 'erpContractsList',
    setup() {
      const { isPurchasePage, isBackupPage, isDemandPage, isSupplyPage } = useApp();
      const { isOne, isAdmin, isGod, hasPermission } = useUser();
      const { model, getMyTeamList, getTeamUserDialogChange, getTfItems, getTeamUsers } = useAppInfo();
      const { jumpToContract } = useJump();
      const ugs_sign_type_items = ref<Recordable[]>([
        { id: '双签', name: '双签' },
        { id: '境内单签', name: '境内单签' },
        { id: '境外单签', name: '境外单签' },
      ]);
      const checked = ref(false);
      let title = ref('');
      if (unref(isPurchasePage)) {
        title.value = '非业务采购合同';
      } else if (unref(isBackupPage)) {
        title.value = '备份类合同';
      } else if (unref(isDemandPage)) {
        title.value = '销售合同';
      } else {
        title.value = '业务采购合同';
      }
      const { addNoBusinessContract, batchEditContract } = useContract();

      const dateRangeOptions = [
        { label: '最近一年', value: 'recent_one_year' },
        { label: '全部', value: 'all' },
      ];
      const filterType = ref('recent_one_year');
      const columns = computed<BasicColumn[]>(() => {
        let columns: BasicColumn[] = [
          {
            title: '部门',
            dataIndex: 'rd_team_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          {
            title: '名称',
            dataIndex: 'name',
            slots: { customRender: 'name' },
            componentType: 'a-input',
            showTop: true,
          },
          {
            title: '币种',
            dataIndex: 'currency__name',
            showTop: true,
            componentType: 'a-select',
          },
          {
            title: '状态',
            dataIndex: 'rd_state_name',
            slots: { customRender: 'rd_state_name' },
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
            showTop: true,
          },
          {
            title: '属性',
            dataIndex: 'rd_property_name',
            showTop: true,
            componentType: 'a-select',
          },
          {
            title: '所属人',
            dataIndex: 'rd_owner_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
            showTop: true,
          },
        ];
        if (isDemandPage.value) {
          columns.splice(4, 0, {
            title: '是否双签',
            dataIndex: 'ugs_sign_type',
            componentType: 'a-select',
          });
        }
        if (!isPurchasePage.value || isBackupPage.value) {
          columns.push({
            title: 'AM',
            dataIndex: 'rd_am_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          });
          columns.push({
            title: '业务线',
            dataIndex: 'rd_bulines',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          });
          if (isDemandPage.value && isSupplyPage.value) {
            columns.push({
              title: '开票方式',
              dataIndex: 'is_manual_invoice',
              componentType: 'a-select',
              format: (text) => {
                return text ? '人工开票' : '自动开票';
              },
              hiddenFilter: true,
            });
          }
          columns.push({
            title: '收/付款时间',
            dataIndex: 'rd_pay_money_time_point',
            componentType: 'a-select',
          });
          columns.push({
            title: '收/付款周期',
            dataIndex: 'rd_pay_money_timespan',
            componentType: 'a-select',
          });
        }
        columns.push({
          title: '客户主体',
          dataIndex: 'rd_company_name',
          componentType: 'a-select',
          componentProps: {
            mode: 'multiple',
          },
        });
        columns.push({
          title: '我方主体',
          dataIndex: 'rd_we_company_name',
          componentType: 'a-select',
          componentProps: {
            mode: 'multiple',
          },
        });
        if (isDemandPage.value) {
          columns.push({
            title: '我方银行',
            dataIndex: 'rd_we_company_bank_name',
            componentType: 'a-input',
          });
          columns.push({
            title: '银行账户',
            dataIndex: 'rd_we_company_bank_number',
            componentType: 'a-input',
          });
        }
        columns.push({
          title: '开始时间',
          dataIndex: 'rd_begin_time',
          componentType: 'a-range-picker',
        });
        columns.push({
          title: '结束时间',
          dataIndex: 'rd_end_time',
          componentType: 'a-range-picker',
        });
        columns = columns.concat([
          {
            title: '状态停留天数',
            dataIndex: 'consume_days',
            componentType: 'we-number-range',
          },
          {
            title: '存在账单',
            dataIndex: 'has_bill',
            componentType: 'a-select',
          },
          {
            title: '过期状态',
            dataIndex: 'rd_overdue_status',
            slots: { customRender: 'rd_overdue_status' },
            defaultHidden: true,
          },
          {
            title: '过期天数',
            dataIndex: 'overdue_days',
            componentType: 'we-number-range',
          },
        ]);

        if (isOne.value) {
          columns.push({
            title: 'Logic Type',
            dataIndex: 'rd_logic_type',
            componentType: 'a-select',
          });
          columns.push({
            title: 'Logic Detail',
            dataIndex: 'logic_detail',
            componentType: 'a-select',
          });
        }
        return columns;
      });
      const moreFunc = computed(() => {
        const funcList = [
          {
            title: '批量修改合同信息',
            click: () => batchEditContract(reload),
            show: () => isDemandPage.value && isGod.value,
          },
        ];
        return funcList.filter((x) => x.show());
      });
      const [register, { reload, getSelectRows, fetchAllNoChange }] = useTable({
        title,
        api: (params: any) => {
          params.is_closed = checked.value;
          params.category = unref(model)?.Contract.CATEGORY.BUSINESS;
          if (unref(isPurchasePage)) {
            params.category = unref(model)?.Contract.CATEGORY.PURCHASE;
          }
          if (unref(isBackupPage)) {
            params.category = unref(model)?.Contract.CATEGORY.BACKUP;
          }
          if (unref(isDemandPage)) {
            params.contract_type = unref(model).Contract.DIRECTION.DEMAND;
          }
          if (unref(isSupplyPage) || unref(isPurchasePage) || unref(isBackupPage)) {
            params.contract_type = unref(model).Contract.DIRECTION.SUPPLY;
          }
          params.fields.push('id');
          params.fields.push('rd_state_color');
          params.fields.push('rd_overdue_status_color');
          params.filter_type = filterType.value;
          return get_contract_page(params);
        },
        columns,
        moreFunc: moreFunc.value,
      });
      watch(checked, () => reload());
      return {
        title,
        register,
        reload,
        fetchAllNoChange,
        getMyTeamList,
        getSelectRows,
        isPurchasePage,
        isBackupPage,
        isDemandPage,
        isSupplyPage,
        isAdmin,
        getTeamUserDialogChange,
        getTfItems,
        jumpToContract,
        model,
        getTeamUsers,
        checked,
        ugs_sign_type_items,
        addNoBusinessContract,
        batchEditContract,
        hasPermission,
        isGod,
        dateRangeOptions,
        filterType,
      };
    },
    methods: {
      editNoBusinessContract() {
        if (this.isPurchasePage) {
          this.addNoBusinessContract('add_purchase', this.reload);
        } else if (this.isBackupPage) {
          this.addNoBusinessContract('add_backup', this.reload);
        }
      },
    },
  });
