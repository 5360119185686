import { computed, unref } from 'vue';
import { useRoute } from 'vue-router';
import { formatTime, getLeftDay } from '@/utils/time';
import { DialogFormItem } from '@/components/WeDialog/types';
import { required, isNonNegativeNumber, isNumber } from '@/utils/validate';
import { appStore } from '@/store/modules/app';
import { get_bill_list } from '@/api/erp-bills';
import { edit_bill, edit_bill_for_adjust, edit_bill_plan_pay_time, batch_edit_media_billing } from '@/api/erp-bills';
import { useJump } from '@/hooks/useJump';
import { isEmptyData } from '@/utils/common';
import { isTest } from '@/utils/const';
import { useAppInfo } from '@/hooks/useAppInfo';

export function useBills() {
  const route = useRoute();
  const isSupplyPage = computed(() => {
    return route.path.includes('supply');
  });
  const isDemandPage = computed(() => {
    return route.path.includes('demand');
  });
  const { jumpToBill } = useJump();
  function initBillTime(bill) {
    const planTime = getLeftDay(bill.pay_money_plan_time);
    bill.pay_money_plan_left_time = planTime.content;
    bill.pay_money_plan_left_time_color = planTime.color;
  }

  function initBillSimple(bill, vue) {
    bill.buline = vue.bulineMap[bill.buline];
    bill.currency = vue.currencyMap[bill.currency_id];

    bill.we_company_short_name = vue.weCompanyMap[bill.we_company_id].short_name;

    // add Exchange Rate and Estimate(transform)
    bill.currency = vue.currencyMap[bill.currency_id];

    bill.analysis_reviewer = vue.userMap[bill.analysis_reviewer_id];
    bill.accountant_reviewer = vue.userMap[bill.accountant_reviewer_id];

    bill.is_normal_bill = !bill.is_parent && !bill.parent_id;
    bill.is_parent_bill = !!bill.is_parent;
    bill.is_sub_bill = !!bill.parent_id;

    bill.is_demand = bill.bill_type === vue.model.Contract.DIRECTION.DEMAND;
    bill.is_supply = bill.bill_type === vue.model.Contract.DIRECTION.SUPPLY;
    bill.type_name = bill.is_supply ? 'Supply' : 'Demand';

    if (bill.buline_id) {
      bill.buline = vue.bulineMap[bill.buline_id];
      bill.show_buline_name = bill.buline.name;
    } else {
      bill.buline = { name: 'NULL', id: 0 };
      bill.show_buline_name = '';
    }

    initBillTime(bill);
  }

  function initBillListSimple(vue, billList) {
    billList.forEach((b) => {
      initBillSimple(b, vue);
    });

    return billList;
  }

  function initBill(vue, bill) {
    initBillSimple(bill, vue);
    if (!isEmptyData(bill.sub_bill_list)) {
      initBillListSimple(vue, bill.sub_bill_list);
    }

    bill.create_time_str = formatTime(bill.create_time);
    bill.update_time_str = formatTime(bill.update_time);

    bill.check_bill_deadline = formatTime(bill.check_bill_deadline, 'YYYY-MM-DD');
    bill.check_bill_finish_time = formatTime(bill.check_bill_finish_time, 'YYYY-MM-DD');
    bill.pay_money_deadline = formatTime(bill.pay_money_deadline, 'YYYY-MM-DD');
    bill.pay_money_finish_time = formatTime(bill.pay_money_finish_time, 'YYYY-MM-DD');
    bill.pay_money_plan_time = formatTime(bill.pay_money_plan_time, 'YYYY-MM-DD');
    bill.pay_money_plan_time_str = formatTime(bill.pay_money_plan_time, 'YYYY-MM-DD (ddd)');
  }
  function editBillForAdjust(vue, bill) {
    const inputs: DialogFormItem[] = [];

    inputs.push({
      type: 'number',
      result: 'invoice_money',
      label: '发票金额',
      width: 24,
      rules: [required() as any, isNonNegativeNumber],
    });
    inputs.push({
      type: 'text',
      result: 'note',
      label: '备注',
      width: 24,
      rules: [required() as any],
    });

    vue.$showDialog({
      title: '编辑账单',
      width: '460px',
      labelCol: '120px',
      init: bill,
      blocks: [{ type: 'input', value: inputs }],
      callback: (result) => {
        edit_bill_for_adjust({ id: bill.id, ...result }).then((res) => {
          appStore.SET_ALERT_CONFIG(null);
          appStore.SET_WE_DIALOG(null);
          if (vue.afterEditBill) {
            vue.afterEditBill(res);
          } else {
            jumpToBill(bill);
          }
        });
      },
    });
  }
  function editBill(vue, bill, disableEst?) {
    const { appInfo } = useAppInfo();
    const inputs: DialogFormItem[] = [];

    let canEditEst = !disableEst;
    if (canEditEst && !vue.isFinance && !bill.is_rebate) {
      const d = new Date();
      d.setMonth(d.getMonth() - 1);
      if (bill.month !== formatTime(d, 'YYYY-MM')) {
        canEditEst = false;
      }
    }
    if (canEditEst && !vue.isFinance && !bill.is_rebate) {
      canEditEst = new Date().getDate() <= Number(unref(appInfo).key_vals.limit_edit_bill_days);
    }

    if (vue.isGod || vue.isAdmin || isTest) {
      canEditEst = true;
    }

    inputs.push({
      type: 'number',
      result: 'estimate_money',
      label: '暂估金额',
      width: 24,
      rules: [isNonNegativeNumber],
      // disabled: !canEditEst || vue.is_DSP_OR_AppMeta,
      disabled: !canEditEst,
    });
    inputs.push({
      type: 'number',
      result: 'invoice_money',
      label: '发票金额',
      width: 24,
      rules: [isNonNegativeNumber],
      // disabled: vue.is_DSP_OR_AppMeta,
    });
    inputs.push({
      type: 'number',
      result: 'cut_money',
      label: '差额',
      width: 24,
      rules: [isNumber],
      disabled: true,
    });
    inputs.push({
      type: 'number',
      result: 'tax_rate',
      label: '税率',
      width: 24,
      rules: [
        isNumber,
        {
          validator: async (_rule, val: any) => {
            if (!isEmptyData(val) && (val >= 1 || val < 0)) {
              return Promise.reject('税率应为小数');
            } else {
              return Promise.resolve();
            }
          },
          trigger: 'change',
        },
      ],
    });
    vue.$showDialog({
      title: '编辑账单',
      width: '460px',
      labelCol: '120px',
      init: bill,
      blocks: [{ type: 'input', value: inputs }],
      change: (result) => {
        let cut_money = 0;
        if (result.estimate_money != null && result.invoice_money != null) {
          cut_money = parseFloat(result.estimate_money) - parseFloat(result.invoice_money);
        }
        if (cut_money !== result.cut_money) {
          result.cut_money = cut_money;
        }
      },
      callback: (result) => {
        edit_bill({ id: bill.id, ...result }).then((res) => {
          appStore.SET_ALERT_CONFIG(null);
          appStore.SET_WE_DIALOG(null);
          if (vue.afterEditBill) {
            vue.afterEditBill(res);
          } else {
            jumpToBill(bill);
          }
        });
      },
    });
  }
  function editPlanPayTime(vue, bill) {
    const inputs: DialogFormItem[] = [];
    inputs.push({
      type: 'date',
      result: 'pay_money_plan_time',
      label: '预收款/付款 时间',
      width: 24,
      init: bill.pay_money_plan_time,
    });
    vue.$showDialog({
      title: '编辑',
      width: '400px',
      init: bill,
      labelCol: '120px',
      blocks: [{ type: 'input', value: inputs }],
      callback: (result) => {
        edit_bill_plan_pay_time({ id: bill.id, ...result }).then((res) => {
          appStore.SET_WE_DIALOG(null);
          if (vue.afterEditBill) {
            vue.afterEditBill(res);
          } else {
            jumpToBill(bill);
          }
          // vue.traceAction('Edit Plan Pay Time');
        });
      },
    });
  }
  const batchEditProductBilling = async (vue, selectedItems, callback, month) => {
    const inputs: DialogFormItem[] = [
      { type: 'text', label: '发票金额', result: 'invoice_money', width: 24 },
      {
        type: 'select',
        label: '状态',
        result: 'status',
        items: [
          { text: 'Checking', value: 'Checking' },
          { text: 'Done', value: 'Done' },
          { text: 'Done, Not To BI', value: 'Done, Not To BI' },
        ],
        width: 24,
      },
    ];
    if (vue.bill) {
      // 在Bill详情页打开的
      const { currencyList } = useAppInfo();
      const currencyItems = unref(currencyList).map((c) => ({ text: c.name, value: c.id }));
      inputs.unshift({
        type: 'select',
        label: '金额币种',
        result: 'data_currency_id',
        init: vue.bill.currency_id,
        items: currencyItems,
      });
    }
    if (vue.isAdmin || vue.isAnalysis) {
      const billRes = await get_bill_list({ fields: ['id', 'name'], filters: [{ month: month }, { parent_id: 0 }] });
      const billItems = billRes.map((x) => ({ text: x.name, value: x.id }));
      inputs.unshift({ type: 'auto-select', label: '账单', result: 'bill_id', items: billItems });
    }

    vue.$showDialog({
      title: `编辑 ${selectedItems.length} 条数据`,
      width: '500px',
      labelCol: '100px',
      blocks: [
        { type: 'input', value: inputs },
        { type: 'tip', value: '注：若没有填写，不会将所选条目的值设为“空”', width: 24 },
      ],
      callback: (result) => {
        batch_edit_media_billing({ month: month, billing_ids: selectedItems.map((x) => x.id), ...result }).then(
          (res) => {
            appStore.SET_WE_DIALOG(null);
            callback(res);
          }
        );
      },
    });
  };
  return {
    isSupplyPage,
    isDemandPage,
    initBillSimple,
    initBillListSimple,
    editPlanPayTime,
    editBill,
    editBillForAdjust,
    initBill,
    batchEditProductBilling,
  };
}
