
  import { BasicTableProps } from '@/components/WeTable';
  import { useUser } from '@/hooks/useUser';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData, pad, generateUUID } from '@/utils/common';
  import { get_archive_list, manage_entity, zip_archive_attach_files } from '@/api/erp-contracts';
  import { defineComponent, ref } from 'vue';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { DialogFormItem } from '@/components/WeDialog/types';
  import { required } from '@/utils/validate';
  import { useJump } from '@/hooks/useJump';
  import { useTable } from '@/components/WeTable';

  export default defineComponent({
    name: 'ERPContractArchive',
    setup() {
      const archiveList = ref<Recordable[]>([]);
      const { hasPermission } = useUser();
      const { jumpToContract } = useJump();
      const { model, getModelItems, countryList, apiRoot } = useAppInfo();
      const columns = [
        {
          title: 'Contract',
          dataIndex: 'contract_name',
          slots: {
            customRender: 'contract_name',
          },
          componentType: 'a-input',
        },
        {
          title: 'Name',
          dataIndex: 'archive_name',
          componentType: 'a-input',
          slots: {
            customRender: 'archive_name',
          },
        },
        {
          title: 'Group',
          dataIndex: 'contract__team__team_group__name',
          componentType: 'a-select',
        },
        {
          title: 'BU',
          dataIndex: 'contract__team__bu__name',
          componentType: 'a-select',
        },
        {
          title: 'Team',
          dataIndex: 'contract__team__name',
          componentType: 'a-select',
        },
        {
          title: '对方主体',
          dataIndex: 'contract__company__name',
          componentType: 'a-select',
        },
        {
          title: '我方主体',
          dataIndex: 'contract__we_company__name',
          componentType: 'a-select',
        },
        {
          title: '附件',
          dataIndex: 'files',
          slots: {
            customRender: 'files',
          },
          hiddenFilter: true,
        },
        {
          title: '是否接入飞书合同',
          dataIndex: 'has_lark_contract',
          componentType: 'a-select',
        },
        { title: 'Location', dataIndex: 'location', componentType: 'a-input' },
        {
          title: 'Archive Type',
          dataIndex: 'archive_type_str',
          componentType: 'a-input',
        },
        {
          title: 'Is Confirmed',
          dataIndex: 'is_confirmed_str',
          componentType: 'a-input',
        },
      ];
      const [register, { getDataSource }] = useTable({
        title: '合同存档',
        columns: columns,
      });
      return {
        archiveList,
        hasPermission,
        model,
        getModelItems,
        countryList,
        jumpToContract,
        apiRoot,
        register,
        getDataSource,
        isEmptyData,
        generateUUID,
      };
    },
    data: () => ({
      tracePage: 'Archives',
    }),
    computed: {
      canEdit(): boolean {
        return this.hasPermission(['RoleArchiveManager']);
      },
      config(): BasicTableProps {
        return {
          title: '合同存档',
          columns: [
            {
              title: 'Contract',
              dataIndex: 'contract_name',
              slots: {
                customRender: 'contract_name',
              },
              componentType: 'a-input',
            },
            {
              title: 'Name',
              dataIndex: 'archive_name',
              componentType: 'a-input',
              slots: {
                customRender: 'archive_name',
              },
            },
            {
              title: 'Group',
              dataIndex: 'contract__team__team_group__name',
              componentType: 'a-select',
            },
            {
              title: 'BU',
              dataIndex: 'contract__team__bu__name',
              componentType: 'a-select',
            },
            {
              title: 'Team',
              dataIndex: 'contract__team__name',
              componentType: 'a-select',
            },
            {
              title: '对方主体',
              dataIndex: 'contract__company__name',
              componentType: 'a-select',
            },
            {
              title: '我方主体',
              dataIndex: 'contract__we_company__name',
              componentType: 'a-select',
            },
            {
              title: '附件',
              dataIndex: 'files',
              slots: {
                customRender: 'files',
              },
              hiddenFilter: true,
            },
            {
              title: '是否接入飞书合同',
              dataIndex: 'has_lark_contract',
              componentType: 'a-select',
            },
            { title: 'Location', dataIndex: 'location', componentType: 'a-input' },
            {
              title: 'Archive Type',
              dataIndex: 'archive_type_str',
              componentType: 'a-input',
            },
            {
              title: 'Is Confirmed',
              dataIndex: 'is_confirmed_str',
              componentType: 'a-input',
            },
          ],
        };
      },
    },
    watch: {},
    methods: {
      pad,
      initData() {
        appStore.SET_ALERT_CONFIG(null);
        appStore.SET_WE_DIALOG(null);
        const fields = this.config.columns
          .filter((x) => !['archive_type_str', 'is_confirmed_str', 'has_lark_contract'].includes(x.dataIndex!))
          .map((x) => x.dataIndex);
        fields.push('contract_id');
        get_archive_list({
          fields: fields,
        }).then((res) => {
          res.archive_list.forEach((x) => {
            x.archive_type_str = this.model.Archive.TYPE[x.archive_type];
            x.is_confirmed_str = x.is_confirmed ? 'Yes' : 'No';
            x.order = x.is_confirmed ? 1 : -1;
            x.is_confirmed = [x.is_confirmed];
          });
          res.archive_list.sort((a, b) => b.id - a.id);
          res.archive_list.sort((a, b) => a.order - b.order);
          this.archiveList = res.archive_list;
        });
      },
      clickItem(archive) {
        const archiveTypeItems = this.getModelItems(this.model.Archive.TYPE);
        const languageItems = this.getModelItems(this.model.Archive.LANGUAGE);
        const countryList = this.countryList.map((c) => ({ text: c.name, value: c.id }));
        const blocks: DialogFormItem[] = [
          {
            type: 'input',
            value: [
              {
                type: 'text',
                label: 'Name',
                result: 'archive_name',
                rules: [required()],
                width: 12,
              },
              {
                type: 'select',
                label: 'Archive Type',
                result: 'archive_type',
                items: archiveTypeItems,
                width: 12,
                rules: [required()],
              },
              {
                type: 'select',
                label: 'Region',
                result: 'country_id',
                items: countryList,
                width: 12,
                rules: [required()],
              },
              {
                type: 'select',
                label: 'Language',
                result: 'language',
                items: languageItems,
                width: 12,
                rules: [required()],
              },
              { type: 'text', label: 'Location', result: 'location', rules: [required()] },
              {
                width: '24',
                type: 'check',
                label: 'Is Confirmed',
                result: 'is_confirmed',
                value: [{ width: '160px;', label: '', value: true }],
                show: (_) => archive.order < 0,
              },
            ],
          },
        ];

        this.$showDialog({
          title: '编辑档案',
          width: '600px',
          init: archive,
          blocks: blocks,
          callback: (result) => {
            if (result.is_confirmed) {
              result.is_confirmed = result.is_confirmed.length > 0;
            }
            manage_entity({
              meta: { method: 'edit', entity: 'Archive', entity_id: archive.id },
              data: result,
            }).then(() => {
              this.initData();
            });
          },
        });
      },
      downloadArchiveAttachFile(file_name, file_path) {
        return window.open(`${this.apiRoot}/get_oss_file/?file_name=${file_name}&file_path=${file_path}`, '_blank');
      },
      downloadArchiveAttachFiles() {
        const uuid = this.generateUUID();
        let downloadFiles = [];
        this.getDataSource().forEach((x) => {
          downloadFiles = downloadFiles.concat(x.files.filter((f) => !isEmptyData(f.path)));
        });
        zip_archive_attach_files({ files: downloadFiles, uuid: uuid }).then((res) => {
          this.$showOperLog({
            operation_group: res.operation_group,
            start_content: '开始处理',
            end_content: '处理结束',
            footerButtons: [
              {
                name: '下载压缩包',
                click: () => {
                  window.open(`${this.apiRoot}/download_archive_attach_files?uuid=${uuid}`, '_blank');
                },
              },
            ],
            afterClose: () => {
              this.initData();
            },
          });
        });
      },
    },
    created() {
      this.initData();
    },
  });
