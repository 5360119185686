
  import { get_tt_rebate_config_region_category } from '@/api/app';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { copyObjectToReactive, isEmptyData } from '@/utils/common';
  import { required } from '@/utils/validate';
  import { cloneDeep, omit, uniqBy } from 'lodash-es';
  import { defineComponent, reactive, ref, computed } from 'vue';
  const CONFIG_TYPE_MAP = {
    NORMAL: 'normal',
    STEP: 'step',
    MB: 'mediabuy', //自代投
    GOOGLE: 'gg',
    TT: 'tt',
    GG2023: 'gg2023',
    GG2024: 'gg2024',
    GG2023FEE: 'gg2023_fee',
    TT2023: 'tt2023',
    GDT_JL2023: 'gdt_jl_2023',
  };
  export default defineComponent({
    name: 'MediaRuleConfig',
    props: {
      is_demand: {
        type: Boolean,
        default: false,
      },
      is_supply: {
        type: Boolean,
        default: false,
      },
      edit_type: {
        type: String,
        default: '',
      },
      data_item: {
        type: Object as PropType<Recordable>,
        default: () => ({}),
      },
      title: {
        type: String,
        default: '',
      },
    },
    setup() {
      const dialog_visible = ref(false);
      const media_rule_form = ref<Nullable<FormActionType>>(null);
      const region_list = ref<Recordable[]>([]);
      const region_list_2023 = ref<Recordable[]>([]);
      const activeKey = ref('基本返点（按地区配置）');
      const model = reactive<Recordable>({
        rule_type: undefined,
        default: 0,
        step: {
          steps: [{ form: 0, to: 0, value: 0 }],
          default: 0,
        },
        gg2023_fee: {
          ac: 0,
          nonac: 0,
        },
        mb: {
          webeyeBuyShowProductClass: undefined,
          webeyeBuyGame: 0,
          webeyeBuyApp: 0,
          webeyeBuyDefault: 0,
          partnerBuyShowProductClass: undefined,
          partnerBuyGame: 0,
          partnerBuyApp: 0,
          partnerBuyDefault: 0,
        },
        gg: {
          ac: {
            default: 0,
            steps: [],
          },
          nonac: {
            default: 0,
            steps: [],
          },
          new_customer: {
            default: 0,
            steps: [],
          },
        },
        gg2023: {
          nonac: {
            default: 0,
            steps: [],
          },
          new_customer: {
            default: 0,
          },
        },
        gg2024: {
          nonac: {
            default: 0,
            steps: [],
          },
          new_customer: {
            default: 0,
          },
        },
        tt: {
          region_config: [
            {
              region_category: undefined,
              steps: [],
              default: 0,
            },
          ],
          default: {
            default: 0,
            steps: [],
          },
        },
        tt2023: {
          region_config: [
            { region_category: 'NA,AUNZ', default: 0 },
            { region_category: 'SEA,JP,KR,EUI', default: 0 },
            { region_category: 'METAP,LATAM,Others', default: 0 },
          ],
          new_config: {
            config_strategy: 'max',
            cost: {
              steps: [],
              default: 0,
            },
            cost_ratio: {
              steps: [],
              default: 0,
            },
            default: 0,
            steps: [],
          },
          store_config: {
            steps: [],
            default: 0,
          },
          agency_config: {
            config_strategy: 'max',
            cost: {
              steps: [],
              default: 0,
            },
            default: 0,
            steps: [],
            cost_ratio: {
              steps: [],
              default: 0,
            },
          },
          default: {
            default: 0,
          },
        },
        gdt_jl_2023: {
          webeye_buy: {
            steps: [],
            default: 0,
          },
          partner_buy: {
            default: 0,
          },
        },
      });

      const { appInfo } = useAppInfo();
      const gdt2023_industry_items = computed<Recordable[]>(() => {
        return appInfo.value.key_vals.gdt_industry2023.split('\t').map((m) => ({
          id: m,
          name: m,
          disabled: model.gdt_jl_2023.webeye_buy.steps.map((step) => step.industry).includes(m),
        }));
      });
      const jl2023_industry_items = computed<Recordable[]>(() => {
        return appInfo.value.key_vals.oceanengine_industry2023.split('\t').map((m) => ({
          id: m,
          name: m,
          disabled: model.gdt_jl_2023.webeye_buy.steps.map((step) => step.industry).includes(m),
        }));
      });
      return {
        dialog_visible,
        model,
        media_rule_form,
        region_list,
        activeKey,
        region_list_2023,
        gdt2023_industry_items,
        jl2023_industry_items,
      };
    },
    computed: {
      is_rebate() {
        return this.edit_type === 'rebate';
      },
      is_service() {
        return this.edit_type === 'service_fee';
      },
      is_gdt2023_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.GDT_JL2023 && this.data_item.platform === 'GDT';
      },
      is_jl2023_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.GDT_JL2023 && this.data_item.platform === 'OceanEngine';
      },
      industry_items() {
        if (this.is_gdt2023_type) {
          return this.gdt2023_industry_items;
        }
        return this.jl2023_industry_items;
      },
      is_normal_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.NORMAL;
      },
      is_step_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.STEP;
      },
      is_mb_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.MB;
      },
      is_gg_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.GOOGLE;
      },
      is_gg2023_fee_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.GG2023FEE;
      },
      is_tt_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.TT;
      },
      is_gg_2023_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.GG2023;
      },
      is_gg_2024_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.GG2024;
      },
      is_tt_2023_type() {
        return this.model.rule_type === CONFIG_TYPE_MAP.TT2023;
      },
      config_type_items() {
        let items: Recordable[] = [];
        if (this.is_rebate) {
          items = [
            { id: CONFIG_TYPE_MAP.NORMAL, name: '数值' },
            { id: CONFIG_TYPE_MAP.STEP, name: '阶梯' },
            { id: CONFIG_TYPE_MAP.MB, name: '自代投' },
            { id: CONFIG_TYPE_MAP.GOOGLE, name: '谷歌-Old' },
            { id: CONFIG_TYPE_MAP.TT, name: 'TikTok-Old' },
            { id: CONFIG_TYPE_MAP.GG2023, name: '谷歌2023' },
            { id: CONFIG_TYPE_MAP.GG2024, name: '谷歌2024' },
            { id: CONFIG_TYPE_MAP.TT2023, name: 'TikTok2023+' },
            { id: CONFIG_TYPE_MAP.GDT_JL2023, name: 'GDT/巨量2023+' },
          ];
        } else {
          items = [
            { id: CONFIG_TYPE_MAP.NORMAL, name: '数值' },
            { id: CONFIG_TYPE_MAP.STEP, name: '阶梯' },
            { id: CONFIG_TYPE_MAP.GG2023FEE, name: '谷歌2023' },
          ];
        }
        return items;
      },
    },
    methods: {
      required,
      resetData() {
        copyObjectToReactive(this.model, {
          rule_type: undefined,
          default: 0,
          step: {
            steps: [{ form: 0, to: 0, value: 0 }],
            default: 0,
          },
          gg2023_fee: {
            ac: 0,
            nonac: 0,
          },
          mb: {
            webeyeBuyShowProductClass: undefined,
            webeyeBuyGame: 0,
            webeyeBuyApp: 0,
            webeyeBuyDefault: 0,
            partnerBuyShowProductClass: undefined,
            partnerBuyGame: 0,
            partnerBuyApp: 0,
            partnerBuyDefault: 0,
          },
          gg: {
            ac: {
              default: 0,
              steps: [],
            },
            nonac: {
              default: 0,
              steps: [],
            },
            new_customer: {
              default: 0,
              steps: [],
            },
          },
          gg2023: {
            nonac: {
              default: 0,
              steps: [],
            },
            new_customer: {
              default: 0,
            },
          },
          gg2024: {
            nonac: {
              default: 0,
              steps: [],
            },
            new_customer: {
              default: 0,
            },
          },
          tt2023: {
            region_config: [
              { region_category: 'NA,AUNZ', default: 0 },
              { region_category: 'SEA,JP,KR,EUI', default: 0 },
              { region_category: 'METAP,LATAM,Others', default: 0 },
            ],
            new_config: {
              config_strategy: 'max',
              cost: {
                steps: [],
                default: 0,
              },
              cost_ratio: {
                steps: [],
                default: 0,
              },
              default: 0,
              steps: [],
            },
            store_config: {
              steps: [],
              default: 0,
            },
            agency_config: {
              config_strategy: 'max',
              cost: {
                steps: [],
                default: 0,
              },
              default: 0,
              steps: [],
              cost_ratio: {
                steps: [],
                default: 0,
              },
            },
            default: {
              default: 0,
            },
          },
          tt: {
            region_config: [
              {
                region_category: undefined,
                steps: [],
                default: 0,
              },
            ],
            default: {
              default: 0,
              steps: [],
            },
          },
          gdt_jl_2023: {
            webeye_buy: {
              steps: [],
              default: 0,
            },
            partner_buy: {
              default: 0,
            },
          },
        });
      },
      initData() {
        get_tt_rebate_config_region_category().then((res) => {
          let list = uniqBy(res, 'region_category');
          this.region_list = list.map((item) => ({ id: item.region_category, name: item.region_category }));
          this.region_list_2023 = list
            .filter((f) => Number(f.year) === 2023)
            .map((item) => ({ id: item.region_category, name: item.region_category }));
        });
        const rule = this.data_item[this.edit_type] || {};
        this.model.rule_type = rule.rule_type || undefined;
        if (rule.rule_type === 'normal') {
          this.model.default = rule.default || 0;
        } else if (rule.rule_type === 'step') {
          this.model.step.default = rule.default || 0;
          this.model.step.steps = rule.steps || [];
        } else if (rule.rule_type === 'gg2023_fee') {
          copyObjectToReactive(this.model.gg2023_fee, {
            ac: rule.ac,
            nonac: rule.nonac,
          });
        } else if (rule.rule_type === 'mediabuy') {
          if (rule.webeye_buy) {
            this.model.mb.webeyeBuyGame = rule.webeye_buy.product_class_game || 0;
            this.model.mb.webeyeBuyApp = rule.webeye_buy.product_class_app || 0;
            this.model.mb.webeyeBuyDefault = rule.webeye_buy.default || 0;
            this.model.mb.webeyeBuyShowProductClass = !!(this.model.mb.webeyeBuyGame || this.model.mb.webeyeBuyApp);
          }
          if (rule.partner_buy) {
            this.model.mb.partnerBuyGame = rule.partner_buy.product_class_game || 0;
            this.model.mb.partnerBuyApp = rule.partner_buy.product_class_app || 0;
            this.model.mb.partnerBuyDefault = rule.partner_buy.default || 0;
            this.model.mb.partnerBuyShowProductClass = !!(this.model.mb.partnerBuyGame || this.model.mb.partnerBuyApp);
          }
        } else if (rule.rule_type === 'gg') {
          this.model.gg.ac.default = isEmptyData(rule.ac) || isEmptyData(rule.ac.default) ? 0 : rule.ac.default;
          this.model.gg.nonac.default =
            isEmptyData(rule.nonac) || isEmptyData(rule.nonac.default) ? 0 : rule.nonac.default;
          this.model.gg.new_customer.default =
            isEmptyData(rule.new_customer) || isEmptyData(rule.new_customer.default) ? 0 : rule.new_customer.default;
          this.model.gg.ac.steps = isEmptyData(rule.ac) || isEmptyData(rule.ac.steps) ? [] : rule.ac.steps;
          this.model.gg.nonac.steps = isEmptyData(rule.nonac) || isEmptyData(rule.nonac.steps) ? [] : rule.nonac.steps;
          this.model.gg.new_customer.steps =
            isEmptyData(rule.new_customer) || isEmptyData(rule.new_customer.steps) ? [] : rule.new_customer.steps;
        } else if (rule.rule_type === 'gg2023') {
          this.model.gg2023.nonac.default =
            isEmptyData(rule.nonac) || isEmptyData(rule.nonac.default) ? 0 : rule.nonac.default;
          this.model.gg2023.new_customer.default =
            isEmptyData(rule.new_customer) || isEmptyData(rule.new_customer.default) ? 0 : rule.new_customer.default;
          this.model.gg2023.nonac.steps =
            isEmptyData(rule.nonac) || isEmptyData(rule.nonac.steps) ? [] : rule.nonac.steps;
        } else if (rule.rule_type === 'gg2024') {
          this.model.gg2024.nonac.default =
            isEmptyData(rule.nonac) || isEmptyData(rule.nonac.default) ? 0 : rule.nonac.default;
          this.model.gg2024.new_customer.default =
            isEmptyData(rule.new_customer) || isEmptyData(rule.new_customer.default) ? 0 : rule.new_customer.default;
          this.model.gg2024.nonac.steps =
            isEmptyData(rule.nonac) || isEmptyData(rule.nonac.steps) ? [] : rule.nonac.steps;
        } else if (rule.rule_type === 'tt2023') {
          copyObjectToReactive(this.model.tt2023, rule);
        } else if (rule.rule_type === 'industry_mediabuy') {
          rule.rule_type = 'gdt_jl_2023';
          const webeye_buy_industry = Object.keys(rule.webeye_buy)
            .filter((f) => f !== 'default')
            .map((m) => ({ industry: m, value: rule.webeye_buy[m] }));
          const config = {
            webeye_buy: {
              steps: webeye_buy_industry,
              default: rule.webeye_buy.default,
            },
            partner_buy: rule.partner_buy,
          };
          copyObjectToReactive(this.model.gdt_jl_2023, config);
        } else if (rule.rule_type === 'tt') {
          this.model.tt.region_config = rule.region_config || [];
          copyObjectToReactive(
            this.model.tt.default,
            isEmptyData(rule.default) ? { default: 0, steps: [] } : rule.default
          );
        }
      },
      cancel() {
        this.dialog_visible = false;
        this.$emit('update:data_item', {});
        // this.media_rule_form?.resetFields();
        // this.resetData();
      },
      async save_data() {
        await this.media_rule_form?.validate();
        let ret: Recordable = {
          rule_type: this.model.rule_type,
        };
        if (this.is_normal_type) {
          ret.default = this.model.default;
        } else if (this.is_gg2023_fee_type) {
          ret = {
            ...ret,
            ...this.model.gg2023_fee,
          };
        } else if (this.is_step_type) {
          ret.default = this.model.step.default;
          ret.steps = this.model.step.steps;
          if (!isEmptyData(ret.steps)) {
            let valid_steps = this.check_steps_valid(this.model.step.steps);
            if (!valid_steps) {
              this.$message.error('阶梯中的数据不能为空，请填写');
              return;
            }
          }
        } else if (this.is_mb_type) {
          if (this.model.mb.webeyeBuyDefault || this.model.mb.webeyeBuyGame || this.model.mb.webeyeBuyApp) {
            ret.webeye_buy = {};
            this.model.mb.webeyeBuyDefault && (ret.webeye_buy.default = this.model.mb.webeyeBuyDefault);
            if (this.model.mb.webeyeBuyShowProductClass) {
              this.model.mb.webeyeBuyGame && (ret.webeye_buy.product_class_game = this.model.mb.webeyeBuyGame);
              this.model.mb.webeyeBuyApp && (ret.webeye_buy.product_class_app = this.model.mb.webeyeBuyApp);
            }
          }
          if (this.model.mb.partnerBuyDefault || this.model.mb.partnerBuyGame || this.model.mb.partnerBuyApp) {
            ret.partner_buy = {};
            this.model.mb.partnerBuyDefault && (ret.partner_buy.default = this.model.mb.partnerBuyDefault);
            if (this.model.mb.partnerBuyShowProductClass) {
              this.model.mb.partnerBuyGame && (ret.partner_buy.product_class_game = this.model.mb.partnerBuyGame);
              this.model.mb.partnerBuyApp && (ret.partner_buy.product_class_app = this.model.mb.partnerBuyApp);
            }
          }
        } else if (this.is_gg_type) {
          ret.ac = {
            default: 0,
            steps: [],
          };
          ret.nonac = {
            default: 0,
            steps: [],
          };
          ret.new_customer = {
            default: 0,
            steps: [],
          };
          ret.ac.default = this.model.gg.ac.default;
          ret.nonac.default = this.model.gg.nonac.default;
          ret.new_customer.default = this.model.gg.new_customer.default;
          if (!isEmptyData(this.model.gg.ac.steps)) {
            let last = 0;
            for (let index = 0; index < this.model.gg.ac.steps.length; index++) {
              const x = this.model.gg.ac.steps[index];
              if (parseFloat(x.to) < last) {
                this.$showAlert('Error', '阶梯必须递增');
                return;
              }
              last = x.to;
            }
            ret.ac.steps = this.model.gg.ac.steps;
            let valid_steps = this.check_steps_valid(this.model.gg.ac.steps);
            if (!valid_steps) {
              this.$message.error('阶梯中的数据不能为空，请填写');
              return;
            }
          }
          if (!isEmptyData(this.model.gg.nonac.steps)) {
            let last = 0;
            for (let index = 0; index < this.model.gg.nonac.steps.length; index++) {
              const x = this.model.gg.nonac.steps[index];
              if (parseFloat(x.to) < last) {
                this.$showAlert('Error', '阶梯必须递增');
                return;
              }
              last = x.to;
            }
            ret.nonac.steps = this.model.gg.nonac.steps;
            let valid_steps = this.check_steps_valid(this.model.gg.nonac.steps);
            if (!valid_steps) {
              this.$message.error('阶梯中的数据不能为空，请填写');
              return;
            }
          }
          if (!isEmptyData(this.model.gg.new_customer.steps)) {
            let last = 0;
            for (let index = 0; index < this.model.gg.new_customer.steps.length; index++) {
              const x = this.model.gg.new_customer.steps[index];
              if (parseFloat(x.to) < last) {
                this.$showAlert('Error', '阶梯必须递增');
                return;
              }
              last = x.to;
            }
            ret.new_customer.steps = this.model.gg.new_customer.steps;
            let valid_steps = this.check_steps_valid(this.model.gg.new_customer.steps);
            if (!valid_steps) {
              this.$message.error('阶梯中的数据不能为空，请填写');
              return;
            }
          }
        } else if (this.is_tt_type) {
          ret.region_config = this.model.tt.region_config;
          ret.default = this.model.tt.default;
          for (let i = 0; i < ret.region_config.length; i++) {
            if (!isEmptyData(ret.region_config[i].steps)) {
              let valid_steps = this.check_steps_valid(ret.region_config[i].steps);
              if (!valid_steps) {
                this.$message.error('阶梯中的数据不能为空，请填写');
                return;
              }
              if (!ret.region_config[i].default) {
                this.$message.error('返点比例不能为空，请填写');
                return;
              }
            }
          }
          if (!isEmptyData(ret.default.steps)) {
            let valid_steps = this.check_steps_valid(ret.default.steps);
            if (!valid_steps) {
              this.$message.error('阶梯中的数据不能为空，请填写');
              return;
            }
            if (!ret.default.default) {
              this.$message.error('返点比例不能为空，请填写');
              return;
            }
          }
        } else if (this.is_gg_2023_type) {
          ret.nonac = {
            default: 0,
            steps: [],
          };
          ret.new_customer = {
            default: 0,
          };
          ret.nonac.default = this.model.gg2023.nonac.default;
          ret.nonac.steps = this.model.gg2023.nonac.steps;
          ret.new_customer.default = this.model.gg2023.new_customer.default;
        } else if (this.is_gg_2024_type) {
          ret.nonac = {
            default: 0,
            steps: [],
          };
          ret.new_customer = {
            default: 0,
          };
          ret.nonac.default = this.model.gg2024.nonac.default;
          ret.nonac.steps = this.model.gg2024.nonac.steps;
          ret.new_customer.default = this.model.gg2024.new_customer.default;
        } else if (this.is_tt_2023_type) {
          if (this.is_supply) {
            ret = { ...ret, ...this.model.tt2023 };
            ret.new_config = omit(ret.new_config, ['steps', 'default']);
            ret.agency_config = omit(ret.agency_config, ['steps', 'default']);
            const new_config_cost_steps = ret.new_config.cost.steps;
            const new_config_cost_ratio_steps = ret.new_config.cost_ratio.steps;
            const agency_config_cost_steps = ret.agency_config.cost.steps;
            const agency_config_cost_ratio_steps = ret.agency_config.cost_ratio.steps;
            const store_config_steps = ret.store_config.steps;
            const steps_list = [
              new_config_cost_steps,
              new_config_cost_ratio_steps,
              agency_config_cost_steps,
              agency_config_cost_ratio_steps,
              store_config_steps,
            ];
            steps_list.forEach((steps) => {
              if (!isEmptyData(steps)) {
                let last = 0;
                for (let index = 0; index < steps.length; index++) {
                  const x = steps[index];
                  if (parseFloat(x.to) < last) {
                    this.$showAlert('Error', '阶梯必须递增');
                    return;
                  }
                  last = x.to;
                }
                let valid_steps = this.check_steps_valid(steps);
                if (!valid_steps) {
                  this.$message.error('阶梯中的数据不能为空，请填写');
                  return;
                }
              }
            });
          } else if (this.is_demand) {
            ret = {
              ...ret,
              ...{
                region_config: this.model.tt2023.region_config,
                new_config: {
                  steps: [],
                  default: this.model.tt2023.new_config.default,
                },
                agency_config: {
                  steps: [],
                  default: this.model.tt2023.agency_config.default,
                },
                store_config: this.model.tt2023.store_config,
                default: this.model.tt2023.default,
              },
            };
          }
        } else if (this.is_gdt2023_type || this.is_jl2023_type) {
          ret = {
            ...ret,
            webeye_buy: {
              default: this.model.gdt_jl_2023.webeye_buy.default,
            },
            partner_buy: this.model.gdt_jl_2023.partner_buy,
          };
          ret.rule_type = 'industry_mediabuy';
          this.model.gdt_jl_2023.webeye_buy.steps.forEach((step) => {
            if (!step.industry) {
              this.$message.error('行业不能为空，请填写');
              return;
            }
            ret.webeye_buy[step.industry] = step.value;
          });
        }
        this.$emit('onSave', ret);
        this.$emit('update:data_item', {});
        this.media_rule_form?.resetFields();
        this.dialog_visible = false;
        this.resetData();
      },
      check_steps_valid(steps) {
        for (let i = 0; i < steps.length; i++) {
          if (isEmptyData(steps[i].to) || isEmptyData(steps[i].from) || isEmptyData(steps[i].value)) {
            return false;
          }
        }
        return true;
      },
      /**
       * 增加阶梯
       */
      add_step_item(config: {
        type?: string;
        gg_type?: string;
        region_index?: number;
        gg2023_type?: string;
        gg2024_type?: string;
        tt2023_type?: string;
        cost_or_ratio?: string;
      }) {
        let steps: Recordable[] = [];
        if (config.type === 'step') {
          steps = this.model.step.steps;
        } else if (config.type === 'gg') {
          steps = this.model.gg[config.gg_type as string].steps;
        } else if (config.type === 'gg2023') {
          steps = this.model.gg2023[config.gg2023_type as string].steps;
        } else if (config.type === 'gg2024') {
          steps = this.model.gg2024[config.gg2024_type as string].steps;
        } else if (config.type === 'tt') {
          if (config.region_index === this.model.tt.region_config.length) {
            steps = this.model.tt.default.steps;
          } else {
            steps = this.model.tt.region_config[config.region_index as number].steps;
          }
        } else if (config.type === 'tt2023') {
          if (config.tt2023_type === 'store_config') {
            steps = this.model.tt2023[config.tt2023_type as string].steps;
          } else {
            steps = this.model.tt2023[config.tt2023_type as string][config.cost_or_ratio as string].steps;
          }
        } else if (config.type === 'gdt_jl_2023') {
          steps = this.model.gdt_jl_2023.webeye_buy.steps;
        }
        const len = steps.length;
        if (config.type === 'gg2023') {
          let level = '';
          let target = 0;
          if (len === 0) {
            level = 'S1';
            target = 6000;
          } else if (len === 1) {
            level = 'S2';
            target = 12000;
          } else if (len === 2) {
            level = 'S3';
            target = 25000;
          } else if (len === 3) {
            level = 'S4';
            target = 45000;
          }
          steps.push({ target, level, value: 0 });
        } else if (config.type === 'gg2024') {
          let level = '';
          let target = 0;
          if (len === 0) {
            level = 'S1';
            target = 8000;
          } else if (len === 1) {
            level = 'S2';
            target = 11000;
          } else if (len === 2) {
            level = 'S3';
            target = 15000;
          } else if (len === 3) {
            level = 'S4';
            target = 19000;
          } else if (len === 4) {
            level = 'S5';
            target = 23000;
          } else if (len === 5) {
            level = 'S6';
            target = 26000;
          } else if (len === 6) {
            level = 'S7';
            target = 30000;
          }
          steps.push({ target, level, value: 0 });
        } else if (config.type === 'tt2023') {
          let step = {
            value: 0,
            from: 0,
            to: 0,
          };
          if (config.tt2023_type === 'new_config') {
            if (config.cost_or_ratio === 'cost') {
              if (len === 0) {
                step = {
                  value: 0,
                  from: 300000,
                  to: 500000,
                };
              } else if (len === 1) {
                step = {
                  value: 0,
                  from: 500000,
                  to: 1000000,
                };
              } else if (len === 2) {
                step = {
                  value: 0,
                  from: 1000000,
                  to: 2000000,
                };
              } else if (len === 3) {
                step = {
                  value: 0,
                  from: 2000000,
                  to: 9000000,
                };
              }
            } else if (config.cost_or_ratio === 'cost_ratio') {
              if (len === 0) {
                step = {
                  value: 0,
                  from: 0.2,
                  to: 0.3,
                };
              } else if (len === 1) {
                step = {
                  value: 0,
                  from: 0.3,
                  to: 0.4,
                };
              } else if (len === 2) {
                step = {
                  value: 0,
                  from: 0.4,
                  to: 0.5,
                };
              } else if (len === 3) {
                step = {
                  value: 0,
                  from: 0.5,
                  to: 0.99,
                };
              }
            }
          } else if (config.tt2023_type === 'agency_config') {
            if (config.cost_or_ratio === 'cost') {
              if (len === 0) {
                step = {
                  value: 0,
                  from: 15000000,
                  to: 3000000,
                };
              } else if (len === 1) {
                step = {
                  value: 0,
                  from: 3000000,
                  to: 5000000,
                };
              } else if (len === 2) {
                step = {
                  value: 0,
                  from: 5000000,
                  to: 10000000,
                };
              } else if (len === 3) {
                step = {
                  value: 0,
                  from: 10000000,
                  to: 90000000,
                };
              }
            } else if (config.cost_or_ratio === 'cost_ratio') {
              if (len === 0) {
                step = {
                  value: 0,
                  from: 0.15,
                  to: 0.25,
                };
              } else if (len === 1) {
                step = {
                  value: 0,
                  from: 0.25,
                  to: 0.35,
                };
              } else if (len === 2) {
                step = {
                  value: 0,
                  from: 0.35,
                  to: 0.5,
                };
              } else if (len === 3) {
                step = {
                  value: 0,
                  from: 0.5,
                  to: 0.99,
                };
              }
            }
          } else if (config.tt2023_type === 'store_config') {
            if (len === 0) {
              step = {
                value: 0,
                from: 100000,
                to: 200000,
              };
            } else if (len === 1) {
              step = {
                value: 0,
                from: 200000,
                to: 500000,
              };
            } else if (len === 2) {
              step = {
                value: 0,
                from: 500000,
                to: 1000000,
              };
            } else if (len === 3) {
              step = {
                value: 0,
                from: 1000000,
                to: 9000000,
              };
            }
          }
          steps.push(step);
        } else if (config.type === 'gdt2023') {
          steps.push({
            industry: '',
            value: 0,
          });
        } else if (config.type === 'gdt_jl_2023') {
          steps.push({
            industry: '',
            value: 0,
          });
        } else {
          if (len === 0) {
            steps.push({
              from: 0,
              to: 0,
              value: 0,
            });
          } else {
            steps.push({
              from: steps[len - 1].to || 0,
              to: 0,
              value: 0,
            });
          }
        }

        if (!config.type) {
          this.model.steps = steps;
        } else if (config.type === 'gg') {
          this.model.gg[config.gg_type as string].steps = steps;
        } else if (config.type === 'gg2023') {
          this.model.gg2023[config.gg2023_type as string].steps = steps;
        } else if (config.type === 'gg2024') {
          this.model.gg2024[config.gg2024_type as string].steps = steps;
        } else if (config.type === 'tt') {
          if (config.region_index === this.model.tt.region_config.length) {
            this.model.tt.default.steps = steps;
          } else {
            this.model.tt.region_config[config.region_index as number].steps = steps;
          }
        } else if (config.type === 'tt2023') {
          if (config.tt2023_type === 'store_config') {
            this.model.tt2023[config.tt2023_type as string].steps = steps;
          } else {
            this.model.tt2023[config.tt2023_type as string][config.cost_or_ratio as string].steps = steps;
          }
        }
      },
      /**
       * 删除阶梯
       */
      delete_step_item(config: {
        type?: string;
        gg_type?: string;
        tt2023_type?: string;
        gg2023_type?: string;
        gg2024_type?: string;
        cost_or_ratio?: string;
        region_index?: number;
        index: number;
      }) {
        let steps: Recordable[] = [];
        if (config.type === 'step') {
          steps = this.model.step.steps;
        } else if (config.type === 'gg') {
          steps = this.model.gg[config.gg_type as string].steps;
        } else if (config.type === 'tt') {
          if (config.region_index === this.model.tt.region_config.length) {
            steps = this.model.tt.default.steps;
          } else {
            steps = this.model.tt.region_config[config.region_index as number].steps;
          }
        } else if (config.type === 'tt2023') {
          if (config.tt2023_type === 'store_config') {
            steps = this.model.tt2023[config.tt2023_type as string].steps;
          } else {
            steps = this.model.tt2023[config.tt2023_type as string][config.cost_or_ratio as string].steps;
          }
        } else if (config.type === 'gg2023') {
          steps = this.model.gg2023[config.gg2023_type as string].steps;
        } else if (config.type === 'gg2024') {
          steps = this.model.gg2024[config.gg2024_type as string].steps;
        } else if (config.type === 'gdt_jl_2023') {
          steps = this.model.gdt_jl_2023.webeye_buy.steps;
        }
        const len = steps.length;

        if (config.type === 'gg2023') {
          steps.splice(config.index, 1);
          steps.forEach((f, index) => {
            f.level = `S${index + 1}`;
          });
        } else if (config.type === 'gg2024') {
          steps.splice(config.index, 1);
          steps.forEach((f, index) => {
            f.level = `S${index + 1}`;
          });
        } else if (config.type === 'gdt2023') {
          steps.splice(config.index, 1);
        } else if (config.type === 'gdt_jl_2023') {
          steps.splice(config.index, 1);
        } else {
          if (len !== 1) {
            if (config.index === 0) {
              steps[1].from = 0;
            } else if (config.index !== len - 1) {
              steps[config.index + 1].from = steps[config.index - 1].to;
            }
          }
          steps.splice(config.index, 1);
        }
      },
      add_region(config: { type: string }) {
        if (config.type === 'tt') {
          this.model.tt.region_config.push({
            region_category: undefined,
            steps: [],
            default: 0,
          });
        } else if (config.type === 'tt2023') {
          this.model.tt2023.region_config.push({
            region_category: '',
            default: 0,
          });
        }
      },
      delete_region_item(config: { type: string; index: number }) {
        if (config.type === 'tt2023') {
          this.model.tt2023.region_config.splice(config.index, 1);
        } else if (config.type === 'tt') {
          this.model.tt.region_config.splice(config.index, 1);
        }
      },
      after_close() {
        this.$emit('update:data_item', {});
        this.media_rule_form?.resetFields();
        this.resetData();
      },
      change_to_value(config: {
        type?: string;
        gg_type?: string;
        region_index?: number;
        tt2023_type?: string;
        gg2023_type?: string;
        gg2024_type?: string;
        cost_or_ratio?: string;
        index: number;
      }) {
        let steps: Recordable[] = [];
        if (config.type === 'step') {
          steps = this.model.step.steps;
        } else if (config.type === 'gg') {
          steps = this.model.gg[config.gg_type as string].steps;
        } else if (config.type === 'tt') {
          if (config.region_index === this.model.tt.region_config.length) {
            steps = this.model.tt.default.steps;
          } else {
            steps = this.model.tt.region_config[config.region_index as number].steps;
          }
        } else if (config.type === 'gg2023') {
          if (config.gg2023_type) {
            steps = this.model.gg2023[config.gg2023_type as string].steps;
          }
        } else if (config.type === 'gg2024') {
          if (config.gg2023_type) {
            steps = this.model.gg2024[config.gg2024_type as string].steps;
          }
        } else if (config.type === 'tt2023') {
          if (config.cost_or_ratio) {
            steps = this.model.tt2023[config.tt2023_type as string][config.cost_or_ratio as string].steps;
          } else {
            steps = this.model.tt2023[config.tt2023_type as string].steps;
          }
        }
        const len = steps.length;
        if (config.index === len - 1) {
          return;
        } else {
          steps[config.index + 1].from = steps[config.index].to;
        }
      },
      change_from_value(config: {
        type?: string;
        gg_type?: string;
        gg2023_type?: string;
        gg2024_type?: string;
        tt2023_type?: string;
        cost_or_ratio?: string;
        region_index?: number;
        index: number;
      }) {
        let steps: Recordable[] = [];
        if (config.type === 'step') {
          steps = this.model.step.steps;
        } else if (config.type === 'gg') {
          steps = this.model.gg[config.gg_type as string].steps;
        } else if (config.type === 'tt') {
          if (config.region_index === this.model.tt.region_config.length) {
            steps = this.model.tt.default.steps;
          } else {
            steps = this.model.tt.region_config[config.region_index as number].steps;
          }
        } else if (config.type === 'gg2023') {
          if (config.gg2023_type) {
            steps = this.model.gg2023[config.gg2023_type as string].steps;
          }
        } else if (config.type === 'gg2024') {
          if (config.gg2024_type) {
            steps = this.model.gg2024[config.gg2024_type as string].steps;
          }
        } else if (config.type === 'tt2023') {
          if (config.cost_or_ratio) {
            steps = this.model.tt2023[config.tt2023_type as string][config.cost_or_ratio as string].steps;
          } else {
            steps = this.model.tt2023[config.tt2023_type as string].steps;
          }
        }
        if (config.index === 0) {
          return;
        } else {
          steps[config.index - 1].to = steps[config.index].from;
        }
      },
      change_cost_or_ratio_value(config: {
        type: string;
        tt2023_type: string;
        index: number;
        target: string;
        event: any;
      }) {
        const { type, tt2023_type, target, index } = config;
        this.model[type][tt2023_type][target]['steps'][index].value = config.event;
      },
      check_uniq_region(value, index) {
        const region_config = cloneDeep(this.model.tt.region_config);
        let is_uniq = region_config.filter((item) => item.region_category === value).length === 1;
        if (!is_uniq) {
          this.$message.error('该区域已选择,请选择其它区域');
          this.model.tt.region_config[index].region_category = undefined;
        }
      },
    },
    watch: {
      data_item: {
        handler(val) {
          if (!isEmptyData(val)) {
            this.dialog_visible = true;
          } else {
            this.dialog_visible = false;
          }
          if (this.dialog_visible) {
            this.initData();
          }
        },
        deep: true,
        immediate: true,
      },
    },
  });
