
  import { BasicColumn, useTable } from '@/components/WeTable';
  import { defineComponent, computed, unref, ref } from 'vue';
  import { get_bill_page, get_export_bill_list, get_export_data } from '@/api/erp-bills';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { useBills } from './hooks/useBills';
  import { useJump } from '@/hooks/useJump';
  import { openDownloadDialog, sheet2blob } from '@/utils/excel';
  import XLSX from 'xlsx';
  import { get_arr_sum_by_group_field, isEmptyData } from '@/utils/common';
  import sumBy from 'lodash-es/sumBy';
  import { useRoute } from 'vue-router';
  export default defineComponent({
    name: 'ERPBillsList',
    setup() {
      const { model } = useAppInfo();
      const { isFinance, isAdmin, user, hasPermission } = useUser();
      const route = useRoute();
      const { isDemandPage, isSupplyPage } = useBills();
      const { jumpToBill } = useJump();
      const financeHeaders: BasicColumn[] = [
        { title: '发票号码', dataIndex: 'invoice_number' },
        { title: '开票日期', dataIndex: 'invoice_date' },
        {
          title: '返点金额',
          dataIndex: 'finance_anti_invoice_money',
          componentType: 'we-number-range',
          hiddenFilter: true,
          defaultHidden: true,
        },
        {
          title: isSupplyPage.value ? '支付中金额' : '收款中金额',
          dataIndex: 'finance_linked_money_paying',
          componentType: 'we-number-range',
          hiddenFilter: true,
        },
        {
          title: isSupplyPage.value ? '已付金额' : '已收金额',
          dataIndex: 'finance_linked_money_paid',
          componentType: 'we-number-range',
          hiddenFilter: true,
        },
        {
          title: isSupplyPage.value ? '已付时间' : '已收时间',
          dataIndex: 'rd_pay_time',
        },
      ];
      const emailHeaders: BasicColumn[] = [
        {
          title: '最近邮件时间',
          dataIndex: 'email_time',
          componentType: 'a-range-picker',
          componentProps: {
            placeholder: ['最近邮件时间', ''],
          },
        },
        { title: '最近邮件类型', dataIndex: 'email_name' },
      ];
      const filterType = ref('recent_one_year');

      let title = ref('');
      if (isDemandPage.value) {
        title.value = '收入账单';
      } else {
        title.value = '成本账单';
      }
      const columns = computed<BasicColumn[]>(() => {
        return [
          {
            title: '名称',
            dataIndex: 'name',
            slots: { customRender: 'name' },
            componentType: 'a-input',
            showTop: true,
          },
          {
            title: 'Team',
            dataIndex: 'rd_team_name',
            componentType: 'a-select',
            showTop: true,
            componentProps: {
              mode: 'multiple',
            },
          },
          {
            title: '状态',
            dataIndex: 'rd_state_name',
            colorField: 'rd_state_color',
            slots: { customRender: 'rd_state_name' },
            componentType: 'a-select',
            showTop: true,
            mode: 'multiple',
          },

          { title: '月份', dataIndex: 'month', componentType: 'a-select' },
          {
            title: '暂估金额',
            dataIndex: 'finance_estimate_money',
            componentType: 'we-number-range',
            hiddenFilter: true,
          },
          {
            title: '发票金额',
            dataIndex: 'finance_invoice_money',
            componentType: 'we-number-range',
          },
          {
            title: '差值',
            dataIndex: 'finance_cut_money',
            colorField: 'rd_cut_money_color',
            slots: { customRender: 'finance_cut_money' },
            componentType: 'we-number-range',
            hiddenFilter: true,
          },
          {
            title: '发票未关联金额',
            dataIndex: 'invoice_unlinked_money',
            componentType: 'we-number-range',
          },
          {
            title: '币种',
            dataIndex: 'rd_currency_name',
            componentType: 'a-select',
          },
          {
            title: '账单类别',
            dataIndex: 'rd_category',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          {
            title: '付款方式',
            dataIndex: 'rd_property',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          {
            title: '开票方式',
            dataIndex: 'is_manual_invoice',
            componentType: 'a-select',
            format: (text, record) => {
              if (
                record.state_value === model.value.BillState.STATE.SUB_AM_MAKING_BILL ||
                record.state_value === model.value.BillState.STATE.AM_CHECKING_BILL ||
                record.state_value === model.value.BillState.STATE.MAIN_AM_WAITING_BILL ||
                record.state_value === model.value.BillState.STATE.MAIN_AM_GOT_BILL
              ) {
                return '-';
              } else {
                return text ? '人工开票' : '自动开票';
              }
            },
            hiddenFilter: true,
          },
          {
            title: '发票是否显示业务线',
            dataIndex: 'show_buline',
            format: (text) => {
              return isEmptyData(text) ? '-' : text ? '是' : '否';
            },
            hiddenFilter: true,
          },
          {
            title: '业务空间',
            dataIndex: 'rd_logic_type',
            componentType: 'a-select',
          },
          ...(unref(isFinance) || unref(isAdmin) || hasPermission('CanReviewEmailSendField') ? emailHeaders : []),
          {
            title: '对账截止时间',
            dataIndex: 'rd_bill_deadline',
            componentType: 'a-range-picker',
            componentProps: {
              placeholder: ['对账截止时间', ''],
            },
          },
          {
            title: isSupplyPage.value ? '付款截止时间' : '收款截止时间',
            dataIndex: 'rd_pay_deadline',
            componentType: 'a-range-picker',
            componentProps: {
              placeholder: isSupplyPage.value ? ['付款截止时间', ''] : ['收款截止时间', ''],
            },
          },

          {
            title: '跟进人',
            dataIndex: 'rd_owner_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
            showTop: true,
          },
          {
            title: '业务线',
            dataIndex: 'rd_buline_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          {
            title: 'Group',
            dataIndex: 'rd_team_group_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          { title: '合同类型', dataIndex: 'rd_type_name' },
          {
            title: '出账周期',
            dataIndex: 'rd_check_bill_timespan',
            componentType: 'we-number-range',
          },
          {
            title: '收款周期',
            dataIndex: 'rd_pay_money_timespan',
            componentType: 'we-number-range',
          },
          {
            title: '总周期',
            dataIndex: 'rd_pay_money_totalspan',
            componentType: 'we-number-range',
          },
          { title: '计划付款日期', dataIndex: 'rd_pay_money_plan_time', sorter: true },
          {
            title: isSupplyPage.value ? '需付款' : '需收款',
            dataIndex: 'finance_to_pay_money',
            componentType: 'we-number-range',
            hiddenFilter: true,
          },
          ...(unref(isFinance) || unref(isAdmin) ? financeHeaders : []),
          {
            title: '对账进度',
            dataIndex: 'rd_bill_time_status',
            colorField: 'rd_bill_time_status_color',
            componentType: 'we-number-range',
            slots: { customRender: 'rd_bill_time_status' },
            ignoreField: true,
          },

          {
            title: isSupplyPage.value ? '付款进度' : '收款进度',
            dataIndex: 'rd_pay_time_status',
            colorField: 'rd_pay_time_status_color',
            componentType: 'we-number-range',
            slots: { customRender: 'rd_pay_time_status' },
            ignoreField: true,
          },
          {
            title: '客户名称',
            dataIndex: 'rd_company_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          {
            title: '我方主体',
            dataIndex: 'rd_we_company_name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          { title: 'ID', dataIndex: 'code', thousands: false },
          {
            title: '是否坏账',
            dataIndex: 'rd_is_bad_bill',
            componentType: 'a-select',
          },
          {
            title: '创建时间',
            dataIndex: 'rd_create_time',
            componentType: 'a-range-picker',
            componentProps: {
              placeholder: ['创建时间', ''],
            },
          },
        ];
      });
      const [register, { reload, getSelectRows }] = useTable({
        title: route.meta.title,
        api: (params: any) => {
          params.bill_type = unref(isDemandPage)
            ? unref(model).Contract.DIRECTION.DEMAND
            : unref(model).Contract.DIRECTION.SUPPLY;
          params.fields.push('id');
          params.fields.push('parent_id');
          params.fields.push('rd_bill_time_status');
          params.fields.push('rd_pay_time_status');
          params.fields.push('state_value');

          params.filter_type = filterType.value;

          return get_bill_page(params);
        },
        columns,
        rowSelection: { type: 'checkbox' },
      });
      return {
        isSupplyPage,
        isDemandPage,
        title,
        user,
        model,
        isAdmin,
        register,
        reload,
        getSelectRows,
        jumpToBill,
        columns,
        hasPermission,
        filterType,
      };
    },
    data: () => ({
      billDateRangeOptions: [
        { label: '最近一年', value: 'recent_one_year' },
        { label: '全部', value: 'all' },
      ],
    }),
    methods: {
      async downloadMonthlyBill(type) {
        const bill_type = this.isDemandPage
          ? this.model.Contract.DIRECTION.DEMAND
          : this.model.Contract.DIRECTION.Supply;
        const filter_current_year = type !== '下载月结账单';
        const billList: Recordable = await get_export_bill_list({ bill_type: bill_type, filter_current_year });
        const chinaBillList = billList[this.model.WeCompany.REGION_TYPE.CHINA];
        const globalBillList = billList[this.model.WeCompany.REGION_TYPE.GLOBAL];
        chinaBillList.forEach((item) => {
          item['no_rate_amount'] = item['fs_data'] / (1 + item.tax_rate);
        });
        globalBillList.forEach((i) => {
          if (i.end_sign === 'Y') {
            if (isEmptyData(i.tax_rate)) {
              i['no_rate_amount'] = i['finance_invoice_money_cn'];
            } else {
              i['no_rate_amount'] = i['finance_invoice_money_cn'] / (1 + i.tax_rate);
            }
          } else {
            i['no_rate_amount'] = i['finance_estimate_money_cn'];
          }
        });
        if (type === '下载月结账单') {
          const chinaItems: Recordable[] = [];
          const globalItems: Recordable[] = [];
          const gobalColums = [
            { title: '暂估金额 - USD', dataIndex: 'finance_estimate_money_cn' },
            { title: '发票金额 - USD', dataIndex: 'finance_invoice_money_cn' },
            {
              title: this.isSupplyPage ? '需付款 - USD' : '需收款 - USD',
              dataIndex: 'finance_to_pay_money_cn',
            },
            {
              title: this.isSupplyPage ? '支付中金额 - USD' : '收款中金额 - USD',
              dataIndex: 'finance_linked_money_paying_cn',
            },
            {
              title: this.isSupplyPage ? '已付金额 - USD' : '已收金额 - USD',
              dataIndex: 'finance_linked_money_paid_cn',
            },
            { title: '是否已经结算', dataIndex: 'end_sign' },
            { title: '不含税金额', dataIndex: 'no_rate_amount' },
          ];

          const chinaColums = [
            { title: '暂估金额 - CNY', dataIndex: 'finance_estimate_money_cny' },
            { title: '发票金额 - CNY', dataIndex: 'finance_invoice_money_cny' },
            {
              title: this.isSupplyPage ? '需付款 - CNY' : '需收款 - CNY',
              dataIndex: 'finance_to_pay_money_cny',
            },
            {
              title: this.isSupplyPage ? '支付中金额 - CNY' : '收款中金额 - CNY',
              dataIndex: 'finance_linked_money_paying_cny',
            },
            {
              title: this.isSupplyPage ? '已付金额 - CNY' : '已收金额 - CNY',
              dataIndex: 'finance_linked_money_paid_cny',
            },
            { title: 'FS数据', dataIndex: 'fs_data' },
            { title: '是否FBP Reviewing', dataIndex: 'is_fbp' },
            { title: '不含税金额', dataIndex: 'no_rate_amount' },
          ];
          chinaBillList.forEach((i: any) => {
            const data = {};
            this.columns.forEach((c) => {
              data[c.title as any] = i[c.dataIndex!];
            });
            chinaColums.forEach((c) => {
              data[c.title as any] = i[c.dataIndex!];
            });
            chinaItems.push(data);
          });
          globalBillList.forEach((i: any) => {
            const data = {};
            this.columns.forEach((c) => {
              data[c.title as any] = i[c.dataIndex!];
            });
            gobalColums.forEach((c) => {
              data[c.title as any] = i[c.dataIndex!];
            });
            globalItems.push(data);
          });
          const wb = XLSX.utils.book_new();
          const sheet1 = XLSX.utils.json_to_sheet(chinaItems);
          const sheet2 = XLSX.utils.json_to_sheet(globalItems);
          XLSX.utils.book_append_sheet(wb, sheet1, '境内月结');
          XLSX.utils.book_append_sheet(wb, sheet2, '境外月结');
          openDownloadDialog(sheet2blob(null, null, wb), `月结账单(${this.title}).xlsx`);
        } else {
          const columns = [
            {
              title: '核算实体',
              dataIndex: 'rd_we_company_name',
            },
            {
              title: 'HW-第三方Bills',
              dataIndex: 'no_rate_amount',
            },
          ];
          const china_items = get_arr_sum_by_group_field(chinaBillList, 'no_rate_amount', 'rd_we_company_name');
          china_items.push({ rd_we_company_name: '境内小计', no_rate_amount: sumBy(china_items, 'no_rate_amount') });
          china_items.unshift({ rd_we_company_name: '境内', no_rate_amount: '' });

          const global_items = get_arr_sum_by_group_field(globalBillList, 'no_rate_amount', 'rd_we_company_name');
          global_items.push({ rd_we_company_name: '境外小计', no_rate_amount: sumBy(global_items, 'no_rate_amount') });
          global_items.unshift({ rd_we_company_name: '境外', no_rate_amount: '' });
          global_items.push({ rd_we_company_name: '', no_rate_amount: '' });
          global_items.push({ rd_we_company_name: '', no_rate_amount: '' });

          const items = [...global_items, ...china_items];
          const excel_items: Recordable[] = [];
          items.forEach((item) => {
            const data: Recordable = {};
            columns.forEach((i) => {
              data[i.title] = item[i.dataIndex];
            });
            excel_items.push(data);
          });
          const wb = XLSX.utils.book_new();
          const sheet = XLSX.utils.json_to_sheet(excel_items);
          XLSX.utils.book_append_sheet(wb, sheet, this.isDemandPage ? 'Demand报表核对' : 'Supply报表核对');
          openDownloadDialog(
            sheet2blob(null, null, wb),
            this.isDemandPage ? '报表核对-收入.xlsx' : '报表核对-成本.xlsx'
          );
        }
      },
      async downloadExportData() {
        const bill_type = this.isDemandPage
          ? this.model.Contract.DIRECTION.DEMAND
          : this.model.Contract.DIRECTION.Supply;
        const exportData = await get_export_data({ bill_type: bill_type });
        const wb = XLSX.utils.book_new();

        Object.keys(exportData).forEach((k) => {
          if (k === 'month_bill_data') {
            const chinaItems: any = [];
            const chinaColums = [
              { title: '暂估金额 - CNY', dataIndex: 'finance_estimate_money_cny' },
              { title: '发票金额 - CNY', dataIndex: 'finance_invoice_money_cny' },
              {
                title: this.isSupplyPage ? '需付款 - CNY' : '需收款 - CNY',
                dataIndex: 'finance_to_pay_money_cny',
              },
              {
                title: this.isSupplyPage ? '支付中金额 - CNY' : '收款中金额 - CNY',
                dataIndex: 'finance_linked_money_paying_cny',
              },
              {
                title: this.isSupplyPage ? '已付金额 - CNY' : '已收金额 - CNY',
                dataIndex: 'finance_linked_money_paid_cny',
              },
              { title: 'FS数据', dataIndex: 'fs_data' },
              { title: '税率', dataIndex: 'rate' },
              { title: '成本', dataIndex: 'all_linked_amount' },
              { title: '是否FBP Reviewing', dataIndex: 'is_fbp' },
            ];
            exportData[k].forEach((i) => {
              const d = {};
              this.columns.forEach((c) => {
                d[c.title as any] = i[c.dataIndex!];
              });
              chinaColums.forEach((c) => {
                d[c.title as any] = i[c.dataIndex!];
              });
              chinaItems.push(d);
            });
            let sheet = XLSX.utils.json_to_sheet(chinaItems);
            XLSX.utils.book_append_sheet(wb, sheet, '源数据');
          } else {
            let sheet = XLSX.utils.json_to_sheet(exportData[k]);
            XLSX.utils.book_append_sheet(wb, sheet, k);
          }
        });
        openDownloadDialog(sheet2blob(null, null, wb), `损益汇总.xlsx`);
      },
    },
  });
