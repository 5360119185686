
  const PAGE = {
    BOOK_BATCH: 'book_batch',
    BOOK_CHANGING: 'book_changing',
    ACCT_COMPANY_CODE: 'acct_company_code',
    ACCT_BULINE_CODE: 'acct_buline_code',
    ACCT_TAX_CODE: 'acct_tax_code',
    ACCT_SUBJECT_SUPPLY: 'acct_subject_supply',
    ACCT_SUBJECT_DEMAND: 'acct_subject_demand',
    ACCT_SUBJECT_CHINA_DEMAND: 'acct_subject_china_demand',
    ACCT_SUBJECT_CHINA_SUPPLY: 'acct_subject_china_supply',
  };
  import { BasicColumn, BasicTableProps } from '@/components/WeTable';
  import {
    get_book_batch_list,
    get_book_changing,
    get_company_code,
    check_dup_company_code,
    get_supply_subject_code,
    get_demand_subject_code,
    get_subject_china_code,
    delete_book_batch,
    get_book_item_list,
    add_book_batch,
    import_demand_subject_code,
    import_supply_subject_code,
    import_buline_code,
    import_tax_code,
    import_company_code,
    import_subject_china,
    set_book_changing,
    clear_acct_code,
    get_tax_code,
  } from '@/api/erp-more';
  import { defineComponent, ref } from 'vue';
  import { appStore } from '@/store/modules/app';
  import { useUser } from '@/hooks/useUser';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { required } from '@/utils/validate';
  import { DialogFormItem } from '@/components/WeDialog/types';
  import { useJump } from '@/hooks/useJump';
  import { cacheStore } from '@/store/modules/cache';
  import { isEmptyData, downloadExcelData } from '@/utils/common';
  import { useUtils } from '@/hooks/useUtils';
  import { formatTime } from '@/utils/time';
  import round from 'lodash-es/round';

  export default defineComponent({
    name: 'ERPBillsBillBooking',
    setup() {
      const itemList = ref<Recordable[]>([]);
      const bookBatch = ref<Nullable<Recordable>>(null);
      const bookList = ref<Recordable[]>([]);
      const bookingTipList = ref<Recordable[]>([]);
      const { isGod } = useUser();
      const { model, bulineList, bulineMap, currencyMap, getModelItems, weCompanyList } = useAppInfo();
      const { jumpToBill, jumpToInvoice } = useJump();
      const { showJson } = useUtils();
      return {
        isGod,
        itemList,
        model,
        bulineList,
        bulineMap,
        currencyMap,
        getModelItems,
        bookBatch,
        bookList,
        bookingTipList,
        jumpToBill,
        jumpToInvoice,
        showJson,
        weCompanyList,
      };
    },
    data: () => ({
      PAGE,
      loading: false,
      page: PAGE.BOOK_BATCH,
      showBookBatchIndi: false,
      showBookingTip: false,
      tracePage: 'Bill Booking',
    }),
    computed: {
      bookingTipConv(): Recordable {
        if (this.bookingTipList) {
          return this.bookingTipList.map((x) => {
            if (x.includes(':')) {
              const col1 = x.split(':')[0];
              const col2 = x.split(':')[1];
              return { col1: col1, col2: col2.replace('[', '').replace(']', '') };
            } else {
              return { col1: x, col2: '' };
            }
          });
        }
        return [];
      },
      bookingTipConfig(): BasicTableProps {
        return {
          title: 'Booking Tips',
          immediate: false,
          columns: [
            { title: 'Col 1', dataIndex: 'col1', componentType: 'a-input' },
            { title: 'Col 2', dataIndex: 'col2', componentType: 'a-input' },
          ],
        };
      },
      config(): BasicTableProps {
        const config: BasicTableProps = {
          columns: [],
          immediate: false,
        };
        config.title = 'Bill Booking';
        config.tabConfig = {
          tabItems: [
            { tab: 'Bookings', key: PAGE.BOOK_BATCH },
            { tab: 'Changing', key: PAGE.BOOK_CHANGING },
            { tab: 'Customer Code', key: PAGE.ACCT_COMPANY_CODE },
            { tab: 'Buline Code', key: PAGE.ACCT_BULINE_CODE },
            { tab: 'Tax Code', key: PAGE.ACCT_TAX_CODE },
            { tab: 'Subject Code - Supply', key: PAGE.ACCT_SUBJECT_SUPPLY },
            { tab: 'Subject Code - Demand', key: PAGE.ACCT_SUBJECT_DEMAND },
            { tab: 'Subject Code - China - Demand', key: PAGE.ACCT_SUBJECT_CHINA_DEMAND },
            { tab: 'Subject Code - China - Supply', key: PAGE.ACCT_SUBJECT_CHINA_SUPPLY },
          ],
        };
        // config.tabs.forEach((t) => {
        //   if (t.value === this.page) {
        //     t.selected = true;
        //   }
        // });

        if (this.page === PAGE.BOOK_BATCH) {
          config.columns = [
            {
              title: '名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
              componentType: 'a-input',
              showTop: true,
            },
            {
              title: '创建时间',
              dataIndex: 'create_time',
              componentType: 'a-range-picker',
              showTop: true,
            },
            { title: '跟进人', dataIndex: 'owner_name', componentType: 'a-input', showTop: true },
            { title: '备注', dataIndex: 'note', componentType: 'a-input', showTop: true },
          ];
          if (this.isGod) {
            config.columns.push({
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: {
                customRender: 'action',
              },
            });
          }
        } else if (this.page === PAGE.BOOK_CHANGING) {
          config.columns = [
            {
              title: 'Book Summary',
              dataIndex: 'name',
              slots: {
                customRender: 'book_name',
              },
              componentType: 'a-input',
            },
            // { title: 'Bill Type', dataIndex: 'bill_type' },
            // { title: 'Bill Name', dataIndex: 'bill_name', slots: 'bill_name' },
            { title: 'Changing Type', dataIndex: 'status_name', componentType: 'a-input' },
            { title: 'Current Bill Status', dataIndex: 'bill_status', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: {
                customRender: 'changing_action',
              },
            },
          ];
        } else if (this.page === PAGE.ACCT_COMPANY_CODE) {
          config.columns = [
            { title: '客户名称', dataIndex: 'name', componentType: 'a-input' },
            { title: 'Code', dataIndex: 'company_code', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: {
                customRender: 'company_code_action',
              },
            },
          ];
        } else if (this.page === PAGE.ACCT_BULINE_CODE) {
          config.columns = [
            { title: 'Buline Name', dataIndex: 'name', componentType: 'a-input' },
            { title: 'Code', dataIndex: 'buline_code', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: {
                customRender: 'buline_code_action',
              },
            },
          ];
        } else if (this.page === PAGE.ACCT_TAX_CODE) {
          config.columns = [
            { title: 'Tax Rate', dataIndex: 'tax_rate', componentType: 'a-input' },
            { title: 'Code', dataIndex: 'code', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: {
                customRender: 'tax_code_action',
              },
            },
          ];
        } else if (this.page === PAGE.ACCT_SUBJECT_SUPPLY) {
          config.columns = [
            { title: '客户名称', dataIndex: 'company_name', componentType: 'a-input' },
            { title: 'Buline Name', dataIndex: 'buline_name', componentType: 'a-input' },
            { title: '应付暂估', dataIndex: 'code_yfzg', componentType: 'a-input' },
            { title: '成本', dataIndex: 'code_cb', componentType: 'a-input' },
            { title: '应付结算', dataIndex: 'code_yfjs', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: { customRender: 'supply_subject_code_action' },
            },
          ];
        } else if (this.page === PAGE.ACCT_SUBJECT_DEMAND) {
          config.columns = [
            { title: 'Buline Name', dataIndex: 'buline_name', componentType: 'a-input' },
            { title: '应收暂估', dataIndex: 'code_yszg', componentType: 'a-input' },
            { title: '收入', dataIndex: 'code_sr', componentType: 'a-input' },
            { title: '应收结算', dataIndex: 'code_ysjs', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: { customRender: 'demand_subject_code_action' },
            },
          ];
        } else if (this.page === PAGE.ACCT_SUBJECT_CHINA_DEMAND) {
          config.columns = [
            { title: '品类', dataIndex: 'service_name', componentType: 'a-select', showTop: true },
            { title: '币种', dataIndex: 'currency_name', componentType: 'a-select', showTop: true },
            { title: '应收结算', dataIndex: 'code_cb', componentType: 'a-input' },
            { title: '应交税费销项税', dataIndex: 'code_sf', componentType: 'a-input' },
            { title: '收入', dataIndex: 'code_js', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: { customRender: 'subject_china_code_action' },
            },
          ];
        } else if (this.page === PAGE.ACCT_SUBJECT_CHINA_SUPPLY) {
          config.columns = [
            { title: '品类', dataIndex: 'service_name', componentType: 'a-select', showTop: true },
            { title: '币种', dataIndex: 'currency_name', componentType: 'a-select', showTop: true },
            { title: '成本', dataIndex: 'code_cb', componentType: 'a-input' },
            { title: '应交税费进项税', dataIndex: 'code_sf', componentType: 'a-input' },
            { title: '应付结算', dataIndex: 'code_js', componentType: 'a-input' },
            {
              title: '操作',
              dataIndex: 'id',
              flag: 'ACTION',
              slots: { customRender: 'subject_china_code_action' },
            },
          ];
        }

        return config;
      },
      bookConfig(): BasicTableProps {
        const vm = this;
        const headers: BasicColumn[] = [
          { title: '凭证ID', dataIndex: 'exp_id', componentType: 'a-input' },
          { title: '会计年', dataIndex: 'acct_year', componentType: 'a-input' },
          { title: '会计期间', dataIndex: 'acct_month', componentType: 'a-input' },
          { title: '制单日期', dataIndex: 'acct_time', componentType: 'a-input' },
          { title: '凭证类别', dataIndex: 'exp_type', componentType: 'a-input' },
          { title: '凭证号', dataIndex: 'exp_id', componentType: 'a-input' },
          { title: '制单人', dataIndex: 'owner_name', componentType: 'a-input' },
          { title: '科目编码', dataIndex: 'subject_code', componentType: 'a-input' },
          {
            title: '摘要',
            dataIndex: 'name',
            slots: { customRender: 'name' },
            componentType: 'a-input',
          },
          { title: '借方金额', dataIndex: 'debit_money', componentType: 'a-input' },
          { title: '贷方金额', dataIndex: 'credit_money', componentType: 'a-input' },
          {
            title:
              vm.bookBatch && vm.bookBatch.book_type === vm.model.BookBatch.TYPE.DEMAND ? '客户编码' : '供应商编码',
            dataIndex: 'company_code',
          },
          { title: '项目大类编码', dataIndex: 'buline_category_code' },
          { title: '项目编码', dataIndex: 'buline_code' },
        ];
        if (this.isGod) {
          headers.push({ title: '科目大类', dataIndex: 'subject_category' });
          headers.push({
            title: '账单',
            dataIndex: 'bill_name',
            slots: { customRender: 'bill_name' },
          });
          headers.push({
            title: '发票',
            dataIndex: 'invoice_name',
            slots: { customRender: 'invoice_name' },
          });
        }
        return {
          title: vm.bookBatch ? vm.bookBatch.name : 'Booking Items',
          columns: headers,
          immediate: false,
        };
      },
    },
    created() {
      this.initData();
    },
    methods: {
      isEmptyData,
      downDetail() {
        const items: any = [];
        const chinaWeCompanyIds = this.weCompanyList
          .filter((x) => x.region_type == this.model.WeCompany.REGION_TYPE.CHINA)
          .map((c) => c.id);
        const companyCodeSuffix =
          this.bookBatch && this.bookBatch!.book_type === this.model.BookBatch.TYPE.DEMAND
            ? ':客户档案'
            : ':供应商档案';
        this.bookList.forEach((i, index) => {
          const we_company = this.weCompanyList.find(
            (w) => w.id === this.itemList.find((x) => x.id === i.book_batch_id)!.we_company_id
          );
          const item = {
            '"billhead_$head,main_pk_accountingbook,main_pk_vouchertype,main_num,main_attachment,main_pk_prepared,main_prepareddate,explanation,pk_liabilitycenter,accsubjcode,pk_currtype,debitamount,localdebitamount,groupdebitamount,globaldebitamount,unitname,price,debitquantity,creditquantity,creditamount,localcreditamount,groupcreditamount,globalcreditamount,checkno,checkdate,checkstyle,verifyno,verifydate,bankaccount,billtype,excrate2,excrate3,excrate4,ass_1,ass_2,ass_3,ass_4,ass_5,ass_6,ass_7,ass_8,ass_9,freevalue1,freevalue2,freevalue3,freevalue4,freevalue5,freevalue6,freevalue7,freevalue8,freevalue9,freevalue10,freevalue11,freevalue12,freevalue13,freevalue14,freevalue15,freevalue16,freevalue17,freevalue18,freevalue19,freevalue20,freevalue21,freevalue22,freevalue23,freevalue24,freevalue25,freevalue26,freevalue27,freevalue28,freevalue29,freevalue30"':
              '',
            '*财务核算账簿':
              we_company.name + (chinaWeCompanyIds.includes(we_company.id) ? '-大陆基准账簿' : '-美元核算账簿'),
            '*凭证类别': i.exp_type,
            '*凭证号': i.exp_id,
            附单据数: '',
            '*制单人': i.owner_name,
            '*制单日期': i.acct_time,
            '*摘要': i.name,
            利润中心: '',
            '*科目编码': i.subject_code,
            '*币种': chinaWeCompanyIds.includes(we_company.id) ? '人民币' : '美元',
            '*原币借方金额': this.isEmptyData(i.debit_money) ? '' : round(i.debit_money / i.currency_rate, 2),
            '*本币借方金额': this.isEmptyData(i.debit_money) ? '' : round(i.debit_money / i.currency_rate, 2),
            '*原币贷方金额': this.isEmptyData(i.credit_money) ? '' : round(i.credit_money / i.currency_rate, 2),
            '*本币贷方金额': this.isEmptyData(i.credit_money) ? '' : round(i.credit_money / i.currency_rate, 2),
            集团本币贷方金额: '',
            全局本币贷方金额: '',
            票据号: '',
            业务结算日期: '',
            结算方式: '',
            核销号: '',
            业务日期: '',
            银行账户: '',
            票据类型: '',
            账簿本币汇率: '',
            集团本币汇率: '',
            全局本币汇率: '',
            辅助核算1: i.subject_category.includes('税')
              ? `${i.tax_rate}%:税率`
              : i.company_code
              ? i.company_code + companyCodeSuffix
              : '',
            辅助核算2: i.subject_category.includes('税') ? '' : i.buline_code ? i.buline_code + ':项目' : '',
            辅助核算3: '',
            辅助核算4: '',
            辅助核算5: '',
            辅助核算6: '',
            辅助核算7: '',
            辅助核算8: '',
            辅助核算9: '',
            分录自定义项1: '',
            分录自定义项2: '',
            分录自定义项3: '',
            分录自定义项4: '',
            分录自定义项5: '',
            分录自定义项6: '',
            分录自定义项7: '',
            分录自定义项8: '',
            分录自定义项9: '',
            分录自定义项10: '',
            分录自定义项11: '',
            分录自定义项12: '',
            分录自定义项13: '',
            分录自定义项14: '',
            分录自定义项15: '',
            分录自定义项16: '',
            分录自定义项17: '',
            分录自定义项18: '',
            分录自定义项19: '',
            分录自定义项20: '',
            分录自定义项21: '',
            分录自定义项22: '',
            分录自定义项23: '',
            分录自定义项24: '',
            分录自定义项25: '',
            分录自定义项26: '',
            分录自定义项27: '',
            分录自定义项28: '',
            分录自定义项29: '',
            分录自定义项30: '',
          };
          if (index === 0) {
            const first = {};
            const title = {};
            Object.keys(item).forEach((x, itemIndex) => {
              if (itemIndex === 0) {
                first[x] =
                  '*导入须知:1.表格中不能增、删、改列及固有内容2.所有内容必须为文本格式;表格中有多个档案名称字段是为了实现多语,如果没有多语只录第一个名称字段即可3.枚举项输入错误时，则按默认值处理;勾选框的导入需输入N、Y;日期类型填写示例：2022-05-17;参照档案支持按照编码或名称导入，名称有重复时则可按照编码导入。针对物料多版本字段，如果没有填写版本号字段取值则自动赋值为匹配到数据中的最新版本；比如同一物料版本1、2、3的名称分别为A、A、B，导入A则自动按照版本2赋值。4.导入带有子、孙表的档案时，主、子、孙区域之间必须有一空行，主、子表对应数据在第一列填写相同的行号，子、孙对应数据在子表末尾行号列、孙表第一列填写相同的行号';
              } else {
                first[x] = '';
              }
              title[x] = x;
            });
            items.push(first);
            items.push(title);
          }
          items.push(item);
        });

        downloadExcelData(items, `exported-${this.bookBatch!.name}`, true);
      },
      selectTab(tabValue) {
        this.page = tabValue;
        this.initData();
      },
      async initData() {
        this.loading = true;
        const vm = this;
        this.itemList = [];
        if (this.page === PAGE.BOOK_BATCH) {
          const res = await get_book_batch_list({});
          this.itemList = res.book_batch_list;
        } else if (this.page === PAGE.BOOK_CHANGING) {
          const res = await get_book_changing({});
          res.book_changing_list.forEach((x) => {
            x.status_name = vm.model.Book.STATUS[x.status];
          });
          this.itemList = res.book_changing_list;
        } else if (this.page === PAGE.ACCT_COMPANY_CODE) {
          const res = await get_company_code({});
          this.itemList = res.company_list;
          check_dup_company_code({}).then((res2) => {
            if (res2.dup_list.length) {
              this.showBookingTip = true;
              this.bookingTipList = res2.dup_list;
            }
          });
        } else if (this.page === PAGE.ACCT_BULINE_CODE) {
          await cacheStore.GetAppInfoAction();
          this.itemList = this.bulineList.filter((x) => x.buline_code);
        } else if (this.page === PAGE.ACCT_TAX_CODE) {
          const res = await get_tax_code({});
          this.itemList = res.tax_code_list;
        } else if (this.page === PAGE.ACCT_SUBJECT_SUPPLY) {
          const res = await get_supply_subject_code({});
          res.subject_code_list.forEach((x) => {
            x.buline_name = vm.bulineMap[x.buline_id] ? vm.bulineMap[x.buline_id].name : '';
          });
          this.itemList = res.subject_code_list;
        } else if (this.page === PAGE.ACCT_SUBJECT_DEMAND) {
          const res = await get_demand_subject_code({});
          res.subject_code_list.forEach((x) => {
            x.buline_name = vm.bulineMap[x.buline_id] ? vm.bulineMap[x.buline_id].name : '';
          });
          this.itemList = res.subject_code_list;
        } else if (this.page === PAGE.ACCT_SUBJECT_CHINA_DEMAND) {
          const res = await get_subject_china_code({});
          res.code_list
            .filter((c) => c.direction === vm.model.BookBatch.TYPE.DEMAND)
            .forEach((x) => {
              x.currency_name = vm.currencyMap[x.currency_id] ? vm.currencyMap[x.currency_id].name : '';
            });
          this.itemList = res.code_list.filter((c) => c.direction === vm.model.BookBatch.TYPE.DEMAND);
        } else if (this.page === PAGE.ACCT_SUBJECT_CHINA_SUPPLY) {
          const res = await get_subject_china_code({});
          res.code_list
            .filter((c) => c.direction === vm.model.BookBatch.TYPE.SUPPLY)
            .forEach((x) => {
              x.currency_name = vm.currencyMap[x.currency_id] ? vm.currencyMap[x.currency_id].name : '';
            });
          this.itemList = res.code_list.filter((c) => c.direction === vm.model.BookBatch.TYPE.SUPPLY);
        }
        this.loading = false;
      },
      deleteBookBatch(bookBatch) {
        this.$showAlert('Warning', 'Are you sure to delete this Book Batch?', () => {
          delete_book_batch({ book_batch_id: bookBatch.id }).then(() => {
            appStore.SET_ALERT_CONFIG(null);
            this.initData();
          });
        });
      },
      showBookBatch(bookBatch) {
        this.bookList = [];
        this.bookBatch = bookBatch;
        this.showBookBatchIndi = true;
        get_book_item_list({ book_batch_id: bookBatch ? bookBatch.id : null }).then(
          (res) => (this.bookList = res.book_list)
        );
      },
      createBookBatch() {
        const vm = this;
        const actionControl = [
          { text: '预览', value: 'preview' },
          { text: '生成Booking', value: 'normal' },
        ];
        if (this.isGod) {
          actionControl.push({ text: '生成错误Booking', value: 'gen_error' });
        }

        const inputs: DialogFormItem[] = [
          {
            type: 'select',
            result: 'book_category',
            label: 'Category',
            items: vm.getModelItems(vm.model.BookBatch.CATEGORY),
            rules: [required()],
            width: 12,
          },
          {
            type: 'select',
            result: 'book_type',
            label: 'Type',
            items: vm.getModelItems(vm.model.BookBatch.TYPE),
            rules: [required()],
            width: 12,
          },
          {
            type: 'select',
            result: 'action_control',
            label: 'Action Control',
            init: 'preview',
            items: actionControl,
            rules: [required()],
            width: 12,
          },
          {
            type: 'select',
            result: 'time_control',
            label: 'Time Control',
            init: 'current_time',
            items: [
              { text: '上月暂估记账的“业务期间”算到当前时间', value: 'current_time' },
              { text: '上月暂估记账的“业务期间”算到上个月最后一天', value: 'last_month' },
            ],
            rules: [required()],
            // show: (result) =>
            //   isEmptyData(result.book_category) || result.book_category !== vm.model.BookBatch.CATEGORY.TO_PAY,
            width: 24,
          },
          {
            label: '月份',
            result: 'month_control',
            type: 'month',
            init: formatTime(new Date(), 'YYYY-MM'),
            rules: [required()],
            show: () => false,
            // show: (result) =>
            //   !isEmptyData(result.book_category) && result.book_category === vm.model.BookBatch.CATEGORY.TO_PAY,
            width: 24,
          },
        ];

        this.$showDialog({
          title: 'Add Booking',
          width: '700px',
          labelCol: '120px',
          blocks: [
            { type: 'input', value: inputs },
            { type: 'note', result: 'note', label: 'Note', width: 24 },
          ],
          callback: (result) => {
            add_book_batch(result).then((res) => {
              appStore.SET_WE_DIALOG(null);
              this.initData();
              if (res.all_error_list.length) {
                this.showBookingTip = true;
                this.bookingTipList = res.all_error_list;
              } else if (result.action_control === 'preview') {
                this.showBookingTip = true;
                this.bookingTipList = res.all_preview_list;
              }
            });
          },
        });
      },
      batchImportCode(import_type) {
        let headers: string[] = [];
        if (import_type === 'Demand-Subject-Code') {
          headers = ['Buline Name', '应收暂估', '收入', '应收结算'];
        } else if (import_type === 'Supply-Subject-Code') {
          headers = ['Customer Name', 'Buline Name', '应付暂估', '成本', '应付结算'];
        } else if (import_type === 'Buline-Code') {
          headers = ['Buline Name', 'Code'];
        } else if (import_type === 'Tax-Code') {
          headers = ['Tax Rate', 'Code'];
        } else if (import_type === 'Customer-Code') {
          headers = ['Customer Name', 'Code'];
        } else if (PAGE.ACCT_SUBJECT_CHINA_DEMAND === import_type) {
          headers = ['品类', '币种', '应收结算', '应交税费销项税', '收入'];
        } else if (PAGE.ACCT_SUBJECT_CHINA_SUPPLY === import_type) {
          headers = ['品类', '币种', '成本', '应交税费进项税', '应付结算'];
        }
        this.$showDialog({
          title: 'Import ' + import_type,
          blocks: [{ type: 'excel', result: 'excel_data', config: { headers: headers } }],
          callback: async (result) => {
            let res: Recordable = {};
            if (import_type === 'Demand-Subject-Code') {
              headers = ['Buline Name', '应收暂估', '收入', '应收结算'];
              res = await import_demand_subject_code(result);
            } else if (import_type === 'Supply-Subject-Code') {
              headers = ['Customer Name', 'Buline Name', '应付暂估', '成本', '应付结算'];
              res = await import_supply_subject_code(result);
            } else if (import_type === 'Buline-Code') {
              headers = ['Buline Name', 'Code'];
              res = await import_buline_code(result);
            } else if (import_type === 'Tax-Code') {
              headers = ['Tax Rate', 'Code'];
              res = await import_tax_code(result);
            } else if (import_type === 'Customer-Code') {
              headers = ['Customer Name', 'Code'];
              res = await import_company_code(result);
            } else if (PAGE.ACCT_SUBJECT_CHINA_DEMAND === import_type) {
              headers = ['品类', '币种', '应收结算', '应交税费销项税', '收入'];
              result.is_supply = false;
              res = await import_subject_china(result);
            } else if (PAGE.ACCT_SUBJECT_CHINA_SUPPLY === import_type) {
              headers = ['品类', '币种', '成本', '应交税费进项税', '应付结算'];
              result.is_supply = true;
              res = await import_subject_china(result);
            }
            appStore.SET_WE_DIALOG(null);
            this.initData();
            headers.push('Error');
            if (res.fail_list.length) {
              this.$showAlert('Failed Items', {
                config: {
                  title: 'Failed Items',
                  immediate: false,
                  showTableHeader: false,
                  useSearchForm: false,
                  columns: headers.map((x) => ({ title: x, dataIndex: x, showString: true })),
                },
                items: res.fail_list,
              });
            }
          },
        });
      },
      setCheck(book) {
        this.$showAlert('Warning', 'Are you sure to check it?', () => {
          set_book_changing({ book_id: book.id }).then(() => {
            appStore.SET_ALERT_CONFIG(null);
            this.initData();
          });
        });
      },
      clearAcctCode(item, type) {
        this.$showAlert('Warning', 'Are you sure to clear the code?', () => {
          clear_acct_code({ id: item.id, type: type }).then(() => {
            appStore.SET_ALERT_CONFIG(null);
            this.initData();
          });
        });
      },
      initAll() {
        this.$showAlert('Warning', 'Re-Init All?', () => {
          this.itemList.forEach((item) => {
            delete_book_batch({ book_batch_id: item.id }).then(() => {
              appStore.SET_ALERT_CONFIG(null);
              this.initData();
            });
          });
        });
      },
    },
    watch: {
      page() {
        this.initData();
      },
    },
  });
