
  import { send_bill_email_bundle, get_bill_email_history } from '@/api/erp-bills';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  import { defineComponent, ref } from 'vue';
  import cloneDeep from 'lodash-es/cloneDeep';

  export default defineComponent({
    name: 'EditEmail',
    props: ['value', 'entity', 'entityType'],
    setup() {
      const selectedFileIdList = ref<Recordable[]>([]);
      const selectedSendToList = ref<Recordable[]>([]);
      const selectedTemplateId = ref<Number>(0);
      const selectedItem = ref<Recordable>({});

      const selectedCopyToList = ref<Recordable[]>([]);
      const { apiRoot } = useAppInfo();
      return {
        selectedFileIdList,
        selectedSendToList,
        selectedCopyToList,
        selectedTemplateId,
        selectedItem,
        apiRoot,
      };
    },
    data: () => ({
      show: false,
      loading: false,
    }),
    computed: {
      templateItems() {
        let ret = [];
        if (this.value) {
          ret = cloneDeep(this.value).map((x) => ({ text: x.name, value: x.id }));
        }
        return ret;
      },
    },
    watch: {
      value: {
        handler() {
          this.show = !isEmptyData(this.value);
          if (this.show) {
            this.initData();
          }
        },
        immediate: true,
      },
      selectedTemplateId: {
        handler(val) {
          this.setTemplate(val);
        },
      },
    },
    emits: ['update:value', 'sent'],
    methods: {
      initData() {
        if (this.value) {
          this.setTemplate(this.value[0].id);
        }
      },
      cancel() {
        this.$emit('update:value', null);
      },
      setTemplate(tid) {
        this.selectedTemplateId = tid;
        this.selectedItem = this.value.find((x) => x.id === this.selectedTemplateId);
        this.selectedFileIdList = [];
        this.selectedCopyToList = this.selectedItem.copy_to_list;
        this.selectedSendToList = this.selectedItem.send_to_list;
      },
      getFileLink(f) {
        if (f.is_link) {
          return f.file_path;
        } else {
          return `${this.apiRoot}/get_entity_file/?entity_file_id=${f.id}&entity_id=${this.entity.id}&entity_type=${this.entityType}`;
        }
      },
      sendEmail() {
        let message = '确定发送该邮件吗';
        if (this.selectedFileIdList.length === 0) {
          message = '检测到未勾选附件，是否直接发送';
        }
        this.$showAlert('Warning', message, () => {
          this.loading = true;
          appStore.SET_WE_DIALOG(null);
          const params = {
            entity_type: this.entityType,
            entity_id: this.entity.id,
            template_id: this.selectedItem.id,
            title: document.getElementById('email_title')!.innerText,
            content: document.getElementById('email_content')!.innerHTML,
            file_id_list: this.selectedFileIdList,
            send_to_list: this.selectedSendToList,
            copy_to_list: this.selectedCopyToList,
          };
          send_bill_email_bundle(params).then((res) => {
            if (res.error_code === 0) {
              this.$showAlert('Message', 'Email has been Sent!');
              this.$emit('update:value', null);
              this.$emit('sent');
            } else {
              this.$showAlert('Warning', res.error_message);
            }
            this.loading = false;
          });
        });
      },
      showEmailHistory() {
        get_bill_email_history({ bill_id: this.entity.id }).then((res) => {
          const historyHeaders = [
            { title: '时间', dataIndex: 'time' },
            { title: '类型', dataIndex: 'template_name' },
            { title: '发送人', dataIndex: 'sender_id' },
            { title: '发送列表', dataIndex: 'send_to_emails' },
            { title: '抄送列表', dataIndex: 'copy_to_emails' },
            { title: '标题', dataIndex: 'title' },
            { title: '附件', dataIndex: 'file_names' },
            { title: '内容', dataIndex: 'content' },
          ];
          this.$showAlert('邮件历史', { config: { columns: historyHeaders }, items: res });
        });
      },
    },
  });
