import { get_bank_list } from '@/api/commonList';
import { get_company_list } from '@/api/crm-customer';
import { get_bill_email_template_list } from '@/api/erp-more';
import { edit_contract, save_no_business_contract, batch_edit_contract } from '@/api/erp-contracts';
import { get_similarity_companys } from '@/api/commonList';
import { DialogFormItem } from '@/components/WeDialog/types';
import { useAppInfo } from '@/hooks/useAppInfo';
import { useJump } from '@/hooks/useJump';
import { useUser } from '@/hooks/useUser';
import { appStore } from '@/store/modules/app';
import { findDialogInput, isEmptyData } from '@/utils/common';
import { formatTime } from '@/utils/time';
import { isNumber, required, duplicateCompanyShortName } from '@/utils/validate';
import { ref, unref } from 'vue';
import { useApp } from '@/hooks/useApp';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';

export function useContract() {
  const {
    model,
    teamMap,
    bulineMap,
    currencyMap,
    userMap,
    teamList,
    weCompanyList,
    appInfo,
    currencyList,
    getMyTeamList,
  } = useAppInfo();
  const { user, isAdmin, isOne } = useUser();
  const { $showDialog, $showAlert } = useApp();
  const required_items = ref<Recordable[]>([]);
  const ugs_sign_type_items = ref<Recordable[]>([
    { id: '双签', name: '双签' },
    { id: '境内单签', name: '境内单签' },
    { id: '境外单签', name: '境外单签' },
  ]);

  function initContract(contract) {
    const modelVal = unref(model)!;

    contract.show_check_bill_time_point = contract.check_bill_time_point
      ? modelVal.Contract.TIME_POINT[contract.check_bill_time_point]
      : '';
    contract.show_pay_money_time_point = contract.pay_money_time_point
      ? modelVal.Contract.TIME_POINT[contract.pay_money_time_point]
      : '';

    contract.is_demand = contract.contract_type === modelVal.Contract.DIRECTION.DEMAND;
    contract.is_supply = contract.contract_type === modelVal.Contract.DIRECTION.SUPPLY;

    contract.is_normal = contract.contract_property === modelVal.Contract.CONTRACT_PROPERTY.NORMAL;
    contract.is_no_bills = contract.contract_property === modelVal.Contract.CONTRACT_PROPERTY.NO_BILLS;
    contract.is_prepay = contract.contract_property === modelVal.Contract.CONTRACT_PROPERTY.PREPAY;

    contract.is_business = contract.category === modelVal.Contract.CATEGORY.BUSINESS;
    contract.is_purchase = contract.category === modelVal.Contract.CATEGORY.PURCHASE;
    contract.is_backup = contract.category === modelVal.Contract.CATEGORY.BACKUP;
    contract.category_name = modelVal.Contract.CATEGORY.PURCHASE[contract.category];

    contract.gen_bill_type_name = modelVal.Contract.GEN_BILL_TYPE[contract.gen_bill_type];
    contract.template_type_name = modelVal.Contract.TEMPLATE_TYPE[contract.template_type];
    contract.show_begin_time = formatTime(contract.begin_time, 'YYYY-MM-DD');
    contract.show_end_time = formatTime(contract.end_time, 'YYYY-MM-DD');

    contract.split_list.forEach((s) => {
      s.name =
        (s.team_id ? unref(teamMap)[s.team_id].name : 'NULL') +
        '-' +
        (s.am_id ? unref(userMap)[s.am_id].name : 'NULL') +
        '-' +
        (s.buline_id ? unref(bulineMap)[s.buline_id].name : 'NULL');
    });
    contract.split_names = contract.split_list.map((s) => s.name).join('，');

    if (contract.buline_id) {
      contract.buline = unref(bulineMap)[contract.buline_id];
      contract.buline_list = [contract.buline];
      contract.show_buline_name = contract.buline.name;
    } else {
      contract.show_buline_name = '';
      contract.buline_list = [contract.buline];
      if (contract.bulines) {
        contract.buline_list = contract.bulines.map((x) => unref(bulineMap)[x]);
        contract.show_buline_name = contract.buline_list.map((x) => x.name).join(', ');
      }
    }
    contract.currency = contract.currency_id ? unref(currencyMap)[contract.currency_id] : modelVal.UNKNOWN;
    contract.owner = contract.owner_id ? unref(userMap)[contract.owner_id] : modelVal.UNKNOWN;
    contract.team = contract.team_id ? unref(teamMap)[contract.team_id] : modelVal.UNKNOWN;
    contract.am_team = contract.am_team_id ? unref(teamMap)[contract.am_team_id] : modelVal.UNKNOWN;
    contract.am = contract.am_id ? unref(userMap)[contract.am_id] : modelVal.UNKNOWN;
    contract.legal_reviewer = contract.legal_reviewer_id
      ? unref(userMap)[contract.legal_reviewer_id]
      : modelVal.UNKNOWN;
    contract.analysis_reviewer = contract.analysis_reviewer_id
      ? unref(userMap)[contract.analysis_reviewer_id]
      : modelVal.UNKNOWN;

    contract.show_company_name = contract.company ? contract.company.name : '';
    contract.show_company_short_name = contract.company ? contract.company.short_name : '';
    contract.show_we_company_name = contract.we_company ? contract.we_company.name : '';

    contract.owner_name = contract.owner.name;
    contract.am_name = contract.am ? contract.am.name : '';
    contract.collapsed = false;
  }

  function initContractList(contractList) {
    contractList.forEach((contract) => initContract(contract));
  }

  const { jumpToContract } = useJump();

  const gotResult = (res, afterEditContract?) => {
    if (afterEditContract) {
      afterEditContract(res);
    } else {
      jumpToContract(res.contract);
    }
  };

  const addInternalContract = async (company_id, afterEditContract?) => {
    company_id = parseInt(company_id);

    const tempTeam = user.value.user_team_list.map((ut) => unref(teamMap)[ut.team_id]);
    const teamItems = (unref(isOne) ? unref(teamList) : tempTeam).map((t) => ({ text: t.name, value: t.id }));

    const companyList = await (
      await get_company_list({ get_all: true, pick_fields: ['id', 'name', 'short_name'] })
    ).company_list;
    const companyItems = companyList.filter((x) => x.short_name).map((c) => ({ text: c.name, value: c.id }));

    const blocks: DialogFormItem[] = [
      {
        type: 'input',
        value: [
          {
            result: 'company_id',
            label: 'Customer',
            type: 'auto-select',
            items: companyItems,
            rules: [required()],
            init: company_id,
            disabled: true,
          },
          { result: 'we_company_id', label: 'Webeye', type: 'auto-select', items: weCompanyList, rules: [required()] },
          { result: 'team_id', label: 'Team', type: 'auto-select', items: teamItems, rules: [required()] },
        ],
      },
    ];

    if (!companyList.find((x) => x.id === company_id)!.short_name) {
      $showAlert('Warning', 'Please Add "Short Name" for this Customer');
      return;
    }

    $showDialog({
      title: '新建内部交易合同',
      width: '920px',
      blocks: blocks,
      callback: (result) => {
        const params = { __meta: { edit_mode: 'add_internal', force_add: true }, ...result };
        edit_contract(params).then((res) => {
          appStore.SET_WE_DIALOG(null);
          gotResult(res, afterEditContract);
        });
      },
    });
  };
  const editContract = async (contract, callBack) => {
    const currencyItems = currencyList.value.map((c) => ({ text: c.name, value: c.id }));
    const team = teamMap.value[contract.team_id];
    let bulineList = team.buline_list.map((buline) => ({
      text: buline.note ? `${buline.name} (${buline.note})` : buline.name,
      value: buline.id,
      system: buline.system,
    }));
    const bulines = team.buline_list.map((buline) => buline.id);
    if (!isEmptyData(contract)) {
      bulineList = bulineList.concat(
        appInfo.value.buline_list
          .filter((b) => contract.buline_ids.includes(b.id) && !bulines.includes(b.id))
          .map((buline) => ({
            text: buline.note ? `${buline.name} (${buline.note})` : buline.name,
            value: buline.id,
            system: buline.system,
          }))
      );
    }
    const bankList = await get_bank_list();
    const weBankList = bankList
      .filter((item) => item.we_company_id === contract.we_company_id)
      .map((b) => ({ name: `${b.bf_bank}(${b.bf_account_number})`, id: b.id }));
    const companyBankList = bankList
      .filter((b) => b.company_id === contract.company_id)
      .map((b) => ({ name: b.bf_bank, id: b.id }));
    const isPurchase = contract.category === model.value.Contract.CATEGORY.PURCHASE;
    const isBackup = contract.category === model.value.Contract.CATEGORY.BACKUP;
    const weCompanyItems = weCompanyList.value.filter((c) => !c.is_history).map((c) => ({ text: c.name, value: c.id }));
    let logicDetailItems: Recordable[] = [];
    if (contract.is_demand) {
      logicDetailItems = appInfo.value.code_const.DEMAND_CONTRACT_LOGIC_DETAIL;
    } else if (contract.is_supply) {
      logicDetailItems = appInfo.value.code_const.SUPPLY_CONTRACT_LOGIC_DETAIL;
    }
    if (!isOne.value) {
      logicDetailItems = logicDetailItems.filter((x) => x.logic_type === contract.logic_type);
    }
    if (
      isEmptyData(contract) ||
      bulineList.filter((b) => contract.buline_ids.includes(b.value) && ['AppMeta', 'DSP'].includes(b.text)).length !==
        2
    ) {
      logicDetailItems.forEach((item) => {
        if (item.value === 'AppMeta&DSP') {
          item.disabled = true;
        }
      });
    }
    let inputs: any[] = [];
    if (isPurchase) {
      inputs.push({
        type: 'auto-select',
        label: '币种',
        result: 'currency_id',
        rules: [required()],
        items: currencyItems,
        width: 12,
        disabled: () => !isAdmin.value,
      });
      inputs.push({
        type: 'auto-select',
        label: '我方主体',
        result: 'we_company_id',
        width: 12,
        items: weCompanyItems,
        disabled: () => !isAdmin.value,
        rules: [required()],
      });
      inputs.push({
        type: 'date',
        label: '开始时间',
        result: 'begin_time',
        width: 12,
        rules: [required()],
      });
      inputs.push({
        type: 'date',
        label: '结束时间',
        result: 'end_time',
        width: 12,
        rules: [required()],
      });
      inputs.push({
        type: 'auto-select',
        label: '我方银行',
        result: 'we_company_bank_id',
        width: 12,
        items: weBankList,
      });
      inputs.push({
        type: 'auto-select',
        label: '对方银行',
        result: 'bank_id',
        width: 12,
        items: companyBankList,
      });
    } else if (isBackup) {
      inputs.push({
        type: 'auto-select',
        label: '我方主体',
        result: 'we_company_id',
        width: 24,
        items: weCompanyItems,
        disabled: () => !isAdmin.value,
        rules: [required()],
      });
    } else {
      const emailList = await get_bill_email_template_list();
      inputs = [
        {
          type: 'date',
          label: '开始时间',
          result: 'begin_time',
          width: 6,
          rules: [required()],
        },
        {
          type: 'date',
          label: '结束时间',
          result: 'end_time',
          width: 6,
          rules: [required()],
        },
        {
          type: 'auto-select',
          label: '币种',
          result: 'currency_id',
          rules: [required()],
          items: currencyItems,
          width: 6,
          disabled: () => contract.is_finished,
        },
        {
          type: 'auto-select',
          label: '付款类型',
          result: 'contract_property',
          rules: [required()],
          items: [
            { text: 'Normal', value: 1 },
            { text: 'Prepay', value: 2 },
          ],
          width: 6,
          disabled: () => contract.is_finished,
        },
        {
          type: 'auto-select',
          label: '生成账单',
          result: 'gen_bill_type',
          items: [
            { text: '合同闭环后自动生成', value: 0 },
            { text: '仅手动生成', value: 1 },
            { text: '每月固定生成', value: 2 },
          ],
          width: 12,
          rules: [required()],
        },
        {
          type: 'auto-select',
          label: '账单模式',
          result: 'is_parent',
          items: [
            { text: '主子账单(M/S账单)', value: true },
            { text: '单条普通账单(N账单)', value: false },
          ],
          width: 12,
          rules: [required()],
        },
        {
          type: 'auto-select',
          label: '对账期',
          result: 'check_bill_time_point',
          items: [{ text: 'Next Month First Day', value: 1 }],
          width: 12,
          rules: [required()],
        },
        {
          type: 'number',
          label: '对账天数',
          result: 'check_bill_timespan',
          width: 12,
          rules: [required()],
        },
      ];
      if (contract.is_demand) {
        inputs.push({
          type: 'auto-select',
          label: '收款期',
          result: 'pay_money_time_point',
          items: [
            { text: 'After Invoice', value: 2 },
            { text: 'Next Month First Day', value: 1 },
          ],
          width: 12,
          rules: [required()],
          show: (result) => result.contract_property !== model.value.Contract.CONTRACT_PROPERTY.PREPAY,
        });
        inputs.push({
          type: 'number',
          label: '收款天数',
          result: 'pay_money_timespan',
          width: 12,
          rules: [required()],
          show: (result) => result.contract_property !== model.value.Contract.CONTRACT_PROPERTY.PREPAY,
        });
        if (contract.logic_type === model.value.Contract.LOGIC_TYPE.TAURUS) {
          inputs.push({
            type: 'number',
            label: '税率',
            result: 'cloud_tax',
            width: 12,
            init: 0,
            placeholder: '请填写小数，例0.01',
            componentProps: {
              min: 0,
              max: 1,
            },
            rules: [required()],
          });
        }
        // inputs.push({
        //   type: 'auto-select',
        //   label: '开票方式',
        //   result: 'is_manual_invoice',
        //   width: 12,
        //   rules: [required()],
        //   items: [
        //     { text: '自动开票', value: false },
        //     { text: '人工开票', value: true },
        //   ],
        // });
      } else {
        inputs.push({
          type: 'auto-select',
          label: '付款期',
          result: 'pay_money_time_point',
          items: [
            { text: 'After Invoice', value: 2 },
            { text: 'Next Month First Day', value: 1 },
          ],
          width: 12,
          rules: [required()],
          show: (result) => result.contract_property !== model.value.Contract.CONTRACT_PROPERTY.PREPAY,
        });
        inputs.push({
          type: 'number',
          label: '付款天数',
          result: 'pay_money_timespan',
          width: 12,
          rules: [required()],
          show: (result) => result.contract_property !== model.value.Contract.CONTRACT_PROPERTY.PREPAY,
        });
      }
      inputs.push({
        type: 'auto-select',
        label: '业务线',
        result: 'buline_id',
        items: bulineList,
        width: 12,
        rules: [required()],
        show: (result) => result.is_parent === false,
      });
      inputs.push({
        type: 'auto-select',
        label: '业务线',
        result: 'buline_ids',
        mode: 'multiple',
        items: bulineList,
        width: 12,
        rules: [required()],
        show: (result) => result.is_parent === true,
        change: (result, _input, config, _dialog) => {
          const entityInput = findDialogInput(config, 'logic_detail');
          if (
            team.buline_list.filter((b) => result.buline_ids.includes(b.id) && ['AppMeta', 'DSP'].includes(b.name))
              .length === 2
          ) {
            entityInput.items.forEach((item) => {
              item.disabled = false;
            });
            if (isEmptyData(result.logic_detail)) {
              result.logic_detail = ['AppMeta&DSP'];
            } else if (!result.logic_detail.includes('AppMeta&DSP')) {
              result.logic_detail.push('AppMeta&DSP');
            }
          } else {
            entityInput.items.forEach((item) => {
              if (item.value === 'AppMeta&DSP') {
                item.disabled = true;
              }
            });
            result.logic_detail = result.logic_detail.filter((l) => l !== 'AppMeta&DSP');
          }
        },
      });
      inputs.push({
        type: 'auto-select',
        label: '业务标签',
        result: 'logic_detail',
        mode: 'multiple',
        items: logicDetailItems,
        width: 12,
        rules: contract.logic_type === model.value.Contract.LOGIC_TYPE.MEDIA ? [required()] : [],
      });
      if (unref(isAdmin)) {
        inputs.push({
          type: 'auto-select',
          label: '我方银行',
          result: 'we_company_bank_id',
          width: 12,
          items: weBankList,
        });
        inputs.push({
          type: 'auto-select',
          label: '对方银行',
          result: 'bank_id',
          width: 12,
          items: companyBankList,
        });
      }
      inputs.push({
        type: 'number',
        label: 'Cloud税率',
        result: 'cloud_tax',
        rules: [isNumber],
        placeholder: ' (example: 0.01)',
        width: 12,
        show: () =>
          [
            model.value.Contract.LOGIC_TYPE.CLOUD,
            model.value.Contract.LOGIC_TYPE.EE,
            model.value.Contract.LOGIC_TYPE.COMPUTING,
          ].includes(contract.logic_type),
      });
      inputs.push({
        type: 'auto-select',
        label: '预警邮件类型',
        result: 'email_template_ids',
        mode: 'multiple',
        width: 12,
        items: emailList
          .filter((e) => e.logic_type === model.value.Contract.LOGIC_TYPE.MEDIA)
          .map((e) => ({ text: e.template_name, value: e.id })),
        show: () => contract.logic_type === model.value.Contract.LOGIC_TYPE.MEDIA,
      });
      inputs.push({
        type: 'auto-select',
        label: '是否双签',
        result: 'ugs_sign_type',
        width: 12,
        items: ugs_sign_type_items.value,
        rules: [required()],
        show: () => contract.is_demand && contract.logic_type === model.value.Contract.LOGIC_TYPE.MEDIA,
      });
    }

    $showDialog({
      title: '修改合同',
      labelCol: '100px',
      width: '1000px',
      blocks: [{ type: 'input', value: inputs }],
      init: contract,
      callback: (result) => {
        const params = {
          __meta: { edit_mode: '', contract_id: contract.id },
          ...result,
        };
        edit_contract(params).then((res) => {
          appStore.SET_WE_DIALOG(null);
          gotResult(res, callBack);
        });
      },
    });
  };
  const addNoBusinessContract = async (mode, callBack?, businessContract?) => {
    const currencyItems = currencyList.value.map((c) => ({ text: c.name, value: c.id }));
    const weCompanyItems = weCompanyList.value.filter((c) => !c.is_history).map((c) => ({ text: c.name, value: c.id }));
    const editBody = businessContract || Object();
    let company_id = editBody.company_id;
    let option: any = {};
    const companyList = (await get_company_list({ get_all: true, pick_fields: ['id', 'name', 'short_name'] }))
      .company_list;
    const handleSearch = debounce((result, record, config, context) => {
      record.company_short_name = null;
      if (isEmptyData(result.company_name)) {
        record.items = [];
      } else {
        get_similarity_companys({ name: result.company_name }).then((res) => {
          record.items = res.similarity_companys.map((x) => ({
            text: x.name,
            value: x.name,
            id: x.id,
            short_name: x.short_name,
          }));
          option = record.items.find((x) => x.value === result.company_name);
          if (isEmptyData(option) || option.id != company_id) {
            company_id = null;
            result.company_short_name = '';
          }
          if (!isEmptyData(option)) {
            result.company_short_name = option.short_name;
            company_id = option.id;
          }
          const entity = findDialogInput(config, 'company_short_name');
          if (entity.disabled(result)) {
            entity.rules = [required()];
          } else {
            entity.rules = [required(), duplicateCompanyShortName(companyList)];
          }
          context.refresh_config(config);
        });
      }
    }, 800);
    const handleSelect = (result, record) => {
      option = record.items.find((x) => x.value === result.company_name);
      company_id = option!.id;
      result.company_short_name = option!.short_name;
    };
    $showDialog({
      title: '新建合同',
      labelCol: '120px',
      width: '600px',
      init: editBody,
      blocks: [
        {
          type: 'input',
          value: [
            {
              type: 'auto-select',
              label: '合同类别',
              result: 'mode',
              rules: [required()],
              items: [
                { text: 'Purchase（一次性采购合同）', value: 'add_purchase' },
                { text: 'Backup（补充协议、备份文件等与账单无关的合同）', value: 'add_backup' },
              ],
              width: 24,
              int: mode,
              show: () => isEmptyData(mode),
            },
            {
              type: 'auto-select',
              label: '部门',
              result: 'team_id',
              rules: [required()],
              items: getMyTeamList(true).map((t) => ({ text: t.name, value: t.id })),
              width: 24,
              disabled: () => !isEmptyData(editBody.team_id),
            },
            {
              type: 'auto-select',
              label: '币种',
              result: 'currency_id',
              rules: [required()],
              items: currencyItems,
              width: 24,
              show: (result) => (mode || result.mode) == 'add_purchase',
            },
            {
              type: 'auto-complete',
              label: '客户名称',
              result: 'company_name',
              rules: [required()],
              width: 24,
              items: [],
              change: (result, input, config, content) => {
                if (isEmptyData(editBody.company_name)) handleSearch(result, input, config, content);
              },
              select: (result, input, _config) => {
                if (isEmptyData(editBody.company_name)) handleSelect(result, input);
              },
              disabled: () => !isEmptyData(editBody.company_name),
            },
            {
              type: 'text',
              label: '客户简称',
              result: 'company_short_name',
              rules: !isEmptyData(editBody.company_short_name)
                ? [required()]
                : [required(), duplicateCompanyShortName(companyList)],
              width: 24,
              disabled: (result) =>
                (!isEmptyData(result.company_name) && !isEmptyData(option) && !isEmptyData(option.short_name)) ||
                !isEmptyData(editBody.company_short_name),
            },
            {
              type: 'auto-select',
              label: '我方主体',
              result: 'we_company_id',
              rules: [required()],
              items: weCompanyItems,
              width: 24,
            },
          ],
        },
      ],
      callback: (result) => {
        save_no_business_contract({
          __meta: { edit_mode: mode || result.mode },
          company: { id: company_id, name: result.company_name, short_name: result.company_short_name },
          ...omit(result, ['company_name', 'company_short_name']),
        }).then((res) => {
          appStore.SET_WE_DIALOG(null);
          if (res.error_message) {
            $showAlert('错误', res.error_message);
          } else {
            if (callBack) {
              callBack();
            }
          }
        });
      },
    });
  };
  const batchEditContract = (afterEditContract) => {
    $showDialog({
      title: '批量修改合同信息',
      width: '920px',
      blocks: [
        {
          type: 'input',
          value: [
            {
              type: 'auto-select',
              label: '开票方式',
              result: 'is_manual_invoice',
              width: 12,
              rules: [required()],
              items: [
                { text: '自动开票', value: false },
                { text: '人工开票', value: true },
              ],
            },
            {
              result: 'contract_ids',
              label: '合同ID',
              type: 'note',
              rules: [required()],
            },
          ],
        },
      ],
      callback: (result) => {
        batch_edit_contract({
          contract_ids: result.contract_ids,
          data: { is_manual_invoice: result.is_manual_invoice },
        }).then((res) => {
          appStore.SET_WE_DIALOG(null);
          gotResult(res, afterEditContract);
        });
      },
    });
  };
  return {
    addInternalContract,
    jumpToContract,
    initContract,
    initContractList,
    required_items,
    editContract,
    addNoBusinessContract,
    batchEditContract,
  };
}
