
  import { defineComponent } from 'vue';
  import cloneDeep from 'lodash-es/cloneDeep';
  export default defineComponent({
    name: 'EntityList',
    props: ['config', 'items', 'title', 'hideNoItems', 'headerButtonConfig'],
    computed: {
      getColorFieldItem(): Recordable[] {
        const Dcolumns = cloneDeep(this.config.columns);
        return Dcolumns.filter((item) => {
          return item.colorField;
        });
      },
    },
    methods: {
      clickEntity(entity) {
        this.$emit('jumpTo', entity);
      },
    },
  });
