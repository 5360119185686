
  import { useTable } from '@/components/WeTable';
  import {
    get_lark_contract_temp_category_page,
    edit_lark_contract_temp_category,
    sync_lark_contract_temps,
  } from '@/api/erp-contracts';
  import { defineComponent, ref, computed } from 'vue';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { appStore } from '@/store/modules/app';
  import { useUser } from '@/hooks/useUser';
  import { required } from '@/utils/validate';
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { useUtils } from '@/hooks/useUtils';
  import { isEmptyData } from '@/utils/common';

  export default defineComponent({
    name: 'ERPContractTemplate',
    setup() {
      const { isGod } = useUser();
      const loading = ref(false);
      const { appInfo, teamMap } = useAppInfo();
      const { downloadUrl } = useUtils();
      const items = ref<Recordable[]>([]);
      const columns = computed(() => {
        return [
          { title: '分类ID', dataIndex: 'category_id' },
          { title: '分类名称', dataIndex: 'name', componentType: 'a-select' },
          { title: '简写名称', dataIndex: 'abbreviation' },
          {
            title: '类别',
            dataIndex: 'depth',
            componentType: 'we-select',
            format: (text) => (text === 1 ? '子类' : '父类'),
            componentProps: {
              options: [
                { id: 0, name: '父类' },
                { id: 1, name: '子类' },
              ],
            },
          },
          { title: '父类ID', dataIndex: 'parent_id' },

          { title: '父类名称', dataIndex: 'parent_name', ignoreField: true, componentType: 'a-select' },
          {
            title: 'Team',
            dataIndex: 'teams',
            ignoreField: true,
            hiddenFilter: true,
            slots: { customRender: 'teams' },
          },
          {
            title: '操作',
            dataIndex: 'action',
            slots: { customRender: 'action' },
            flag: 'ACTION',
          },
        ];
      });
      const [register, { reload }] = useTable({
        title: '飞书合同模版分类',
        columns,
        api: (params) => {
          params.fields.push('id');
          return get_lark_contract_temp_category_page(params);
        },
      });
      const teamList = computed(() => {
        return appInfo.value.team_list;
      });
      return { isGod, appInfo, teamMap, downloadUrl, items, register, reload, teamList, loading };
    },
    methods: {
      sync_lark_contract() {
        this.loading = true;
        this.$showAlert('提示', '确定同步飞书合同模板？', () => {
          sync_lark_contract_temps().then(() => {
            appStore.SET_ALERT_CONFIG(null);
            this.$message.success('同步成功');
            this.loading = false;
          });
        });
      },
      editTemplate(item) {
        item = item || {};
        if (!isEmptyData(item.teams)) {
          item.team_ids = item.teams.map((item) => item.team_id);
        }

        const teamItems = this.teamList.map((x) => ({
          id: x.id,
          name: x.team_group.name + ' - ' + x.name + (x.is_history ? '【History】' : ''),
        }));
        teamItems.sort((a, b) => a.name.localeCompare(b.name));

        const blocks: DialogFormItem[] = [
          {
            type: 'input',
            value: [
              {
                width: 24,
                type: 'select',
                label: 'Team',
                result: 'team_ids',
                items: teamItems,
                mode: 'multiple',
                rules: [required()],
              },
            ],
          },
        ];

        const config: DialogConfig = {
          title: 'Edit Template',
          init: item,
          width: '600px',
          labelCol: '60px',
          blocks: blocks,
        };
        config.callback = (result) => {
          edit_lark_contract_temp_category({ id: item.id, ...result })
            .then(() => {
              this.$message.success('操作成功');
              this.reload();
            })
            .catch(() => {
              this.$message.error('操作失败');
            })
            .finally(() => {
              appStore.SET_WE_DIALOG(null);
            });
        };

        this.$showDialog(config);
      },
    },
  });
