
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { formatTime } from '@/utils/time';
  import { defineComponent, ref } from 'vue';
  import uniq from 'lodash-es/uniq';
  import { isEmptyData } from '@/utils/common';
  import groupBy from 'lodash-es/groupBy';
  import { BasicColumn, BasicTableProps } from '@/components/WeTable/types/table';
  import {
    delete_gcp_billing_config,
    edit_gcp_billing_config,
    set_ms_config_platform,
    edit_media_account_config,
    get_gcp_config_options,
    upload_gcp_billing_config,
    get_gcp_billing_config_page,
  } from '@/api/erp-contracts';
  import { DialogFormItem } from '@/components/WeDialog/types';
  import { ActionItem } from '@/components/WeTable';
  import MediaRuleConfig from '../components/MediaRuleConfig.vue';
  import HarborBilling from '../components/HarborBilling.vue';
  import MetaProductList from '@/views/meta/customers/ProductList.vue';
  import GsuiteBill from '@/views/cloud/gsuite/GsuiteBill.vue';
  import AwsBill from '@/views/cloud/aws/AwsBill.vue';
  import { useTable } from '@/components/WeTable';
  import { useUser } from '@/hooks/useUser';
  import { useApp } from '@/hooks/useApp';
  import { appStore } from '@/store/modules/app';
  export default defineComponent({
    name: 'ContractConfig',
    props: ['contract', 'canEdit'],
    components: {
      MediaRuleConfig,
      MetaProductList,
      GsuiteBill,
      AwsBill,
      HarborBilling,
    },
    data: () => ({
      timeSpanId: '',
      edit_type: '',
      title: '',
    }),
    setup(props) {
      const editingPricingRule = ref<Recordable>({});
      const month = ref<String>(formatTime(new Date(), 'YYYY-MM'));
      const editingPricingItem = ref<Recordable>({});
      const data_item = ref<Recordable>({});
      const { model, appInfo, getModelItems } = useAppInfo();
      const { isAdmin, isGod } = useUser();
      const { $showAlert } = useApp();
      const clearCloudConfig = () => {
        $showAlert('Delete', `Are you sure to clear config of month ${month.value}?`, () => {
          delete_gcp_billing_config({ month: month.value, contract_id: props.contract.id }).then(() => {
            reload();
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      };
      const columns = [
        { title: 'Billing Account ID', dataIndex: 'billing_account_id', componentType: 'a-input', showTop: true },
        { title: 'Project', dataIndex: 'project', componentType: 'a-input', showTop: true },
        { title: 'Service', dataIndex: 'service', componentType: 'a-input', showTop: true },
        { title: 'SKU', dataIndex: 'sku', componentType: 'a-input', showTop: true },
        { title: 'Mode', dataIndex: 'mode_name', componentType: 'a-input' },
        {
          title: 'Discount',
          dataIndex: 'discount',
          componentType: 'we-number-range',
          componentProps: { placeholder: ['discount', ''] },
          slots: { customRender: 'discount' },
        },
        {
          title: 'Price',
          dataIndex: 'price',
          componentType: 'we-number-range',
          componentProps: { placeholder: ['price', ''] },
          slots: { customRender: 'price' },
        },
        { title: 'Credit Fields', dataIndex: 'credit_fields', componentType: 'a-input' },
      ];
      const [register, { reload }] = useTable({
        title: '对账配置',
        useSearchForm: false,
        immediate: false,
        actionColumn: {
          title: '操作',
          dataIndex: 'action',
          slots: { customRender: 'action' },
        },
        columns: columns,
        moreFunc: [{ title: '清空配置', click: clearCloudConfig }],
        api: (params: any) => {
          params.month = month.value;
          params.contract_id = props.contract.id;
          return get_gcp_billing_config_page(params);
        },
      });
      return {
        editingPricingItem,
        editingPricingRule,
        model,
        appInfo,
        getModelItems,
        data_item,
        isAdmin,
        isGod,
        register,
        month,
        reload,
        columns,
      };
    },
    computed: {
      isSupply(): boolean {
        return this.contract.is_supply!;
      },
      isDemand(): boolean {
        return this.contract.is_demand!;
      },
      isPubLogic(): boolean {
        return this.contract && this.contract.logic_type === this.model.Contract.LOGIC_TYPE.PUB;
      },
      isCloudLogic(): boolean {
        return (
          this.contract &&
          [
            this.model.Contract.LOGIC_TYPE.CLOUD,
            this.model.Contract.LOGIC_TYPE.EE,
            this.model.Contract.LOGIC_TYPE.FEISHU,
          ].includes(this.contract.logic_type)
        );
      },
      isGCPLogic(): boolean {
        return this.contract && this.contract.rd_bulines.includes('GCP');
      },
      isGSuiteLogic(): boolean {
        return this.contract && this.contract.rd_bulines.includes('GSuite');
      },
      isAwsLogic(): boolean {
        return this.contract && this.contract.rd_bulines.includes('AWS');
      },
      isMediaLogic(): boolean {
        return this.contract && this.contract.logic_type === this.model.Contract.LOGIC_TYPE.MEDIA;
      },
      isContent(): boolean {
        return this.contract && this.contract.rd_buline_name === 'Content';
      },
      accountIsChina(): boolean {
        return (
          this.contract.account_list &&
          this.contract.account_list.length &&
          this.contract.account_list[0].region_type === this.model.MlAccount.REGION_TYPE.CHINA
        );
      },
      timeSpanList(): Recordable[] {
        if (this.contract) {
          let list: Recordable[] = [];
          if (this.accountIsChina) {
            list = this.appInfo.time_span_list.filter((x) => x.range_type === this.model.TimeSpan.RANGE_TYPE.BY_MONTH);
          } else {
            list = this.appInfo.time_span_list.filter((x) => x.range_type === this.model.TimeSpan.RANGE_TYPE.BY_Q);
          }
          list.sort((a, b) => b.id - a.id);
          return list.map((x) => ({ ...x, label: x.name, value: x.id, title: x.name }));
        }
        return [];
      },
      mediaConfigTableList(): Recordable {
        const vm = this;
        const configPlatforms = (this.contract.business_config || {}).platforms || [];
        const is_demand = this.contract.is_demand;
        const is_supply = this.contract.is_supply;

        const mediaAccountPlatforms = uniq(this.contract.account_list.map((x) => x.platform).concat(configPlatforms));
        function parseMediabuyConfigStr(way, wayName) {
          const rules: string[] = [];
          way.product_class_game && rules.push(`game=${way.product_class_game}`);
          way.product_class_app && rules.push(`app=${way.product_class_app}`);
          way.default && rules.push(way.default);
          return wayName + ': ' + rules.join(' | ');
        }
        function parseConfigStr(c) {
          let parsedStr = '';
          if (c) {
            if (c.rule_type === 'normal') {
              return c.default;
            } else if (c.rule_type === 'step') {
              parsedStr = '';
              if (c.steps) {
                c.steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value} | `));
              }
              return (parsedStr + c.default).trim();
            } else if (c.rule_type === 'gg2023_fee') {
              parsedStr += `AC:${c.ac}`;
              parsedStr += '\n';
              parsedStr += `NonAC:${c.nonac}`;
              return parsedStr.trim();
            } else if (c.rule_type === 'mediabuy') {
              const parts: string[] = [];
              c.webeye_buy && parts.push(parseMediabuyConfigStr(c.webeye_buy, '自投'));
              c.partner_buy && parts.push(parseMediabuyConfigStr(c.partner_buy, '代投'));
              return parts.join('　　');
            } else if (c.rule_type === 'gg') {
              const ac = c.ac;
              const nonac = c.nonac;
              const new_customer = c.new_customer;
              if (!isEmptyData(ac)) {
                parsedStr += 'AC:';
                if (!isEmptyData(ac.steps)) {
                  ac.steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(ac.default)) {
                    parsedStr += `其它返点比例:${ac.default}`;
                  }
                } else {
                  if (!isEmptyData(ac.default)) {
                    parsedStr += `返点比例:${ac.default}`;
                  }
                }
              }
              if (!isEmptyData(nonac)) {
                parsedStr += '\n';
                parsedStr += 'NonAC:';
                if (!isEmptyData(nonac.steps)) {
                  nonac.steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(nonac.default)) {
                    parsedStr += `其它返点比例:${nonac.default}`;
                  }
                } else {
                  if (!isEmptyData(nonac.default)) {
                    parsedStr += `返点比例:${nonac.default}`;
                  }
                }
              }
              if (!isEmptyData(new_customer)) {
                parsedStr += '\n';
                parsedStr += '新客:';
                if (!isEmptyData(new_customer.steps)) {
                  new_customer.steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(new_customer.default)) {
                    parsedStr += `其它返点比例:${new_customer.default}`;
                  }
                } else {
                  if (!isEmptyData(new_customer.default)) {
                    parsedStr += `返点比例:${new_customer.default}`;
                  }
                }
              }
              return parsedStr.trim();
            } else if (c.rule_type === 'tt') {
              const region_config = c.region_config;
              const default_item = c.default;
              if (!isEmptyData(region_config)) {
                region_config.forEach((item, index) => {
                  if (index === 0) {
                    parsedStr += `${item.region_category}:`;
                  } else {
                    parsedStr += '\n';
                    parsedStr += `${item.region_category}:`;
                  }
                  if (!isEmptyData(item.steps)) {
                    item.steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                    if (!isEmptyData(item.default)) {
                      parsedStr += `其它返点比例:${item.default}`;
                    }
                  } else {
                    if (!isEmptyData(item.default)) {
                      parsedStr += `返点比例:${item.default}`;
                    }
                  }
                });
              }
              if (!isEmptyData(default_item)) {
                parsedStr += '\n';
                parsedStr += '其它区域:';
                if (!isEmptyData(default_item.steps)) {
                  default_item.steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(default_item.default)) {
                    parsedStr += `其它返点比例:${default_item.default}`;
                  }
                } else {
                  if (!isEmptyData(default_item.default)) {
                    parsedStr += `${default_item.default}`;
                  }
                }
              }
              return parsedStr.trim();
            } else if (c.rule_type === 'gg2023' || c.rule_type === 'gg2024') {
              const nonac = c.nonac;
              const new_customer = c.new_customer;
              if (!isEmptyData(nonac)) {
                parsedStr += '\n';
                parsedStr += '老客激励：';
                if (!isEmptyData(nonac.steps)) {
                  nonac.steps.forEach((step) => (parsedStr += `${step.level}-${step.target}=${step.value};`));
                  if (!isEmptyData(nonac.default)) {
                    parsedStr += `其它返点比例:${nonac.default}`;
                  }
                } else {
                  if (!isEmptyData(nonac.default)) {
                    parsedStr += `返点比例:${nonac.default}`;
                  }
                }
              }
              if (!isEmptyData(new_customer)) {
                parsedStr += '\n';
                parsedStr += '新客:';
                if (!isEmptyData(new_customer.steps)) {
                  new_customer.steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(new_customer.default)) {
                    parsedStr += `其它返点比例:${new_customer.default}`;
                  }
                } else {
                  if (!isEmptyData(new_customer.default)) {
                    parsedStr += `返点比例:${new_customer.default}`;
                  }
                }
              }
              return parsedStr.trim();
            } else if (c.rule_type === 'tt2023') {
              const store_config_steps = c.store_config.steps;
              const region_config = c.region_config;
              parsedStr += '\n';
              parsedStr += '基本返点（按地区配置）：';
              if (!isEmptyData(region_config)) {
                region_config.forEach((region) => (parsedStr += `${region.region_category}=${region.default};`));
              }
              if (c.default) {
                parsedStr += `其它区域：${c.default.default}`;
              }
              if (is_supply) {
                const new_config_cost_steps = c.new_config.cost.steps;
                const new_config_cost_ratio_steps = c.new_config.cost_ratio.steps;
                const agency_config_cost_steps = c.agency_config.cost.steps;
                const agency_config_cost_ratio_steps = c.agency_config.cost_ratio.steps;
                parsedStr += '\n';
                parsedStr += '新客（按消耗）';
                if (!isEmptyData(new_config_cost_steps)) {
                  new_config_cost_steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(c.new_config.cost.default)) {
                    parsedStr += `其它返点比例:${c.new_config.cost.default}`;
                  }
                } else {
                  if (!isEmptyData(c.new_config.cost.default)) {
                    parsedStr += `返点比例:${c.new_config.cost.default}`;
                  }
                }
                parsedStr += '\n';
                parsedStr += '新客（按占比）';
                if (!isEmptyData(new_config_cost_ratio_steps)) {
                  new_config_cost_ratio_steps.forEach(
                    (step) => (parsedStr += `${step.from}~${step.to}=${step.value};`)
                  );
                  if (!isEmptyData(c.new_config.cost_ratio.default)) {
                    parsedStr += `其它返点比例:${c.new_config.cost_ratio.default}`;
                  }
                } else {
                  if (!isEmptyData(c.new_config.cost_ratio.default)) {
                    parsedStr += `返点比例:${c.new_config.cost_ratio.default}`;
                  }
                }
                parsedStr += '\n';
                parsedStr += '代运营（按消耗）';
                if (!isEmptyData(agency_config_cost_steps)) {
                  agency_config_cost_steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(c.agency_config.cost.default)) {
                    parsedStr += `其它返点比例:${c.agency_config.cost.default}`;
                  }
                } else {
                  if (!isEmptyData(c.agency_config.cost.default)) {
                    parsedStr += `返点比例:${c.agency_config.cost.default}`;
                  }
                }
                parsedStr += '\n';
                parsedStr += '代运营（按占比）';
                if (!isEmptyData(agency_config_cost_ratio_steps)) {
                  agency_config_cost_ratio_steps.forEach(
                    (step) => (parsedStr += `${step.from}~${step.to}=${step.value};`)
                  );
                  if (!isEmptyData(c.agency_config.cost_ratio.default)) {
                    parsedStr += `其它返点比例:${c.agency_config.cost_ratio.default}`;
                  }
                } else {
                  if (!isEmptyData(c.agency_config.cost_ratio.default)) {
                    parsedStr += `返点比例:${c.agency_config.cost_ratio.default}`;
                  }
                }
                parsedStr += '\n';
                parsedStr += '电商';
                if (!isEmptyData(store_config_steps)) {
                  store_config_steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(c.store_config.default)) {
                    parsedStr += `其它返点比例:${c.store_config.default}`;
                  }
                } else {
                  if (!isEmptyData(c.store_config.default)) {
                    parsedStr += `返点比例:${c.store_config.default}`;
                  }
                }
              }
              if (is_demand) {
                parsedStr += '\n';
                parsedStr += '新客：';
                parsedStr += `返点比例:${c.new_config.default}`;
                parsedStr += '\n';
                parsedStr += '代运营';
                parsedStr += `返点比例:${c.agency_config.default}`;
                parsedStr += '\n';
                parsedStr += '电商';
                if (!isEmptyData(store_config_steps)) {
                  store_config_steps.forEach((step) => (parsedStr += `${step.from}~${step.to}=${step.value};`));
                  if (!isEmptyData(c.store_config.default)) {
                    parsedStr += `其它返点比例:${c.store_config.default}`;
                  }
                } else {
                  if (!isEmptyData(c.store_config.default)) {
                    parsedStr += `返点比例:${c.store_config.default}`;
                  }
                }
              }
              return parsedStr.trim();
            } else if (c.rule_type === 'industry_mediabuy') {
              parsedStr += '\n';
              parsedStr += '自投（由Webeye投放或Webeye寻找第三方投放）：';
              Object.entries(c.webeye_buy)
                .filter((f) => f[0] !== 'default')
                .forEach((item) => {
                  parsedStr += `${item[0]}:${item[1]};`;
                });
              parsedStr += `其它行业:${c.webeye_buy.default}`;
              parsedStr += '\n';
              parsedStr += '代投（由Partner投放）：';
              parsedStr += `返点比例:${c.partner_buy.default}`;
              return parsedStr.trim();
            } else {
              return JSON.stringify(c);
            }
          }
        }

        function getTableConfig(configType): BasicTableProps {
          const dataItems: Recordable[] = [];
          mediaAccountPlatforms.forEach((platform) => {
            const item = {
              config_type: configType,
              platform: platform,
              rebate: null,
              rebate_str: '',
              service_fee: null,
              service_fee_str: '',
            };
            const found = vm.contract.account_config_list.find(
              (x) => x.time_span_id === vm.timeSpanId && x.platform === platform && x.config_type === configType
            );
            if (found) {
              const rebateJsonStr = vm.contract.is_demand ? found.demand_rebate_rate : found.supply_rebate_rate;
              item.rebate = rebateJsonStr ? JSON.parse(rebateJsonStr) : null;
              item.rebate_str = parseConfigStr(item.rebate);
              const serviceJsonStr = vm.contract.is_demand ? found.demand_service_fee : found.supply_service_fee;
              item.service_fee = serviceJsonStr ? JSON.parse(serviceJsonStr) : null;
              item.service_fee_str = parseConfigStr(item.service_fee);
            }
            dataItems.push(item);
          });

          let tableName = '返点/服务费配置';
          if (configType.includes('Demand')) {
            tableName = configType.slice(0, 2) + tableName;
          }
          return {
            title: tableName,
            useSearchForm: false,
            immediate: false,
            columns: [
              { title: '平台', dataIndex: 'platform' },
              {
                title: '返点比例',
                dataIndex: 'rebate_str',
                customCell: () => {
                  return {
                    style: { 'white-space': 'pre' },
                  };
                },
              },
              {
                title: '服务费比例',
                dataIndex: 'service_fee_str',
                customCell: () => {
                  return {
                    style: { 'white-space': 'pre' },
                  };
                },
              },
              {
                title: '编辑',
                dataIndex: 'action',
                slots: {
                  customRender: 'action',
                },
                flag: 'ACTION',
              },
            ],
            dataSource: dataItems,
          };
        }

        return (this.contract.logic_detail || [])
          .filter((ct) =>
            ['ML_Supply_China', 'ML_Supply_Global', 'RS_Demand', 'MB_Demand', 'PK_Demand', 'Internal_Demand'].includes(
              ct
            )
          )
          .map((ct) => getTableConfig(ct));
      },
      accountListTable(): BasicTableProps {
        const config: BasicTableProps = {
          useSearchForm: false,
          immediate: false,
          columns: [
            { title: '类型', dataIndex: 'rd_account_logic_name', componentType: 'a-select' },
            { title: '平台', dataIndex: 'platform', componentType: 'a-select' },
            { title: 'Account ID', dataIndex: 'account_id', componentType: 'a-input' },
            { title: 'Account Agency', dataIndex: 'rd_account_agency_name', componentType: 'a-select' },
            { title: 'Service Agency', dataIndex: 'rd_service_agency_name', componentType: 'a-select' },
            { title: 'Region', dataIndex: 'rd_region_type', componentType: 'a-select' },
            { title: '状态', dataIndex: 'status', componentType: 'a-select' },
            { title: 'Code', dataIndex: 'rd_product_code', componentType: 'a-input' },
            { title: 'UA', dataIndex: 'rd_ua_name', componentType: 'a-input' },
            { title: 'POD2', dataIndex: 'pod2', componentType: 'a-input' },
          ],
          title: 'Accounts',
        };
        return config;
      },
      showGCPBilling() {
        return this.isGCPLogic && (![3816, 4034].includes(this.contract.id) || this.isAdmin || this.isGod);
      },
    },
    methods: {
      isEmptyData(obj) {
        return isEmptyData(obj);
      },
      initTimeSpan() {
        const now = formatTime(new Date(), 'YYYY-MM-DD HH:mm:ss');
        this.timeSpanList.forEach((x) => {
          if (x.from_time <= now && now <= x.to_time) {
            this.timeSpanId = x.id;
          }
        });
      },
      onUpdateConfig(res) {
        this.$emit('onUpdateConfig', res);
        this.reload();
      },
      async getGcpConfigOptions(item) {
        return new Promise<Recordable>((resolve) => {
          get_gcp_config_options({}).then((res) => {
            const accountMap = groupBy(res.project_list, 'billing_account_id');
            const accountItems = Object.keys(accountMap).map((x) => ({ text: x, value: x }));
            const projectItems = (accountMap[item.billing_account_id] || [])
              .filter((x) => x.project)
              .map((x) => ({ text: x.project, value: x.project }));

            const serviceMap = groupBy(res.service_list, 'service');
            const serviceItems = Object.keys(serviceMap).map((x) => ({ text: x, value: x }));
            const skuItems = (serviceMap[item.service] || [])
              .filter((x) => x.sku)
              .map((x) => ({ text: x.sku, value: x.sku }));
            resolve({ accountMap, accountItems, projectItems, serviceMap, serviceItems, skuItems });
          });
        });
      },
      async editCloudBillingConfig(item) {
        const vm = this;
        const isAdd = !item;
        item = item || {};

        const MODE = vm.model.GcpBillingConfig.MODE;
        let modeItems = vm.getModelItems(MODE);
        modeItems = modeItems.map((m) => {
          if (m.name === 'By Discount') {
            m.name = 'By Discount(Subtotal)';
          }
          if (m.name === 'By Discount List Cost') {
            m.name = 'By Discount(Cost List)';
          }
          return { id: m.id, name: m.name };
        });

        const inputs: DialogFormItem[] = [
          {
            width: 24,
            type: 'auto-select',
            result: 'mode',
            label: 'Mode',
            items: modeItems,
            rules: [{ required: true, trigger: 'change', message: '请选择', type: 'any' }],
          },
          {
            width: 24,
            type: 'text',
            result: 'price',
            label: 'Price',
            placeholder: '注意，如果单价是1刀每百万(例如翻译)，请填写0.000001',
            rules: [{ required: true, trigger: 'change', message: '请选择', type: 'any' }],
            show: (result) => result.mode === MODE.BY_USAGE,
          },
          {
            width: 24,
            type: 'text',
            result: 'discount',
            label: 'Discount',
            rules: [{ required: true, trigger: 'change', message: '请选择', type: 'any' }],
            show: (result) => [MODE.BY_DISCOUNT_LIST_COST, MODE.BY_DISCOUNT].includes(result.mode),
          },
          {
            width: 12,
            type: 'text',
            result: 'price',
            label: 'Price',
            placeholder: '注意，如果单价是1刀每百万(例如翻译)，请填写0.000001',
            rules: [{ required: true, trigger: 'change', message: '请选择', type: 'any' }],
            show: (result) => result.mode === MODE.USAGE_THEN_DISCOUNT,
          },
          {
            width: 12,
            type: 'text',
            result: 'discount',
            label: 'Discount',
            rules: [{ required: true, trigger: 'change', message: '请选择', type: 'any' }],
            show: (result) => result.mode === MODE.USAGE_THEN_DISCOUNT,
          },
          {
            width: 24,
            type: 'text',
            result: 'billing_account_id',
            label: 'Billing Account ID',
            rules: [{ required: true, trigger: 'change', message: '请选择', type: 'any' }],
          },
          {
            width: 24,
            type: 'text',
            result: 'project',
            label: 'Project',
            placeholder: '请填写Project ID！',
          },
          { width: 24, type: 'text', result: 'service', label: 'Service' },
          { width: 24, type: 'text', result: 'sku', label: 'SKU' },
        ];

        this.$showDialog({
          title: (isAdd ? 'Add' : 'Edit') + ' Cloud Billing Config',
          init: item,
          width: '600px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            edit_gcp_billing_config({
              id: item.id,
              month: this.month,
              contract_id: this.contract.id,
              data: result,
            }).then((res) => {
              if (res.error_code === 500) {
                this.$message.error(res.error_message);
              } else {
                this.onUpdateConfig(res);
              }
            });
          },
        });
      },
      deleteCloudBillingConfig(item) {
        this.$showAlert('Delete', 'Are you sure to delete this config?', () => {
          delete_gcp_billing_config({ ids: [item.id] }).then((res) => {
            this.onUpdateConfig(res);
          });
        });
      },
      uploadCloudConfig() {
        const vm = this;
        const configHeaders: any[] = vm.columns.filter((x) => x.title !== '操作').map((x) => x.title);
        this.$showDialog({
          title: '导入对账配置',
          width: '600px',
          labelCol: '100px',
          layout: 'vertical',
          blocks: [{ type: 'excel', result: 'excel_data', config: { headers: configHeaders } }],
          callback: (result) => {
            const configs = result.excel_data.map((x) => {
              const res: any = {};
              Object.keys(x).forEach((k) => {
                res[vm.columns.find((c) => c.title === k)!.dataIndex!] = x[k];
              });
              if (res.mode_name === 'By Discount(Subtotal)') {
                res.mode = this.model.GcpBillingConfig.MODE['By Discount'];
              } else if (res.mode_name === 'By Discount(Cost List)') {
                res.mode = this.model.GcpBillingConfig.MODE['By Discount List Cost'];
              } else {
                res.mode = this.model.GcpBillingConfig.MODE[res.mode_name];
              }
              return res;
            });
            upload_gcp_billing_config({ configs: configs, contract_id: vm.contract.id, month: vm.month }).then(
              (res) => {
                if (res.error_message) {
                  vm.$showAlert('错误', res.error);
                }
                this.onUpdateConfig(res);
              }
            );
          },
        });
      },
      editRebateSeviceRule(item, ruleField, title) {
        this.edit_type = ruleField;
        this.title = title;
        item.policy_name = title;
        this.data_item = item;
      },
      saveRule(result) {
        const config = {
          contract_id: this.contract.id,
          time_span_id: this.timeSpanId,
          platform: this.data_item.platform,
          config_type: this.data_item.config_type,
        };
        let resultField = '';
        if (this.edit_type === 'rebate') {
          resultField = this.contract.is_demand ? 'demand_rebate_rate' : 'supply_rebate_rate';
        } else if (this.edit_type === 'service_fee') {
          resultField = this.contract.is_demand ? 'demand_service_fee' : 'supply_service_fee';
        }
        config[resultField] = result;
        edit_media_account_config(config).then((res) => {
          this.onUpdateConfig(res);
        });
      },
      handleActions(record: any, _column: BasicColumn): ActionItem[] {
        return [
          { label: '编辑', onClick: () => this.editCloudBillingConfig(record) },
          { label: '删除', color: 'error', onClick: () => this.deleteCloudBillingConfig(record) },
        ];
      },
      handleMediaActions(record: any, _column: BasicColumn): ActionItem[] {
        const actionItem: ActionItem[] = [];
        const timeSpanTitle = this.timeSpanList.find((item) => item.value === this.timeSpanId)!.title;
        const rebate_title = `编辑${timeSpanTitle}返点比例(e.g. 0.01)`;
        const service_title = `编辑${timeSpanTitle}服务费比例(e.g. 0.01)`;

        actionItem.push({
          label: '编辑返点比例',
          onClick: () => this.editRebateSeviceRule(record, 'rebate', rebate_title),
        });
        actionItem.push({
          label: '编辑服务费比例',
          onClick: () => this.editRebateSeviceRule(record, 'service_fee', service_title),
        });
        return actionItem;
      },
      handleDropClick({ key }: { key: string }) {
        switch (key) {
          case 'ByPlainText':
            this.editCloudBillingConfig(null);
            break;
          case 'BatchImportConfig':
            this.uploadCloudConfig();
            break;

          default:
            break;
        }
      },
      addConfigPlatform() {
        const platforms = ['Adwords', 'FB', 'TikTok', 'Kwai'].map((x) => ({ text: x, value: x }));
        const inputs = { type: 'select', label: '平台', result: 'platform', items: platforms, width: 24 };

        this.$showDialog({
          title: '添加平台',
          width: '600px',
          labelCol: '60px',
          blocks: [{ type: 'input', value: [inputs] }],
          callback: (result) => {
            set_ms_config_platform({ contract_id: this.contract.id, ...result }).then((res) => {
              this.onUpdateConfig(res);
            });
          },
        });
      },
    },
    watch: {
      timeSpanList: {
        handler() {
          this.initTimeSpan();
        },
        deep: true,
        immediate: true,
      },
      contract: {
        handler() {
          this.reload();
        },
        deep: true,
        immediate: true,
      },
      month: {
        handler() {
          this.reload();
        },
      },
    },
  });
