
  import { get_invoice_page, get_invoice_item, upload_invoices, download_invoice_file } from '@/api/erp-invoice';
  import { BasicColumn, useTable } from '@/components/WeTable';
  import { useApp } from '@/hooks/useApp';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { computed, defineComponent, unref, ref } from 'vue';
  import { useInvoice } from './hooks/useInvoice';
  import { switchNumber } from '@/utils/number';
  import { useJump } from '@/hooks/useJump';
  import { formatTime } from '@/utils/time';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';

  export default defineComponent({
    name: 'erpInvoicesList',
    setup() {
      const { isAdmin, isFinance, hasPermission, isGod } = useUser();
      const { model } = useAppInfo();
      const { isDemandPage, isSupplyPage } = useApp();
      const { jumpToBill, jumpToCompany, jumpToInvoice } = useJump();
      const { editInvoice } = useInvoice();
      let pageItems = ref<Recordable>({});
      const dateRangeOptions = [
        { label: '最近一年', value: 'recent_one_year' },
        { label: '全部', value: 'all' },
      ];
      const filterType = ref('recent_one_year');
      const columns = computed<BasicColumn[]>(() => {
        const demandHeaders = [
          { title: '客户税号', dataIndex: 'cs_tax_number' },
          { title: '客户地址', dataIndex: 'cs_address' },
          { title: '客户号码', dataIndex: 'cs_phone' },
          { title: '客户银行', dataIndex: 'cs_bank__bf_bank' },
          {
            title: '客户账户',
            dataIndex: 'cs_bank__bf_account_number',
          },
        ];
        return [
          {
            title: '发票名称',
            dataIndex: 'name',
            componentType: 'a-select',
            showTop: true,
            slots: {
              customRender: 'name',
            },
          },
          {
            title: unref(pageConfig).customer,
            dataIndex: 'company__name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
            showTop: true,
            width: 260,
            slots: {
              customRender: 'company__name',
            },
          },
          {
            title: 'Type',
            dataIndex: 'rd_invoice_type',
            componentType: 'a-select',
            showTop: true,
          },
          {
            title: 'Property',
            dataIndex: 'rd_property',
            componentType: 'a-select',
            showTop: true,
          },
          {
            title: '发票种类',
            dataIndex: 'rd_category',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          {
            title: '发票状态',
            dataIndex: 'rd_state_name',
            colorField: 'rd_state_color',
            componentType: 'a-select',
            slots: { customRender: 'rd_state_name' },
            componentProps: {
              mode: 'multiple',
            },
          },
          ...(unref(pageConfig).type === unref(model).Invoice.TYPE.DEMAND
            ? [
                {
                  title: '状态更新时间',
                  dataIndex: 'newest_state_time',
                  componentType: 'a-range-picker',
                },
                {
                  title: '状态更新人',
                  dataIndex: 'newest_state_owner',
                },
              ]
            : []),
          {
            title: '发票日期',
            dataIndex: 'date',
            componentType: 'a-range-picker',
            slots: {
              customRender: 'date',
            },
          },
          {
            title: '币种',
            dataIndex: 'currency__name',
            componentType: 'a-select',
          },
          {
            title: '税率',
            dataIndex: 'tax_rate',
            componentType: 'a-select',
            slots: {
              customRender: 'tax_rate',
            },
          },
          {
            title: '含税金额',
            dataIndex: 'it_amount',
            componentType: 'we-number-range',
            hiddenFilter: true,
            round: 2,
          },
          {
            title: '不含税金额',
            dataIndex: 'et_amount',
            componentType: 'we-number-range',
            hiddenFilter: true,
            round: 2,
          },
          {
            title: '税额',
            dataIndex: 'tax_amount',
            componentType: 'we-number-range',
            hiddenFilter: true,
            round: 2,
          },
          {
            title: '关联金额',
            dataIndex: 'linked_amount',
            componentType: 'we-number-range',
            hiddenFilter: true,
            slots: {
              customRender: 'linked_amount',
            },
          },
          {
            title: '未关联金额',
            dataIndex: 'unlinked_amount',
            componentType: 'we-number-range',
            hiddenFilter: true,
            slots: {
              customRender: 'unlinked_amount',
            },
          },
          {
            title: '发票号',
            dataIndex: 'invoice_number',
            componentType: 'a-input',
          },
          ...(unref(pageConfig).customerType === 'Purchase'
            ? []
            : [
                {
                  title: '发票分类',
                  dataIndex: 'service_name',
                  componentType: 'a-select',
                },
              ]),
          ...(unref(pageConfig).type === unref(model).Invoice.TYPE.DEMAND ? demandHeaders : []),
          {
            title: '我方主体',
            dataIndex: 'we_company__name',
            componentType: 'a-select',
            componentProps: {
              mode: 'multiple',
            },
          },
          ...(unref(pageConfig).type === unref(model).Invoice.TYPE.DEMAND
            ? [
                {
                  title: '我方银行',
                  dataIndex: 'we_bank__bf_bank',
                  componentType: 'a-select',
                },
                {
                  title: '我方银行账户',
                  dataIndex: 'we_bank__bf_account_number',
                  componentType: 'a-select',
                },
              ]
            : []),

          { title: '创建时间', dataIndex: 'create_time', componentType: 'a-range-picker' },
        ];
      });
      const pageConfig = computed<Recordable>(() => {
        const temp = isSupplyPage.value
          ? {
              pathSuffix: 'supply',
              title: '业务成本发票',
              customer: '销售方',
              customerType: 'Supply',
              type: unref(model).Invoice.TYPE.SUPPLY,
            }
          : isDemandPage.value
          ? {
              pathSuffix: 'demand',
              title: '销售发票',
              customer: '客户',
              customerType: 'Demand',
              type: unref(model).Invoice.TYPE.DEMAND,
            }
          : {
              pathSuffix: 'purchase',
              title: '非业务支出发票',
              customer: '销售方',
              customerType: 'Purchase',
              type: unref(model).Invoice.TYPE.SUPPLY,
            };
        return temp;
      });
      const [register, { reload }] = useTable({
        title: unref(pageConfig).title,
        columns,
        initItem: (item) => {
          const state = unref(model).InvoiceState.STATE;
          item.color =
            item.rd_state_value === state.DONE ? 'green' : item.rd_state_value === state.CLOSED ? 'grey' : 'orange';
        },
        api: (params) => {
          params.invoice_type = unref(pageConfig).type;
          params.is_purchase = unref(pageConfig).pathSuffix === 'purchase';
          params.fields = params.fields.concat(['id', 'company_id', 'is_purchase', 'rd_state_value']);
          pageItems.value = params;
          params.filter_type = filterType.value;
          return get_invoice_page(params);
        },
      });
      return {
        isAdmin,
        isGod,
        isFinance,
        jumpToBill,
        jumpToCompany,
        register,
        reload,
        pageConfig,
        editInvoice,
        model,
        jumpToInvoice,
        hasPermission,
        isDemandPage,
        pageItems,
        dateRangeOptions,
        filterType,
      };
    },
    computed: {
      canAddInvoice(): boolean {
        // return this.isAdmin || this.isFinance;
        return this.isGod;
      },
      screenOut(): boolean {
        return (
          !isEmptyData(this.pageItems) &&
          !isEmptyData(this.pageItems.page_params.filters) &&
          (this.isGod || this.hasPermission('CanSeeBatchDownloadInvoice'))
        );
      },
      isPurchase(): boolean {
        return this.pageConfig.customerType === 'Purchase';
      },
    },
    methods: {
      switchNumber,
      formatTime,
      addInvoice() {
        this.editInvoice(this, 'add', this.pageConfig, {});
      },
      showBills(id) {
        const vm = this;
        get_invoice_item({ invoice_id: id }).then((res) => {
          const billItems = res.linked_bill_list;
          if (billItems.length && billItems.length !== 0) {
            vm.$showAlert('Bills', {
              config: {
                title: 'Bills',
                immediate: false,
                tableLayout: 'auto',
                columns: [
                  { title: 'Group', dataIndex: 'rd_team_group_name', componentType: 'a-input' },
                  {
                    title: 'Team',
                    dataIndex: 'rd_team_name',
                    componentType: 'a-select',
                    showTop: true,
                  },
                  {
                    title: 'Owner',
                    dataIndex: 'rd_owner_name',
                    componentType: 'a-select',
                    showTop: true,
                  },
                  { title: 'Name', dataIndex: 'name', showTop: true, componentType: 'a-input' },
                  {
                    title: 'Status',
                    dataIndex: 'rd_state_name',
                    colorField: 'rd_state_color',
                    componentType: 'a-select',
                    showTop: true,
                  },
                  {
                    title: 'Invoice Amount',
                    dataIndex: 'finance_invoice_money',
                    componentType: 'we-number-range',
                    hiddenFilter: true,
                  },
                  { title: 'All Linked', dataIndex: 'linked_amount', componentType: 'a-input' },
                  {
                    title: 'Unlinked',
                    dataIndex: 'unlinked_amount',
                    componentType: 'a-input',
                    hiddenFilter: true,
                    round: 2,
                  },
                  {
                    title: 'Linked in this Pay',
                    dataIndex: 'this_linked_amount',
                    componentType: 'a-input',
                  },
                ],
              },
              items: billItems,
              clickItems: [
                {
                  key: 'name',
                  onClick: (item) => {
                    vm.jumpToBill(item);
                  },
                },
              ],
            });
          }
        });
      },
      importExcel() {
        let headers: { label: string; key: string }[] = [];
        if (this.isDemandPage) {
          headers = [
            { label: '编号', key: 'Num' },
            { label: '发票号码', key: 'Invoice Number' },
            { label: '是否预付', key: 'Is Prepay' },
            { label: '发票种类', key: 'Invoice Category' },
            { label: '币种', key: 'Currency' },
            { label: '发票日期', key: 'Date' },
            { label: '客户', key: 'Company' },
            { label: '客户纳税人识别号', key: 'Tax Number' },
            { label: '地址电话', key: 'Address Phone' },
            { label: '我方主体', key: 'WeCompany' },
            { label: '含税金额', key: 'IT Amount' },
            { label: '税率', key: 'Tax Rate' },
            { label: '不含税金额', key: 'ET Amount' },
            { label: '开票品目', key: 'Service Name' },
            { label: '描述', key: 'Description' },
          ];
        } else {
          headers = [
            { label: '编号', key: 'Num' },
            { label: '发票号码', key: 'Invoice Number' },
            { label: '是否预付', key: 'Is Prepay' },
            { label: '发票种类', key: 'Invoice Category' },
            { label: '币种', key: 'Currency' },
            { label: '发票日期', key: 'Date' },
            { label: '客户', key: 'Company' },
            { label: '我方主体', key: 'WeCompany' },
            { label: '含税金额', key: 'IT Amount' },
            { label: '税率', key: 'Tax Rate' },
            { label: '不含税金额', key: 'ET Amount' },
            { label: '描述', key: 'Description' },
          ];
        }

        this.$showDialog({
          title: '批量导入',
          width: '800px',
          blocks: [{ type: 'excel', result: 'excel_data', config: { headers } }],
          callback: (result) => {
            const params = {
              invoice_type: this.pageConfig.type,
              is_purchase: this.pageConfig.pathSuffix === 'purchase',
              ...result,
            };
            upload_invoices(params)
              .then((res) => {
                if (!isEmptyData(res)) {
                  this.$message.error('导入失败');
                  const tableHeaders = [
                    { title: '客户纳税人识别号', dataIndex: 'cs_tax_number' },
                    { title: '币种', dataIndex: 'currency_id' },
                    { title: '发票日期', dataIndex: 'date', format: (text) => formatTime(text, 'YYYY-MM-DD') },
                    { title: '描述', dataIndex: 'description' },
                    { title: '发票号码', dataIndex: 'invoice_number' },
                    { title: '是否预付', dataIndex: 'is_prepay' },
                    { title: '税率', dataIndex: 'tax_rate' },
                  ];
                  tableHeaders.push({ title: '原因', dataIndex: 'Reason' });
                  this.$showAlert('Failed Items', {
                    config: {
                      title: 'Failed Items',
                      columns: tableHeaders,
                    },
                    items: res,
                  });
                } else {
                  this.$message.success('导入成功');
                  this.reload();
                }
              })
              .catch(() => {
                this.$message.error('导入失败');
              })
              .finally(() => {
                appStore.SET_WE_DIALOG(null);
              });
          },
        });
      },
      downloadInvoiceFile() {
        download_invoice_file(this.pageItems).then((res) => {
          this.$showOperLog({
            operation_group: res.operation_group,
            start_content: '开始处理',
            end_content: '处理结束',
            afterClose: () => {
              this.reload();
            },
          });
        });
      },
    },
  });
