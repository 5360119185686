import { get_company_list } from '@/api/crm-customer';
import {
  add_prepay,
  batch_add_pay,
  edit_demand_pay,
  edit_purchase_pay,
  edit_supply_pay,
  god_edit_pay,
  get_pay_cat,
  edit_direct_pay,
  get_company_with_bank,
} from '@/api/erp-pay';
import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
import { useAppInfo } from '@/hooks/useAppInfo';
import { appStore } from '@/store/modules/app';
import { formatTime } from '@/utils/time';
import { isNumber, required } from '@/utils/validate';
import pad from 'lodash-es/pad';
import { unref } from 'vue';
import { useJump } from '@/hooks/useJump';
import { useOptionList } from '@/hooks/useOptionList';
import { isEmptyData, uniqueItems } from '@/utils/common';
function initPayInfo(pay, vue) {
  const model = vue.model;

  pay.is_demand = pay.pay_type === model.Pay.TYPE.DEMAND;
  pay.is_supply = pay.pay_type === model.Pay.TYPE.SUPPLY;
  pay.is_virtual = pay.category === model.Pay.CATEGORY.VIRTUAL;
  pay.is_prepay = pay.category === model.Pay.CATEGORY.PREPAY;
  pay.is_purchase = pay.category === model.Pay.CATEGORY.PURCHASE;
  pay.type_name = pay.pay_type === 0 ? '' : model.Pay.TYPE[pay.pay_type];
  pay.pay_method_name = model.Pay.METHOD[pay.pay_method];

  pay.company_name = pay.company.name;
  pay.company_short_name = pay.company.short_name;

  pay.we_company = vue.weCompanyMap[pay.we_company_id];
  pay.we_company_name = pay.we_company.name;

  pay.currency = vue.currencyMap[pay.currency_id];
  pay.currency_name = pay.currency.name;

  pay.category_name = model.Pay.CATEGORY[pay.category];

  pay.pay_time = pay.pay_time ? pay.pay_time.split(' ')[0] : '';
  pay.plan_pay_time = pay.plan_pay_time ? pay.plan_pay_time.split(' ')[0] : '';

  const companyShort = pay.company.short_name ? pay.company.short_name.replace(/ /g, '') : '';
  const weCompanyShort = pay.we_company.short_name.replace(/ /g, '');
  const payTime = pay.is_supply ? formatTime(pay.plan_pay_time, 'YYYYMMDD') : formatTime(pay.pay_time, 'YYYYMMDD');
  pay.name = `${pad(pay.id, 5)}-${companyShort}-${weCompanyShort}-${payTime}`;
}
export function usePay() {
  const { weCompanyList, getModelItems, teamList } = useAppInfo();
  const { pay_method_items } = useOptionList();
  const { jumpToPay } = useJump();
  const afterEdit = (res: any, vue: any) => {
    appStore.SET_WE_DIALOG(null);
    if (vue.afterEditPay) {
      vue.afterEditPay(res);
    } else if (vue.afterEditBill) {
      vue.afterEditBill(res);
    }
    // this.traceAction('Edit Pay');
  };
  const godEditPay = async (pay, vue: any) => {
    const companyList = (await get_company_list({ pick_fields: ['id', 'name', 'short_name'] })).company_list
      .filter((x) => x.short_name)
      .map((c) => ({ text: c.name, value: c.id }));
    const weCompanyItems = unref(weCompanyList)
      .filter((x) => !x.is_history)
      .map((c) => ({ text: c.name, value: c.id }));
    const payCategoryItems = getModelItems(vue.model.Pay.PAY_CATEGORY);
    const payFeeTypeItems = getModelItems(vue.model.Pay.PAY_FEE_TYPE);
    const teamItems = unref(teamList).map((t) => ({ text: t.name, value: t.id }));
    const companyBankList = (await get_company_with_bank()).map((c) => ({
      text: c.name,
      value: c.id,
      bank_num: c.bank_num,
      banks: c.bank_list,
    }));
    let bankItems = [];
    if (!isEmptyData(pay) && pay.company_id) {
      bankItems = companyBankList
        .find((item) => item.value === pay.company_id)
        .banks.map((x) => ({ text: x.bf_bank + x.bf_account_number, value: x.id }));
    }
    const inputs: DialogFormItem[] = [];
    const dg: DialogConfig = {
      title: 'God Edit Transaction',
      width: '800px',
      init: pay,
      labelCol: '120px',
      blocks: [{ type: 'input', value: inputs }],
    };
    inputs.push({
      width: 12,
      result: 'team_id',
      type: 'auto-select',
      label: '部门',
      items: teamItems,
    });
    inputs.push({
      width: 12,
      result: 'company_id',
      type: 'auto-select',
      label: '客户',
      items: companyList,
    });
    inputs.push({
      width: 12,
      result: 'we_company_id',
      type: 'auto-select',
      label: '我方主体',
      items: weCompanyItems,
    });
    if (pay.rd_type_name === 'Demand') {
      inputs.push({
        width: 12,
        result: 'category',
        type: 'select',
        label: '属性',
        items: getModelItems(vue.model.Pay.CATEGORY),
      });
    }
    inputs.push({ width: 12, result: 'pay_money', type: 'text', label: '金额' });
    inputs.push({
      width: 12,
      result: 'currency_id',
      type: 'select',
      label: '币种',
      items: 'currency',
    });
    inputs.push({ width: 12, result: 'pay_time', type: 'date', label: '付款/收款日期' });
    inputs.push({ width: 12, result: 'fee_money', type: 'text', label: '银行手续费' });
    inputs.push({
      width: 12,
      result: 'pay_method',
      type: 'select',
      label: '付款方式',
      items: pay_method_items,
    });
    inputs.push({
      width: 12,
      result: 'pay_category',
      type: 'select',
      label: '付款类型',
      items: payCategoryItems,
    });
    inputs.push({
      width: 12,
      result: 'pay_fee_type',
      type: 'select',
      label: '手续费承担类型',
      items: payFeeTypeItems,
    });
    inputs.push({ width: 12, result: 'plan_pay_time', type: 'date', label: '计划付款日期' });
    inputs.push({ width: 12, result: 'invoice_number', type: 'text', label: '发票号' });
    inputs.push({
      type: 'auto-select',
      label: '银行信息',
      result: 'bank_id',
      width: 24,
      items: bankItems,
    });
    dg.callback = (result) => {
      god_edit_pay({ meta: { edit_mode: 'edit', pay_id: pay.id }, data: result }).then(() => {
        afterEdit(null, vue);
      });
    };

    vue.$showDialog(dg);
  };
  const editDemandPay = async (mode, pay, vue: any, bill_id?: any) => {
    const vm = vue;
    const current_state = isEmptyData(pay.id) ? {} : pay.flow.current_state;
    const isAdd = mode === 'add';
    const payId = isAdd ? 0 : pay.id;
    const companyList = (await get_company_list({ pick_fields: ['id', 'name', 'short_name'] })).company_list
      .filter((x) => x.short_name)
      .map((c) => ({ text: c.name, value: c.id }));
    const weCompanyItems = unref(weCompanyList)
      .filter((x) => !x.is_history)
      .map((c) => ({ text: c.name, value: c.id }));
    const categoryItems = getModelItems(vm.model.Pay.CATEGORY).filter((x) =>
      [vm.model.Pay.CATEGORY.NORMAL, vm.model.Pay.CATEGORY.PREPAY].includes(x.value)
    );

    const inputs: DialogFormItem[] = [];
    const dg: DialogConfig = {
      title: isAdd ? 'Add Receipt' : 'Edit Receipt',
      width: '800px',
      labelCol: '120px',
      init: pay,
      blocks: [{ type: 'input', value: inputs }],
    };
    if (isAdd || current_state.key === 'Capital Checking Recv') {
      inputs.push({
        result: 'company_id',
        type: 'auto-select',
        label: '客户主体',
        items: companyList,
        rules: [required()],
        disabled: () => !isEmptyData(pay.linked_money),
        width: 12,
      });
      inputs.push({
        result: 'we_company_id',
        type: 'auto-select',
        label: '我方主体',
        items: weCompanyItems,
        rules: [required()],
        disabled: () => !isEmptyData(pay.linked_money),
        width: 12,
      });
      inputs.push({
        result: 'pay_money',
        type: 'text',
        label: '金额',
        rules: [required('string', '请输入金额'), isNumber],
        width: 12,
      });
      inputs.push({
        result: 'currency_id',
        type: 'select',
        label: '币种',
        rules: [required('string', '请选择币种')],
        items: 'currency',
        width: 12,
      });
      inputs.push({
        result: 'pay_time',
        type: 'date',
        label: '付款日期',
        rules: [required('string', '请选择时间')],
        width: 12,
      });
      inputs.push({
        result: 'category',
        type: 'select',
        label: '种类',
        items: categoryItems,
        rules: [required('string', '请选择种类')],
        width: 12,
      });
    } else if (current_state.key === 'Accountant Linking Recv') {
      inputs.push({
        result: 'fee_money',
        type: 'text',
        label: '银行手续费',
        rules: [required('string', '请选择'), isNumber],
        width: 12,
      });
    }
    dg.callback = (result) =>
      edit_demand_pay({ meta: { edit_mode: mode, pay_id: payId }, data: result, bill_id: bill_id }).then((res) => {
        afterEdit(res, vue);
      });
    vm.$showDialog(dg);
  };
  const editSupplyPay = async (pay, vue: any) => {
    const isPrepay = pay.category === vue.model.Pay.CATEGORY.PREPAY;
    const STATE = vue.model.PayState.STATE;
    const payCategoryItems = getModelItems(vue.model.Pay.PAY_CATEGORY);
    const payFeeTypeItems = getModelItems(vue.model.Pay.PAY_FEE_TYPE);
    const companyBankList = (await get_company_with_bank()).map((c) => ({
      text: c.name,
      value: c.id,
      bank_num: c.bank_num,
      banks: c.bank_list,
    }));
    let bankItems = [];
    if (!isEmptyData(pay) && pay.company_id) {
      bankItems = companyBankList
        .find((item) => item.value === pay.company_id)
        .banks.map((x) => ({ text: x.bf_bank + x.bf_account_number, value: x.id }));
    }
    const inputs: DialogFormItem[] = [];
    const dg: DialogConfig = {
      title: `Edit ${isPrepay ? 'Pre-Pay' : 'Payment'}`,
      width: '500px',
      init: pay,
      labelCol: '120px',
      blocks: [{ type: 'input', value: inputs }],
    };

    if (pay.state_value === STATE.ANALYSIS_CHECKING_PREPAY || pay.state_value === STATE.ANALYSIS_CHECKING_PAY) {
      inputs.push({
        result: 'plan_pay_time',
        type: 'date',
        label: '计划付款日期',
        rules: [required()],
      });
      inputs.push({
        result: 'pay_method',
        type: 'select',
        label: '付款方式',
        items: pay_method_items,
      });
      inputs.push({
        result: 'pay_category',
        type: 'select',
        label: '付款类型',
        items: payCategoryItems,
        rules: [required()],
      });
      inputs.push({
        result: 'pay_fee_type',
        type: 'select',
        label: '手续费承担类型',
        items: payFeeTypeItems,
        rules: [required()],
      });
      inputs.push({
        type: 'auto-select',
        label: '银行信息',
        result: 'bank_id',
        width: 24,
        items: bankItems,
      });
    } else if (pay.state_value === STATE.AM_CHECKING_PREPAY || pay.state_value === STATE.AM_CHECKING_PREPAY_INFO) {
      inputs.push({
        result: 'pay_money',
        type: 'text',
        label: '金额',
        rules: [required(), isNumber],
      });
      inputs.push({
        result: 'plan_pay_time',
        type: 'date',
        label: '计划付款日期',
        rules: [required()],
      });
      inputs.push({ result: 'invoice_number', type: 'text', label: '发票号' });
    } else if (
      pay.state_value === STATE.ACCOUNTANT_CHECKING_PREPAY ||
      pay.state_value === STATE.ACCOUNTANT_CHECKING_PURCHASE_PAY
    ) {
      inputs.push({ result: 'invoice_number', type: 'text', label: '发票号' });
    }
    dg.callback = (result) =>
      edit_supply_pay({ meta: { pay_id: pay.id }, data: result }).then(() => {
        afterEdit(null, vue);
      });
    vue.$showDialog(dg);
  };
  const addPrepay = (contract, vue) => {
    const { getMyTeamList } = useAppInfo();
    const inputs: DialogFormItem[] = [
      { result: 'pay_money', type: 'text', label: '金额', rules: [required(), isNumber], width: 24 },
      { result: 'plan_pay_time', type: 'date', label: '计划付款日期', rules: [required()], width: 24 },
      { result: 'invoice_number', type: 'text', label: '发票号', width: 24 },
    ];
    if (vue.isAdmin || vue.isFinance) {
      const teamItems = getMyTeamList().map((x) => ({ text: x.team_group.name + '-' + x.name, value: x.id }));
      inputs.unshift({
        result: 'team_id',
        type: 'select',
        label: 'Team',
        rules: [required()],
        items: teamItems,
        init: contract.team_id,
        width: 24,
      });
    }
    const dg: DialogConfig = {
      title: 'Add Prepay',
      width: '500px',
      blocks: [{ type: 'input', value: inputs }],
      labelCol: '120px',
      callback: (result) =>
        add_prepay({ contract_id: contract.id, ...result }).then((res) => {
          jumpToPay(res.pay);
          afterEdit(null, vue);
        }),
    };
    const payCategoryItems = getModelItems(vue.model.Pay.PAY_CATEGORY);
    const payFeeTypeItems = getModelItems(vue.model.Pay.PAY_FEE_TYPE);
    inputs.push({ result: 'pay_method', type: 'select', label: '付款方式', width: 24, items: pay_method_items });
    inputs.push({
      result: 'pay_category',
      type: 'select',
      label: '付款类型',
      items: payCategoryItems,
      rules: [required()],
      width: 24,
    });
    inputs.push({
      result: 'pay_fee_type',
      type: 'select',
      label: '手续费承担类型',
      items: payFeeTypeItems,
      rules: [required()],
      width: 24,
    });
    vue.$showDialog(dg);
  };
  const editPurchasePay = async (mode, entity, vue) => {
    let pay: Recordable = {};
    let contract: Recordable = {};
    if (mode === 'add') {
      contract = entity;
      if (!contract.bank_id) {
        vue.$showAlert('Warning', '缺少银行信息，请先申请客商信息');
        return;
      }
    } else {
      pay = entity;
    }

    const payFeeTypeItems = getModelItems(vue.model.Pay.PAY_FEE_TYPE);
    const pay_cat_list = await get_pay_cat();
    const pay_cat1Items = uniqueItems(pay_cat_list.map((x) => ({ text: x.pay_cat_1__name, value: x.pay_cat_1__name })));

    payFeeTypeItems.forEach((x) => {
      if (x.value === vue.model.Pay.PAY_FEE_TYPE.SHA) {
        x.text += ' - 双方共同负担全部手续费';
      } else if (x.value === vue.model.Pay.PAY_FEE_TYPE.OUR) {
        x.text += ' - 我方负担全部手续费';
      } else if (x.value === vue.model.Pay.PAY_FEE_TYPE.BEN) {
        x.text += ' - 对方负担全部手续费';
      } else if (x.value === vue.model.Pay.PAY_FEE_TYPE.NO_CHARGE) {
        x.text += ' - 境内付款无手续费';
      }
    });

    let initCat2Items = [];
    let initCat3Items = [];

    if (pay && pay.cat1_name) {
      initCat2Items = uniqueItems(
        pay_cat_list.filter((x) => x.pay_cat_1__name === pay.cat1_name).map((x) => ({ text: x.name, value: x.name }))
      );
      if (pay.cat2_name) {
        initCat3Items = uniqueItems(
          pay_cat_list
            .filter((x) => x.pay_cat_1__name === pay.cat1_name && x.name === pay.cat2_name)
            .reduce((pre, cur) => {
              pre.push(...cur.pay_cat_3_list.map((m) => ({ text: m.name, value: m.name })));
              return pre;
            }, [])
        );
      }
    }

    const inputs: DialogFormItem[] = [
      {
        result: 'pay_money',
        type: 'text',
        label: '金额',
        width: 12,
        rules: [required('string', '请输入金额'), isNumber],
      },
      {
        result: 'plan_pay_time',
        type: 'date',
        label: '计划付款日期',
        width: 12,
        rules: [required('string', '请选择日期')],
      },
      {
        result: 'pay_method',
        type: 'select',
        label: '付款方式',
        width: 12,
        items: pay_method_items,
        rules: [required('number', '请选择付款方式')],
      },
      {
        result: 'pay_fee_type',
        type: 'select',
        label: '手续费承担类型',
        width: 12,
        items: payFeeTypeItems,
        rules: [required('number', '请选择收费类型')],
      },
      {
        type: 'select',
        label: '一类付款类型',
        result: 'cat1_name',
        width: 12,
        items: pay_cat1Items,
        rules: [required('string', '请选择种类')],
        change: (result, _input, config) => {
          let entityInput2: DialogFormItem = {};

          config.forEach((b) => {
            if (b.type === 'input') {
              b.value.forEach((i) => {
                if (i.result === 'cat2_name') {
                  entityInput2 = i;
                }
              });
            }
          });
          const i2Items = uniqueItems(
            pay_cat_list
              .filter((x) => x.pay_cat_1__name === result.cat1_name)
              .map((x) => ({ text: x.name, value: x.name }))
          );
          result.cat2_name = '';
          entityInput2.items = i2Items;
        },
      },
      {
        type: 'auto-select',
        label: '二类付款类型',
        result: 'cat2_name',
        width: 12,
        items: initCat2Items,
        rules: [required()],
        change: (result, _input, config) => {
          let entityInput: DialogFormItem = {};
          config.forEach((b) => {
            if (b.type === 'input') {
              b.value.forEach((i) => {
                if (i.result === 'cat3_name') {
                  entityInput = i;
                }
              });
            }
          });

          initCat3Items = uniqueItems(
            pay_cat_list
              .filter((x) => x.pay_cat_1__name === result.cat1_name && x.name === result.cat2_name)
              .reduce((pre, cur) => {
                pre.push(...cur.pay_cat_3_list.map((m) => ({ text: m.name, value: m.name })));
                return pre;
              }, [])
          );
          result.cat3_name = '';
          entityInput.items = initCat3Items;
        },
      },
      {
        type: 'auto-select',
        label: '三类付款类型',
        result: 'cat3_name',
        width: 12,
        items: initCat3Items,
        show: (result) => result.cat1_name === '市场费用',
        // show: () => initCat3Items.length > 0,
        rules: [required()],
      },
    ];

    vue.$showDialog({
      title: mode === 'add' ? 'Add Purchase Pay' : 'Edit Purchase Pay',
      width: '700px',
      init: pay,
      labelCol: '120px',
      blocks: [{ type: 'input', value: inputs }],
      callback: (result) => {
        const params = { contract_id: contract ? contract.id : 0, pay_id: pay ? pay.id : 0 };
        edit_purchase_pay({ mode: mode, ...params, ...result }).then((res) => {
          appStore.SET_WE_DIALOG(null);
          if (mode === 'add') {
            jumpToPay(res.pay);
          }
        });
      },
    });
  };
  const addBillPayment = (bill, vue) => {
    const vm = vue;
    const inputs = [
      {
        type: 'date',
        label: 'Plan Pay Time',
        result: 'plan_pay_time',
        init: bill.rd_pay_money_plan_time,
      },
      {
        type: 'radio',
        label: 'Is Virtual',
        result: 'is_virtual',
        init: false,
        items: vm.getTfItems,
      },
    ];

    vue.$showDialog({
      title: 'Add Payment',
      width: '400px',
      blocks: [{ type: 'input', value: inputs }],
      callback: (result) => {
        batch_add_pay({ ...result, bill_id: bill.id }).then((res) => {
          vue.initData();
          //   this.traceAction('Add Pay');
          vue.addPaymentDialog = false;
          appStore.SET_WE_DIALOG(null);
          jumpToPay(res);
        });
      },
    });
  };
  async function addDirectPayment(mode, pay, vue) {
    const isAdd = mode === 'add';
    const weCompanyItems = unref(weCompanyList)
      .filter((x) => !x.is_history)
      .map((c) => ({ text: c.name, value: c.id }));
    const { pay_method_items } = useOptionList();
    const payFeeTypeItems = getModelItems(vue.model.Pay.PAY_FEE_TYPE);
    const companyList = (await get_company_with_bank()).map((c) => ({
      text: c.name,
      value: c.id,
      bank_num: c.bank_num,
      banks: c.bank_list,
    }));
    const pay_cat_list = await get_pay_cat();
    const pay_cat1Items = uniqueItems(pay_cat_list.map((x) => ({ text: x.pay_cat_1__name, value: x.pay_cat_1__name })));
    const pay_cat2Items = uniqueItems(pay_cat_list.map((x) => ({ text: x.name, value: x.name })));
    const pay_cat3Items = uniqueItems(
      pay_cat_list.reduce((pre, cur) => {
        pre.push(...cur.pay_cat_3_list.map((m) => ({ text: m.name, value: m.name })));
        return pre;
      }, [])
    );
    const teamItems = uniqueItems(
      pay_cat_list.filter((x) => x.team_id).map((x) => ({ text: x.team_name, value: x.team_id }))
    );
    let bankItems = [];
    if (!isEmptyData(pay) && pay.company_id) {
      bankItems = companyList
        .find((item) => item.value === pay.company_id)
        .banks.map((x) => ({ text: `${x.bf_bank}${x.bf_account_number}(${x.id})`, value: x.id }));
    }
    const inputs: DialogFormItem[] = [
      {
        width: 12,
        result: 'we_company_id',
        type: 'select',
        label: '我方主体',
        rules: [required()],
        items: weCompanyItems,
      },
      {
        width: 12,
        result: 'is_china',
        type: 'select',
        label: '国内/国外',
        rules: [required()],
        items: [
          { text: '国内', value: true },
          { text: '国外', value: false },
        ],
      },
      {
        width: 12,
        result: 'team_id',
        type: 'select',
        label: '付款部门',
        rules: [required()],
        items: teamItems,
        change: (result, _input, config) => {
          const entityInput3: DialogFormItem = {};
          let entityInput2: DialogFormItem = {};
          let entityInput1: DialogFormItem = {};
          config.forEach((b) => {
            if (b.type === 'input') {
              b.value.forEach((i) => {
                if (i.result === 'cat3_name') {
                  entityInput2 = i;
                }
                if (i.result === 'cat2_name') {
                  entityInput2 = i;
                }
                if (i.result === 'cat1_name') {
                  entityInput1 = i;
                }
              });
            }
          });
          const i1Items = uniqueItems(
            pay_cat_list
              .filter((x) => x.team_id === result.team_id)
              .map((x) => ({ text: x.pay_cat_1__name, value: x.pay_cat_1__name }))
          );
          const i2Items = uniqueItems(
            pay_cat_list.filter((x) => x.team_id === result.team_id).map((x) => ({ text: x.name, value: x.name }))
          );
          const i3Items = uniqueItems(
            pay_cat_list.reduce((pre, cur) => {
              pre.push(
                ...cur.pay_cat_3_list
                  .filter((f) => f.teams.includes(result.team_id))
                  .map((m) => ({ text: m.name, value: m.name }))
              );
              return pre;
            }, [])
          );
          result.cat1_name = '';
          entityInput1.items = i1Items;
          result.cat2_name = '';
          entityInput2.items = i2Items;
          result.cat3_name = '';
          entityInput3.items = i3Items;
        },
      },
      {
        width: 12,
        result: 'pay_method',
        type: 'select',
        label: '付款方式',
        items: pay_method_items,
        rules: [required()],
      },
      {
        width: 12,
        result: 'pay_fee_type',
        type: 'select',
        label: '手续费承担类型',
        items: payFeeTypeItems,
        rules: [required()],
      },
      {
        result: 'currency_id',
        type: 'select',
        label: '币种',
        rules: [required('string', '请选择币种')],
        items: 'currency',
        width: 12,
      },
      {
        result: 'pay_money',
        type: 'text',
        label: '金额',
        rules: [required('string', '请输入金额'), isNumber],
        width: 12,
      },
      {
        width: 12,
        result: 'plan_pay_time',
        type: 'date',
        label: '预计付款日期',
        rules: [required()],
      },
      {
        width: 24,
        result: 'company_id',
        type: 'auto-select',
        label: '对方主体',
        items: companyList,
        rules: [
          required(),
          {
            validator: async (_rule, val: any) => {
              const find_item = companyList.find((item) => item.value === val);
              if (isEmptyData(val) || find_item!.bank_num > 0) {
                return Promise.resolve();
              } else {
                return Promise.reject('此客户无银行信息！');
              }
            },
            trigger: 'change',
          },
        ],
        change: (result, _input, config) => {
          let entityInput: DialogFormItem = {};
          config.forEach((b) => {
            if (b.type === 'input') {
              b.value.forEach((i) => {
                if (i.result === 'bank_id') {
                  entityInput = i;
                }
              });
            }
          });
          entityInput.items = companyList
            .find((item) => item.value === result.company_id)
            .banks.filter((x) => !isEmptyData(x.bf_account_number))
            .map((x) => ({ text: `${x.bf_bank} - ${x.bf_account_number}`, value: x.id }));
        },
      },
      {
        type: 'auto-select',
        label: '银行信息',
        result: 'bank_id',
        width: 24,
        items: bankItems,
        rules: [required()],
      },
      {
        type: 'select',
        label: '一类付款类型',
        result: 'cat1_name',
        width: 12,
        items: pay_cat1Items,
        rules: [required('string', '请选择付款类型')],
        change: (result, _input, config) => {
          let entityInput2: DialogFormItem = {};
          let entityInput3: DialogFormItem = {};

          config.forEach((b) => {
            if (b.type === 'input') {
              b.value.forEach((i) => {
                if (i.result === 'cat2_name') {
                  entityInput2 = i;
                }
                if (i.result === 'cat3_name') {
                  entityInput3 = i;
                }
              });
            }
          });
          const i2Items = uniqueItems(
            pay_cat_list
              .filter((x) => x.team_id === result.team_id && x.pay_cat_1__name === result.cat1_name)
              .map((x) => ({ text: x.name, value: x.name }))
          );
          const i3Items = uniqueItems(
            pay_cat_list
              .filter((x) => x.team_id === result.team_id && x.pay_cat_1__name === result.cat1_name)
              .reduce((pre, cur) => {
                pre.push(
                  ...cur.pay_cat_3_list
                    .filter((f) => f.teams.includes(result.team_id))
                    .map((m) => ({ text: m.name, value: m.name }))
                );
                return pre;
              }, [])
          );
          result.cat2_name = '';
          entityInput2.items = i2Items;
          result.cat3_name = '';
          entityInput3.items = i3Items;
        },
        disabled: !isEmptyData(pay),
      },
      {
        type: 'auto-select',
        label: '二类付款类型',
        result: 'cat2_name',
        width: 12,
        items: pay_cat2Items,
        rules: [
          required(),
          {
            validator: async (_rule, val: any) => {
              const find_item = pay_cat_list.find((item) => item.name === val);
              if (isEmptyData(val) || find_item!.flow_roles.length > 0 || find_item!.has_supply_flow) {
                return Promise.resolve();
              } else {
                return Promise.reject('此类型未配置审批流程！');
              }
            },
            trigger: 'change',
          },
        ],
        change: (result, _input, config) => {
          let entityInput: DialogFormItem = {};
          config.forEach((b) => {
            if (b.type === 'input') {
              b.value.forEach((i) => {
                if (i.result === 'cat3_name') {
                  entityInput = i;
                }
              });
            }
          });
          const i3Items = uniqueItems(
            pay_cat_list
              .filter(
                (x) =>
                  x.team_id === result.team_id && x.pay_cat_1__name === result.cat1_name && x.name === result.cat2_name
              )
              .reduce((pre, cur) => {
                pre.push(
                  ...cur.pay_cat_3_list
                    .filter((f) => f.teams.includes(result.team_id))
                    .map((m) => ({ text: m.name, value: m.name }))
                );
                return pre;
              }, [])
          );
          result.cat3_name = '';
          entityInput.items = i3Items;
        },
        disabled: !isEmptyData(pay),
      },
      {
        type: 'auto-select',
        label: '三类付款类型',
        result: 'cat3_name',
        width: 12,
        items: pay_cat3Items,
        placeholder: '若无合适选项，请联系对应FBP',
        show: (result) => result.cat1_name === '市场费用',
        rules: [required()],
        disabled: !isEmptyData(pay),
      },
      {
        type: 'text',
        label: '发票号',
        result: 'invoice_number',
        width: 12,
      },
    ];
    const dg: DialogConfig = {
      title: isAdd ? '新增无合同付款' : '编辑无合同付款',
      width: '800px',
      labelCol: '120px',
      blocks: [
        { type: 'input', value: inputs },
        { type: 'note', result: 'note', label: '付款事由', width: 24 },
      ],
      init: pay,
      callback: (result) => {
        edit_direct_pay({ pay_id: pay.id, data: result }).then((res) => {
          appStore.SET_WE_DIALOG(null);
          if (pay.id) {
            vue.afterEditPay(res);
          } else {
            jumpToPay(res.pay);
          }
        });
      },
    };
    vue.$showDialog(dg);
  }
  return {
    afterEdit,
    godEditPay,
    addBillPayment,
    editDemandPay,
    editSupplyPay,
    addPrepay,
    editPurchasePay,
    initPayInfo,
    addDirectPayment,
  };
}
