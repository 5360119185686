
  import { get_paying_money_bill_list, get_pay_page, upload_demand_pay_list, upload_direct_pays } from '@/api/erp-pay';
  import { BasicTableProps, useTable } from '@/components/WeTable';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { appStore } from '@/store/modules/app';
  import { computed, defineComponent, ref, unref } from 'vue';
  import { usePay } from './hooks/usePay';
  import { useApp } from '@/hooks/useApp';
  import { useJump } from '@/hooks/useJump';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'erpPaysList',
    setup() {
      const { isFinance, hasPermission, isAccountant, isGod, isAnalysis } = useUser();
      const { isPurchasePage, isDemandPage, isSupplyPage, isDirectPage, $message, $showAlert } = useApp();
      const { jumpToBill, jumpToPay } = useJump();
      const dateRangeOptions = [
        { label: '最近一年', value: 'recent_one_year' },
        { label: '全部', value: 'all' },
      ];
      const filterType = ref('recent_one_year');
      let config: BasicTableProps = {
        columns: [],
      };
      if (isPurchasePage.value) {
        config = {
          ...config,
          title: '非业务付款-有合同',
          columns: [
            { title: '部门', dataIndex: 'rd_team_name', componentType: 'a-input' },
            {
              title: '名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
              componentType: 'a-input',
              showTop: true,
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              componentType: 'a-select',
              slots: {
                customRender: 'rd_state_name',
              },
              showTop: true,
            },
            {
              title: '计划付款日期',
              dataIndex: 'rd_plan_pay_time',
              componentType: 'a-range-picker',
            },
            {
              title: '付款日期',
              dataIndex: 'rd_pay_time',
              componentType: 'a-range-picker',
            },
            {
              title: '付款金额',
              dataIndex: 'pay_money',
              round: 2,
              componentType: 'we-number-range',
              hiddenFilter: true,
            },
            {
              title: '币种',
              dataIndex: 'rd_currency_name',
              componentType: 'a-select',
            },
            {
              title: '种类',
              dataIndex: 'cat1_name',
              componentType: 'a-select',
            },
            {
              title: '种类详情',
              dataIndex: 'cat2_name',
              componentType: 'a-select',
            },
            {
              title: '三类付款类型',
              dataIndex: 'cat3_name',
              componentType: 'a-select',
            },
            {
              title: 'Invoice Link 状态',
              dataIndex: 'rd_invoice_link_status',
              componentType: 'a-input',
              slots: { customRender: 'rd_invoice_link_status' },
            },
            {
              title: '客户主体',
              dataIndex: 'rd_company_name',
              componentType: 'a-select',
              componentProps: {
                mode: 'multiple',
              },
              showTop: true,
            },
            {
              title: '我方主体',
              dataIndex: 'rd_we_company_name',
              componentType: 'a-select',
              componentProps: {
                mode: 'multiple',
              },
            },
            {
              title: '创建者',
              dataIndex: 'rd_owner_name',
              componentType: 'a-select',
              showTop: true,
            },
            {
              title: '创建时间',
              dataIndex: 'rd_create_time',
              componentType: 'a-range-picker',
            },
            { title: '银行账户', dataIndex: 'contract__bank__bf_account_number', defaultHidden: true },
            { title: '银行名称', dataIndex: 'contract__bank__bf_bank', defaultHidden: true },
            { title: 'Swift Code', dataIndex: 'contract__bank__bf_swift_code', defaultHidden: true },
            { title: '手续费承担方', dataIndex: 'rd_pay_fee_type_name', defaultHidden: true },
            { title: '发票号', dataIndex: 'rd_linked_invoice_info', defaultHidden: true },
          ],
        };
      } else if (isDirectPage.value) {
        config = {
          ...config,
          title: '非业务付款-无合同',
          columns: [
            { title: '部门', dataIndex: 'rd_team_name', componentType: 'a-input' },
            {
              title: '名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
              componentType: 'a-input',
              showTop: true,
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              componentType: 'a-select',
              slots: {
                customRender: 'rd_state_name',
              },
              showTop: true,
            },
            {
              title: '计划付款日期',
              dataIndex: 'rd_plan_pay_time',
              componentType: 'a-range-picker',
            },
            {
              title: '付款日期',
              dataIndex: 'rd_pay_time',
              componentType: 'a-range-picker',
            },
            {
              title: '付款金额',
              dataIndex: 'pay_money',
              round: 2,
              componentType: 'we-number-range',
              hiddenFilter: true,
            },
            {
              title: '币种',
              dataIndex: 'rd_currency_name',
              componentType: 'a-select',
            },
            {
              title: '种类',
              dataIndex: 'cat1_name',
              componentType: 'a-select',
            },
            {
              title: '种类详情',
              dataIndex: 'cat2_name',
              componentType: 'a-select',
            },
            {
              title: '三类付款类型',
              dataIndex: 'cat3_name',
              componentType: 'a-select',
            },
            {
              title: '客户主体',
              dataIndex: 'rd_company_name',
              componentType: 'a-select',
              componentProps: {
                mode: 'multiple',
              },
              showTop: true,
            },
            { title: '银行账户', dataIndex: 'bank__bf_account_number', defaultHidden: true },
            { title: '银行名称', dataIndex: 'bank__bf_bank', defaultHidden: true },
            { title: 'Swift Code', dataIndex: 'bank__bf_swift_code', defaultHidden: true },
            { title: '手续费承担方', dataIndex: 'rd_pay_fee_type_name', defaultHidden: true },
            {
              title: '我方主体',
              dataIndex: 'rd_we_company_name',
              componentType: 'a-select',
              componentProps: {
                mode: 'multiple',
              },
            },
            {
              title: '创建者',
              dataIndex: 'rd_owner_name',
              componentType: 'a-select',
              showTop: true,
            },
            {
              title: '创建时间',
              dataIndex: 'rd_create_time',
              componentType: 'a-range-picker',
            },
          ],
        };
      } else if (isSupplyPage.value) {
        config = {
          ...config,
          title: '业务付款',
          columns: [
            {
              title: '名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
              componentType: 'a-input',
              showTop: true,
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              componentType: 'a-select',
              slots: {
                customRender: 'rd_state_name',
              },
              showTop: true,
            },
            {
              title: '交易类型',
              dataIndex: 'rd_category',
              componentType: 'a-select',
              showTop: true,
            },
            {
              title: '计划付款日期',
              dataIndex: 'rd_plan_pay_time',
              componentType: 'a-range-picker',
            },
            {
              title: '付款日期',
              dataIndex: 'rd_pay_time',
              componentType: 'a-range-picker',
            },
            {
              title: '付款金额',
              dataIndex: 'pay_money',
              round: 2,
              componentType: 'we-number-range',
              hiddenFilter: true,
            },
            {
              title: '未核销金额',
              dataIndex: 'unlinked_money',
              componentType: 'we-number-range',
              hiddenFilter: true,
              round: 2,
            },
            {
              title: '币种',
              dataIndex: 'rd_currency_name',
              componentType: 'a-select',
            },
            {
              title: '客户主体',
              dataIndex: 'rd_company_name',
              componentType: 'a-select',
              showTop: true,
              componentProps: {
                mode: 'multiple',
              },
            },
            {
              title: '我方主体',
              dataIndex: 'rd_we_company_name',
              componentType: 'a-select',
              componentProps: {
                mode: 'multiple',
              },
            },
            {
              title: '创建者',
              dataIndex: 'rd_owner_name',
              componentType: 'a-select',
            },
            {
              title: '创建时间',
              dataIndex: 'rd_create_time',
              componentType: 'a-range-picker',
            },
            { title: '发票号', dataIndex: 'rd_linked_invoice_info' },
            { title: '团队', dataIndex: 'link_bill_team' },
            { title: '部门', dataIndex: 'link_bill_group' },
            { title: '银行账户', dataIndex: 'contract__bank__bf_account_number', defaultHidden: true },
            { title: '银行名称', dataIndex: 'contract__bank__bf_bank', defaultHidden: true },
            { title: 'Swift Code', dataIndex: 'contract__bank__bf_swift_code', defaultHidden: true },
            { title: '手续费承担方', dataIndex: 'rd_pay_fee_type_name', defaultHidden: true },
            { title: '付款类型', dataIndex: 'rd_pay_category_name', defaultHidden: true },
          ],
        };
      } else {
        config = {
          ...config,
          title: '收款',
          columns: [
            {
              title: '名称',
              showTop: true,
              dataIndex: 'name',
              componentType: 'a-input',
              slots: {
                customRender: 'name',
              },
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              slots: {
                customRender: 'rd_state_name',
              },
              componentType: 'a-select',
              showTop: true,
            },
            {
              title: '交易类型',
              dataIndex: 'rd_category',
              componentType: 'a-select',
              showTop: true,
            },
            {
              title: '到款日期',
              dataIndex: 'rd_pay_time',
              componentType: 'a-range-picker',
            },
            {
              title: '到款金额',
              dataIndex: 'pay_money',
              componentType: 'we-number-range',
              hiddenFilter: true,
              round: 2,
            },
            {
              title: '银行手续费',
              dataIndex: 'fee_money',
              componentType: 'we-number-range',
              hiddenFilter: true,
            },
            {
              title: '未核销金额',
              dataIndex: 'unlinked_money',
              round: 2,
              componentType: 'we-number-range',
              hiddenFilter: true,
            },
            {
              title: '币种',
              dataIndex: 'rd_currency_name',
              componentType: 'a-select',
            },
            {
              title: '客户主体',
              dataIndex: 'rd_company_name',
              componentType: 'a-select',
              showTop: true,
              componentProps: {
                mode: 'multiple',
              },
            },
            {
              title: '我方主体',
              dataIndex: 'rd_we_company_name',
              componentType: 'a-select',
              componentProps: {
                mode: 'multiple',
              },
            },
            {
              title: '创建者',
              dataIndex: 'rd_owner_name',
              componentType: 'a-select',
            },
            { title: '创建时间', dataIndex: 'rd_create_time', componentType: 'a-range-picker' },
            { title: '团队', dataIndex: 'link_bill_team' },
            { title: '部门', dataIndex: 'link_bill_group' },
            {
              title: '关联发票信息',
              dataIndex: 'rd_linked_invoice_info',
              componentType: 'a-input',
            },
            {
              title: '关联账单月份',
              dataIndex: 'link_bill_month',
              componentType: 'a-input',
            },
          ],
        };
      }
      config.api = (params) => {
        params.is_purchase = isPurchasePage.value;
        params.is_direct = isDirectPage.value;
        params.pay_type =
          isSupplyPage.value || isPurchasePage.value || isDirectPage.value
            ? unref(model).Pay.TYPE.SUPPLY
            : unref(model).Pay.TYPE.DEMAND;
        params.fields.push('id');
        params.fields.push('pay_fee_type');
        params.fields.push('pay_category');
        config.columns.filter((h) => h.colorField).forEach((h) => params.fields.push(h.colorField));
        params.filter_type = filterType.value;
        return get_pay_page(params);
      };
      const [register, { reload }] = useTable(config);
      const title = computed(() => {
        if (isPurchasePage.value) {
          return '非业务付款';
        } else if (isDemandPage.value) {
          return '收款';
        } else if (isDirectPage.value) {
          return '无合同事项付款';
        } else {
          return '业务付款';
        }
      });
      const { model, getTfItems, weCompanyList, currencyList, getModelItems, appInfo } = useAppInfo();
      const { editDemandPay, addDirectPayment, addBillPayment } = usePay();
      const addPaymentBillList = ref<Recordable[]>([]);
      return {
        hasPermission,
        isFinance,
        jumpToBill,
        isPurchasePage,
        isSupplyPage,
        isDemandPage,
        isDirectPage,
        model,
        isGod,
        isAnalysis,
        isAccountant,
        getTfItems,
        addPaymentBillList,
        title,
        register,
        reload,
        editDemandPay,
        jumpToPay,
        weCompanyList,
        currencyList,
        getModelItems,
        appInfo,
        addDirectPayment,
        addBillPayment,
        $message,
        $showAlert,
        dateRangeOptions,
        filterType,
      };
    },
    data: () => ({
      addPaymentDialog: false,
    }),
    computed: {
      params() {
        const params: Recordable = { is_purchase: this.isPurchasePage, pay_type: '' };
        params.pay_type =
          this.isSupplyPage || this.isPurchasePage ? this.model.Pay.TYPE.SUPPLY : this.model.Pay.TYPE.DEMAND;
        return params;
      },
      paymentBillListConfig(): BasicTableProps {
        return {
          immediate: false,
          title: 'Add Payment',
          tableLayout: 'auto',
          columns: [
            {
              title: 'Bill 名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
              componentType: 'a-input',
              showTop: true,
            },
            { title: 'Owner', dataIndex: 'rd_owner_name', componentType: 'a-input', showTop: true },
            { title: '币种', dataIndex: 'rd_currency_name', componentType: 'a-input' },
            { title: 'To Pay Money', dataIndex: 'to_pay_money', align: 'right' },
            {
              title: 'Plan Pay Time',
              dataIndex: 'rd_pay_money_plan_time',
              componentType: 'a-range-picker',
            },
            {
              title: 'Action',
              flag: 'ACTION',
              dataIndex: 'add_payment_action',
              slots: {
                customRender: 'add_payment_action',
              },
            },
          ],
        };
      },
      canAddReceipt(): boolean {
        return this.isDemandPage && (this.isFinance || this.isGod);
      },
      canAddPayment(): boolean {
        return this.isSupplyPage && (this.isFinance || this.isGod);
      },
      canAddDirectPayment(): boolean {
        return this.isDirectPage && (!this.hasPermission('RoleFinanceDataViewer') || this.isGod);
      },
    },
    created() {
      //   this.traceActionPage(this.tableConfig.title);
    },
    watch: {
      $route() {
        this.$nextTick(this.initData);
      },
    },
    methods: {
      toAddBillPayment(record) {
        this.addBillPayment(record, this);
      },
      addDemandPay() {
        this.editDemandPay('add', {}, this);
      },
      batchImportPay() {
        const headers = ['WebEye', 'Customer', 'Currency', 'Amount', 'Bank Charges', 'Received Date', 'Category'];
        const exampleData = [
          {
            WebEye: '我方主体全称',
            Customer: '对方主体全称',
            Currency: 'USD/CNY',
            Amount: '123.45',
            'Bank Charges': '可不填',
            'Received Date': '2020-01-01',
            Category: 'Normal/Prepay',
          },
        ];
        this.$showDialog({
          title: '批量导入收款',
          width: '600px',
          blocks: [
            {
              type: 'excel',
              result: 'excel_data',
              config: { headers: headers, example: exampleData },
            },
          ],
          callback: (result) => {
            upload_demand_pay_list({ ...result }).then((res) => {
              appStore.SET_WE_DIALOG(null);
              this.reload();
              headers.push('Reason');
              const alertHeaders = headers.map((x) => ({ title: x, dataIndex: x }));
              this.$showAlert('Failed Items', {
                config: {
                  title: 'Failed Items',
                  immediate: false,
                  useSearchForm: false,
                  useFilterFromColumns: false,
                  showHeader: false,
                  columns: alertHeaders,
                },
                items: res,
              });
            });
          },
        });
      },
      addPayment() {
        this.addPaymentBillList = [];
        this.addPaymentDialog = true;
        get_paying_money_bill_list().then((res) => {
          this.addPaymentBillList = res.bill_list.filter((x) => x.to_pay_money > 0);
        });
      },
      addDirect() {
        this.addDirectPayment('add', {}, this);
      },
      initData() {
        this.reload();
      },
      afterEditPay() {
        this.initData();
      },

      importNoContract() {
        const headers: { label: string; key: string }[] = [
          { label: '我方主体', key: 'WeCompany' },
          { label: '国内/国外', key: 'Is China' },
          { label: '付款部门', key: 'Team' },
          { label: '付款方式', key: 'Method' },
          { label: '手续费承担类型', key: 'Pay Fee Type' },
          { label: '币种', key: 'Currency' },
          { label: '金额', key: 'Pay Money' },
          { label: '预计付款日期', key: 'Plan Pay Time' },
          { label: '客户', key: 'Company' },
          { label: '一类付款类型', key: 'Pay Cat1' },
          { label: '二类付款类型', key: 'Pay Cat2' },
          { label: '发票号', key: 'Invoice Number' },
          { label: '付款事由', key: 'Note' },
        ];
        this.$showDialog({
          title: '批量导入',
          width: '800px',
          blocks: [{ type: 'excel', result: 'excel_data', config: { headers } }],
          callback: (result) => {
            upload_direct_pays(result).then((res) => {
              appStore.SET_WE_DIALOG(null);
              if (!isEmptyData(res)) {
                this.$message.error('导入失败');
                const tableHeaders = headers.map((item) => ({
                  title: item.label,
                  dataIndex: item.key,
                }));
                tableHeaders.push({ title: '原因', dataIndex: 'Reason' });
                this.$showAlert('Failed Items', {
                  config: {
                    title: 'Failed Items',
                    columns: tableHeaders,
                  },
                  items: res,
                });
              } else {
                this.$message.success('导入成功');
                this.reload();
              }
            });
          },
        });
      },
    },
  });
