
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { defineComponent, ref } from 'vue';
  import { required } from '@/utils/validate';
  import { appStore } from '@/store/modules/app';
  import {
    batch_edit_media_billing_by_excel,
    clear_bill_billing_log,
    delete_bill_adjust,
    edit_bill_adjust,
    gen_pub_confirmation_pdf,
    pub_edit_billing_item,
    pub_batch_edit_billing,
    get_bill_aws_billing_detail,
    upload_scaler_billing,
    edit_scaler_billing,
  } from '@/api/erp-bills';
  import { formatMoneyByRound, eq } from '@/utils/number';
  import { ActionItem, BasicColumn, BasicTableProps, useTable } from '@/components/WeTable';
  import { findDialogInput, formatJson, formatNumberByNumeral, isEmptyData } from '@/utils/common';
  import { cloneDeep, groupBy, isNumber, orderBy, sumBy, uniq, uniqueId } from 'lodash-es';
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { genPubCfmHtmlText } from '@/utils/printPubCfm';
  import { generatorPDFByCanvas } from '@/utils/pdf';
  import { useBills } from '../bills/hooks/useBills';
  import { useJump } from '@/hooks/useJump';
  import dayjs from 'dayjs';
  import { exportJson2Excel } from '@/utils/excel';
  import { export_json_to_excel } from '@/utils/Export2Excel';
  export default defineComponent({
    name: 'Billing',
    props: ['contract', 'bill', 'billing'],
    setup() {
      const { isFinance, isAdmin, isAnalysis, isOne, hasPermission, user } = useUser();
      const { model, appInfo, currencyList } = useAppInfo();
      const { batchEditProductBilling } = useBills();
      const [register, { getSelectRows }] = useTable();
      const { jumpToProduct } = useJump();
      const selectedBillingIds = ref([]);
      return {
        isOne,
        isFinance,
        isAnalysis,
        isAdmin,
        model,
        user,
        appInfo,
        register,
        getSelectRows,
        batchEditProductBilling,
        jumpToProduct,
        selectedBillingIds,
        currencyList,
        hasPermission,
        eq,
      };
    },
    data: () => ({
      expandBilling: false,
      showPubCfm: false,
      showPubCfmHtml: '',
      isManualPubCfm: false,
      savingPubCfm: false,
    }),
    computed: {
      is_parent_bill(): boolean {
        return this.bill.is_parent;
      },
      is_mix(): boolean {
        return this.bill.logic_detail.includes('AppMeta&DSP');
      },
      show_bill_table_button(): boolean {
        return !this.is_parent_bill && this.bill.rd_state_name !== '完成';
      },
      get_platform(): any {
        if (this.is_parent_bill) {
          if (this.bill.sub_bill_list[0]) {
            return this.bill.sub_bill_list[0].rd_buline_name;
          }
          return '';
        }
        return this.bill.rd_buline_name;
      },
      contractLogicDetail(): Recordable {
        return this.contract ? JSON.parse(this.contract.logic_detail || '[]') : [];
      },
      tags() {
        return this.billing && this.billing.billing_tags ? this.billing.billing_tags : [];
      },

      isAWS() {
        return this.tags.includes('AWS');
      },
      isPub(): boolean {
        return this.tags.includes('Pub');
      },
      isScaler(): boolean {
        return this.tags.includes('AppMeta') || this.tags.includes('DSP');
      },
      isSubBillAdjust(): boolean {
        return (
          (this.tags.includes('MSBilling') && (this.tags.includes('MSCustomer') || this.tags.includes('ML'))) ||
          this.tags.includes('Cash')
        );
      },
      isUgsCustomer(): boolean {
        return this.tags.includes('MSBilling') && (this.tags.includes('MSCustomer') || this.tags.includes('MSRebate'));
      },
      isChinaMl(): boolean {
        return this.contractLogicDetail.includes('ML_Supply_China');
      },
      isBx(): boolean {
        return this.contractLogicDetail.includes('BX_Demand');
      },

      canBilling(): boolean {
        const isChecking =
          this.bill.state_value === this.model.BillState.STATE.SUB_AM_MAKING_BILL ||
          this.bill.state_value === this.model.BillState.STATE.AM_CHECKING_BILL ||
          this.bill.state_value === this.model.BillState.STATE.MAIN_AM_WAITING_BILL ||
          this.bill.state_value === this.model.BillState.STATE.MAIN_AM_GOT_BILL;
        return (
          this.isAdmin || this.isFinance || (this.bill.flow!.current_state.owner_id === this.user.id && isChecking)
        );
      },
      isMine(): boolean {
        return [this.bill.owner_id, this.bill.contract_owner_id].includes(this.user.id);
      },

      canDoDetailBilling(): boolean {
        const tags = this.bill.billing.billing_tags;
        return (
          this.bill.is_parent_bill &&
          this.canBilling &&
          !tags.includes('StrictSystemBilling') &&
          (this.isChinaMl || this.isBx || tags.includes('MSBilling'))
        );
      },

      mediaMlbxConfig(): BasicTableProps {
        const vm = this;
        const config: BasicTableProps = {
          rowSelection: vm.canDoDetailBilling ? { type: 'checkbox' } : undefined,
          useSearchForm: false,
          title: this.bill.is_supply ? '买量对账' : '变现对账',
          columns: [],
          moreFunc: [],
          immediate: false,
          pagination: {
            pageSize: 10,
          },
        };

        if (this.bill.is_supply) {
          const supplyColumns: any[] = [
            { title: '账户ID', dataIndex: 'account_id' },
            { title: '代理', dataIndex: 'agency_name', componentType: 'a-select' },
            { title: '平台', dataIndex: 'platform', componentType: 'a-select' },
            {
              title: '产品Code',
              dataIndex: 'rd_product_code',
              componentType: 'a-select',
              slots: { customRender: 'code' },
            },
            { title: '对账状态', dataIndex: 'status', componentType: 'a-select' },
            { title: '币种', dataIndex: 'rd_currency_code' },
          ];

          if (this.isChinaMl) {
            config.columns = supplyColumns.concat([
              { title: '结算金额', dataIndex: 'invoice_money', componentType: 'we-number-range' },
              { title: 'BI结算金额', dataIndex: 'invoice_computed', componentType: 'we-number-range' },
              { title: '结算金额差异', dataIndex: 'delta_invoice', componentType: 'we-number-range' },
              { title: '合同返点', dataIndex: 'rebate_rate', componentType: 'we-number-range', round: 4 },
              {
                title: '代理商返点',
                dataIndex: 'customer_rebate_computed',
                componentType: 'we-number-range',
                round: 4,
              },
              { title: '非赠款消耗', dataIndex: 'customer_estimate', componentType: 'we-number-range', round: 4 },
              { title: 'BI非赠款消耗', dataIndex: 'bi_raw_money', componentType: 'we-number-range', round: 4 },
              { title: '非赠款消耗差异', dataIndex: 'delta_estimate', componentType: 'we-number-range', round: 4 },
              { title: '赠款金额', dataIndex: 'customer_reward', componentType: 'we-number-range', round: 4 },
              { title: 'BI赠款金额', dataIndex: 'reward_money', componentType: 'we-number-range', round: 4 },
              { title: '赠款金额差异', dataIndex: 'delta_reward', componentType: 'we-number-range', round: 4 },
              { title: '结算暂估差异', dataIndex: 'delta_money', componentType: 'we-number-range', round: 4 },
            ]);
          } else {
            config.columns = supplyColumns.concat([
              { title: '暂估金额', dataIndex: 'estimate_money', componentType: 'we-number-range' },
              { title: '结算金额', dataIndex: 'invoice_money', componentType: 'we-number-range' },
              { title: '结算暂估差异', dataIndex: 'delta_money', componentType: 'we-number-range' },
            ]);
          }
        } else {
          config.columns = [
            { title: '账户ID', dataIndex: 'account_id' },
            { title: '账户名称', dataIndex: 'account_name', componentType: 'a-select' },
            { title: '平台', dataIndex: 'platform', componentType: 'a-select' },
            {
              title: '产品Code',
              dataIndex: 'rd_product_code',
              componentType: 'a-select',
              slots: { customRender: 'code' },
            },
            { title: '币种', dataIndex: 'rd_currency_code', componentType: 'a-select' },
            { title: '暂估金额', dataIndex: 'estimate_money', componentType: 'we-number-range' },
            { title: '结算金额', dataIndex: 'invoice_money', componentType: 'we-number-range' },
            { title: '结算暂估差异', dataIndex: 'delta_money', componentType: 'we-number-range' },
            { title: '对账状态', dataIndex: 'status', componentType: 'a-select' },
          ];
        }

        config.initItem = (item) => {
          item.delta_money = item.status === 'Done' ? (item.invoice_money || 0) - (item.estimate_money || 0) : null;
          if (this.isChinaMl) {
            if (item.invoice_money) {
              item.customer_rebate_computed = (item.customer_estimate || 0) / item.invoice_money - 1;
            }
            item.delta_cost = (item.estimate_money || 0) - (item.customer_estimate || 0);
            item.delta_reward = (item.reward_money || 0) - (item.customer_reward || 0);
            item.delta_invoice = (item.invoice_computed || 0) - (item.invoice_money || 0);
          }
        };
        if (this.expandBilling) {
          config.pagination = false;
        }
        return config;
      },
      mediaMlbxRebateConfig() {
        const config: BasicTableProps = {
          title: '返点对账',
          useSearchForm: false,
          columns: [],
          moreFunc: [],
          immediate: false,
          pagination: {},
        };

        if (this.bill.is_supply) {
          config.columns = [
            { title: '平台', dataIndex: 'platform', componentType: 'a-select' },
            { title: '账户ID', dataIndex: 'account_id' },
            { title: '账户名称', dataIndex: 'account_name', componentType: 'a-select' },
            { title: 'BI基数', dataIndex: 'bi_amount', componentType: 'we-number-range' },
            { title: 'HW返点率', dataIndex: 'demand_rebate_rate', componentType: 'we-number-range', round: 4 },
            { title: '暂估返点', dataIndex: 'demand_rebate_estimate', componentType: 'we-number-range' },
            { title: '实际返点', dataIndex: 'demand_rebate_invoice', componentType: 'we-number-range' },
          ];
        } else {
          config.columns = [
            { title: '平台', dataIndex: 'platform', componentType: 'a-select' },
            { title: '账户ID', dataIndex: 'account_id' },
            { title: '账户名称', dataIndex: 'account_name', componentType: 'a-select' },
            { title: 'BI基数', dataIndex: 'bi_amount', componentType: 'we-number-range' },
            { title: 'HW返点率', dataIndex: 'supply_rebate_rate', componentType: 'we-number-range', round: 4 },
            { title: '暂估返点', dataIndex: 'supply_rebate_estimate', componentType: 'we-number-range' },
            { title: '实际返点', dataIndex: 'supply_rebate_invoice', componentType: 'we-number-range' },
          ];
        }

        if (!this.expandBilling) {
          config.pagination = { pageSize: 10 };
        }

        return config;
      },
      mediaMsConfig(): BasicTableProps {
        const config: BasicTableProps = {
          useSearchForm: false,
          immediate: false,
          columns: [
            { title: '平台', dataIndex: 'platform', componentType: 'a-select', showTop: true },
            { title: '账户ID', dataIndex: 'account_id', componentType: 'a-input', showTop: true },
            { title: 'Code', dataIndex: 'rd_product_code', componentType: 'a-select', slots: { customRender: 'code' } },
            { title: '状态', dataIndex: 'status', componentType: 'a-select' },
            { title: '币种', dataIndex: 'rd_currency_code', componentType: 'a-select' },
            { title: 'Billing', dataIndex: 'invoice_money', componentType: 'we-number-range', numberFormat: '0,0.00' },
            { title: 'BI Cost', dataIndex: 'estimate_money', componentType: 'we-number-range', numberFormat: '0,0.00' },
            { title: 'Demand Rebate', dataIndex: 'demand_rebate', round: 4, componentType: 'a-input' },
            { title: 'Supply Rebate', dataIndex: 'supply_rebate', round: 4, componentType: 'a-input' },
            { title: 'Demand Service', dataIndex: 'demand_service_fee', round: 4, componentType: 'a-input' },
            { title: 'Supply Service', dataIndex: 'supply_service_fee', round: 4, componentType: 'a-input' },
          ],
          moreFunc: [],
        };
        return config;
      },

      pubBillingShowGrant(): boolean {
        return (
          !isEmptyData(this.billing.revenue_list) &&
          !this.billing.revenue_list.find((r) => r.is_finance_reviewed === false)
        );
      },
      pubBillingLog(): Recordable {
        if (this.bill.billing_log) {
          return JSON.parse(this.bill.billing_log);
        }
        return {};
      },
      pubBillingConfig(): BasicTableProps {
        const vm = this;
        const config: BasicTableProps = {
          immediate: false,
          title: `发行对账 (${this.bill.rd_currency_name})`,
          columns: [
            {
              title: 'Code',
              dataIndex: 'product_code',
              showTop: true,
              customRender: ({ record, text }) => {
                const obj = {
                  children: text,
                  props: {} as any,
                };
                if (record.is_sum_by_group) {
                  obj.children = '';
                }
                return obj;
              },
            },
            {
              title: '产品名称/分类',
              dataIndex: 'product_name',
              showTop: true,
              customCell: (record) => {
                if (!isEmptyData(record.is_sum_by_group)) {
                  return {
                    style: { 'font-weight': 700 },
                  };
                }
                return {};
              },
            },

            {
              title: '支出',
              dataIndex: 'cost',
              componentType: 'we-number-range',
              round: 2,
              customCell: (record) => {
                if (!isEmptyData(record.is_sum_by_group)) {
                  return {
                    style: { 'font-weight': 700 },
                  };
                }
                return {};
              },
            },
            {
              title: '收入',
              dataIndex: 'revenue',
              componentType: 'we-number-range',
              round: 2,
              customCell: (record) => {
                if (!isEmptyData(record.is_sum_by_group)) {
                  return {
                    style: { 'font-weight': 700 },
                  };
                }
                return {};
              },
            },
            {
              title: '盈利',
              dataIndex: 'profit',
              componentType: 'we-number-range',
              round: 2,
              customCell: (record) => {
                if (!isEmptyData(record.is_sum_by_group)) {
                  return {
                    style: { 'font-weight': 700 },
                  };
                }
                return {};
              },
            },
            { title: '计入分成', dataIndex: 'divide_count_in_str', componentType: 'a-input' },
            { title: '分成类型', dataIndex: 'divide_type_str', componentType: 'a-input' },
            {
              title: '分成比例',
              dataIndex: 'divide_ratio',
              componentType: 'we-number-range',
              slots: { customRender: 'divide_ratio' },
            },
            {
              title: '分成金额',
              dataIndex: 'divide_amount',
              componentType: 'we-number-range',
              slots: { customRender: 'divide_amount' },
              round: 2,
              customCell: (record) => {
                if (!isEmptyData(record.is_sum_by_group)) {
                  return {
                    style: { 'font-weight': 700 },
                  };
                }
                return {};
              },
            },

            ...(vm.pubBillingShowGrant
              ? [
                  { title: '计入补贴', dataIndex: 'grant_count_in_str' },
                  {
                    title: '总补贴',
                    dataIndex: 'grant_all',
                    componentType: 'we-number-range',
                    round: 2,
                  },
                  {
                    title: '补贴比例',
                    dataIndex: 'grant_ratio',
                    componentType: 'we-number-range',
                  },
                  {
                    title: '补贴金额',
                    dataIndex: 'grant_amount',
                    componentType: 'we-number-range',
                    round: 2,
                  },
                ]
              : []),

            ...(vm.canBilling ? [{ title: '编辑', dataIndex: 'action', slots: { customRender: 'action' } }] : []),
          ],
          initItem: (item) => {
            if (item.product_id) {
              item.divide_type_str = { by_profit: '按盈利', by_revenue: '按收入' }[item.divide_type];
              item.grant_count_in_str = item.grant_count_in ? '计入' : '不计入';
              item.divide_count_in_str = item.divide_count_in ? '计入' : '不计入';
            }
          },
          moreFunc:
            this.canBilling || this.isMine
              ? [{ title: '分平台收入明细', click: vm.showPubBillingRevenueDetail, icon: 'description' }]
              : [],
        };
        return config;
      },
      pubBillingTotal() {
        const total = {};
        const totalRow = ['cost', 'revenue', 'profit', 'divide_amount', 'grant_all', 'grant_amount'];
        this.billing.billing_list
          .filter((x) => x.divide_count_in)
          .forEach((billing) => {
            totalRow.forEach((r) => {
              total[r] = (total[r] || 0) + Number(billing[r]);
            });
          });
        total['divide_count_in'] = true;
        total['product_code'] = '总计';
        return [total];
      },
      pubBillingDataSource() {
        let product_list = cloneDeep(this.billing.billing_list || []);
        product_list = orderBy(product_list, ['product_code'], ['asc']);
        let pub_billing_data_source: Recordable[] = [];
        let has_classified = product_list.some((item) => !isEmptyData(item.name_group));
        if (has_classified) {
          let grouped_product_list = groupBy(product_list, 'name_group');
          Reflect.ownKeys(grouped_product_list).forEach((item: string) => {
            const first_grouped_product_item = grouped_product_list[item][0];
            if (isEmptyData(item)) {
              grouped_product_list[item].forEach((f) => {
                f.name_group = '其它';
              });
            }
            grouped_product_list[item].unshift({
              product_code: uniqueId(),
              product_name: first_grouped_product_item.name_group,
              cost: sumBy(grouped_product_list[item], 'cost'),
              revenue: sumBy(grouped_product_list[item], 'revenue'),
              profit: sumBy(grouped_product_list[item], 'profit'),
              divide_amount: sumBy(grouped_product_list[item], 'divide_amount'),
              divide_count_in: true,
              is_sum_by_group: true,
            });
            pub_billing_data_source.push(...grouped_product_list[item]);
          });
          return pub_billing_data_source.concat(this.pubBillingTotal);
        } else {
          return this.billing.billing_list.concat(this.pubBillingTotal);
        }
      },
      cloudBillingDataConfig(): BasicTableProps {
        const config: BasicTableProps = {
          immediate: false,
          useFilterFromColumns: true,
          title: 'GCP Billing',
          rowSelection: { type: 'checkbox' },
          columns: [
            { title: '结算账号ID', dataIndex: 'billing_account_id', componentType: 'a-input' },
            { title: '项目ID', dataIndex: 'project', componentType: 'a-input', showTop: true },
            { title: '项目名称', dataIndex: 'project_name', componentType: 'a-input', showTop: true },
            { title: '服务ID', dataIndex: 'service_id', componentType: 'a-input', showTop: true },
            { title: '服务名称', dataIndex: 'service', componentType: 'a-input', showTop: true },
            { title: 'SKU', dataIndex: 'sku', componentType: 'a-input', showTop: true },
            { title: 'SKU描述', dataIndex: 'sku_desc', componentType: 'a-input', showTop: true },
            { title: '计费模式', dataIndex: 'mode_name', componentType: 'a-input', showTop: true },
            { title: 'Discount', dataIndex: 'discount', round: 4, componentType: 'we-number-range' },
            { title: 'Price/GD', dataIndex: 'price', round: 4, componentType: 'we-number-range' },
            { title: 'List Cost', dataIndex: 'list_cost', round: 4, componentType: 'we-number-range' },

            { title: 'c_cud', dataIndex: 'c_cud', round: 4, componentType: 'we-number-range' },
            { title: 'c_cud_db', dataIndex: 'c_cud_db', round: 4, componentType: 'we-number-range' },
            { title: 'c_discount', dataIndex: 'c_discount', round: 4, componentType: 'we-number-range' },
            { title: 'c_free_tier', dataIndex: 'c_free_tier', round: 4, componentType: 'we-number-range' },
            { title: 'c_promotion', dataIndex: 'c_promotion', round: 4, componentType: 'we-number-range' },
            { title: 'c_rm', dataIndex: 'c_rm', round: 4, componentType: 'we-number-range' },
            { title: 'c_sub_benefit', dataIndex: 'c_sub_benefit', round: 4, componentType: 'we-number-range' },
            { title: 'c_sud', dataIndex: 'c_sud', round: 4, componentType: 'we-number-range' },

            { title: 'Usage', dataIndex: 'amount', round: 4, componentType: 'we-number-range' },
            { title: 'Unit', dataIndex: 'unit', componentType: 'a-input' },
            { title: 'Consumption', dataIndex: 'consumption', round: 4, componentType: 'we-number-range' },
            { title: 'Cost', dataIndex: 'cost', round: 4, componentType: 'we-number-range' },
            { title: '对账金额', dataIndex: 'estimate', round: 4, componentType: 'we-number-range' },
          ],
        };
        if (this.tags.includes('GCP')) {
          config.moreFunc = [
            { title: 'Show Config', click: this.showGcpBillingConfig },
            { title: '下载对账明细', click: this.downloadGcpBillingDetail },
          ];
        }
        if (this.tags.includes('GSuite')) {
          config.title = 'GSuite Billing';
          config.columns = [
            { title: '域名', dataIndex: 'domain_name', showTop: true },
            { title: '类型', dataIndex: 'subscription', showTop: true },
            { title: '描述', dataIndex: 'description', showTop: true },
            { title: '起始日期', dataIndex: 'start_date' },
            { title: '结束日期', dataIndex: 'end_date' },
            { title: '使用天数', dataIndex: 'days' },
            { title: '席位数', dataIndex: 'quantity' },
            { title: '单价', dataIndex: 'revenue_price', showTop: true },
            { title: '成本', dataIndex: 'cost', componentType: 'we-number-range' },
            { title: '收入', dataIndex: 'revenue', componentType: 'we-number-range' },
          ];
          config.moreFunc = [{ title: '下载对账明细', click: this.downloadGsuiteBillingDetail }];
        }

        if (this.tags.includes('MSG')) {
          config.title = 'MSG Billing';
          config.columns = [
            { title: '合同ID', dataIndex: 'contract_id', showTop: true },
            { title: '客户名称', dataIndex: 'company__name', showTop: true },
            { title: '短信类型', dataIndex: 'tmpl_type', showTop: true },
            { title: '供应商', dataIndex: 'vendor' },
            { title: '国家/地区', dataIndex: 'country_name' },
            { title: '短信条数', dataIndex: 'send_count' },
            { title: '成本单价', dataIndex: 'cost_price', round: 3 },
            { title: '对客单价', dataIndex: 'revenue_price', round: 3 },
            {
              title: '成本',
              dataIndex: 'cost',
              componentType: 'we-number-range',
              format: (text) => {
                let res = text.toFixed(3);
                return res.substring(0, res.lastIndexOf('.') + 3);
              },
            },
            {
              title: '收入',
              dataIndex: 'revenue',
              componentType: 'we-number-range',
              format: (text) => {
                let res = text.toFixed(3);
                return res.substring(0, res.lastIndexOf('.') + 3);
              },
            },
            {
              title: '月份',
              dataIndex: 'month',
              componentType: 'a-month-picker',
              componentProps: {
                valueFormat: 'YYYY-MM',
              },
              init: dayjs().subtract(1, 'months').format('YYYY-MM'),
              showTop: true,
              format: (text) => {
                return dayjs(text).format('YYYY-MM');
              },
            },
          ];
        }
        if (this.tags.includes('AWS')) {
          config.title = 'AWS Billing';
          const month = this.bill.month;
          config.columns = [
            {
              title: '账单账户ID',
              dataIndex: 'account_id',
              customCell: () => {
                return {
                  style: { 'white-space': 'pre' },
                };
              },
              format: (text) => {
                if (Array.isArray(text)) {
                  return text.join('\n');
                } else {
                  return text;
                }
              },
              showTop: true,
            },
            {
              title: '折扣',
              dataIndex: 'billing_config__discount',
              showTop: true,
            },
            {
              title: 'SPP折扣',
              dataIndex: 'spp_discount',
              ignoreField: true,
              hiddenFilter: true,
              componentType: 'we-number-range',
              numberFormat: '0,0.00',
            },
            {
              title: '成本',
              dataIndex: 'cost',
              numberFormat: '0.00',
              showTop: true,
              componentType: 'we-number-range',
            },
            {
              title: '收入',
              dataIndex: 'revenue',
              numberFormat: '0.00',
              showTop: true,
              componentType: 'we-number-range',
            },
            {
              title: '日期',
              dataIndex: 'date',
              componentType: 'a-range-picker',
              componentProps: {
                valueFormat: 'YYYY-MM-DD',
              },
              init: [
                dayjs(month).startOf('month').format('YYYY-MM-DD'),
                dayjs(month).endOf('month').format('YYYY-MM-DD'),
              ],
            },
          ];
          config.moreFunc = [{ title: '展示详情', click: this.showAWSDetail }];
        }
        return config;
      },
      scalerBillingAdjustConfig(): BasicTableProps {
        return {
          title: `调整项`,
          immediate: false,
          columns: [
            { title: '扣件金额（USD）', dataIndex: 'adjust_money' },
            { title: '调整说明', dataIndex: 'note' },
          ],
        };
      },
      billingAdjustConfig(): BasicTableProps {
        return {
          title: `账单调整项 (${this.bill.rd_currency_name})`,
          immediate: false,
          columns: [
            { title: '创建时间', dataIndex: 'create_time', componentType: 'a-range-picker' },
            ...(this.isUgsCustomer ? [{ title: '平台', dataIndex: 'platform', componentType: 'a-input' }] : []),
            ...(this.isSubBillAdjust
              ? [{ title: '针对子账单', dataIndex: 'for_sub_bill_id', componentType: 'a-input' }]
              : []),
            { title: '备注', dataIndex: 'note', componentType: 'a-input', showTop: true, width: 500 },
            ...(this.isScaler ? [{ title: '核减/增加', dataIndex: 'adjust_type', ignoreField: true }] : []),
            {
              title: '调整金额',
              dataIndex: 'adjust_money',
              componentType: 'we-number-range',
              hiddenFilter: true,
              componentProps: {
                placeholder: ['Adjustment Min', 'Adjustment Max'],
              },
            },
            ...(this.isPub
              ? [
                  { title: '是否计入', dataIndex: 'count_in_str', componentType: 'a-input' },
                  { title: '计入比例', dataIndex: 'ratio', componentType: 'a-input', slots: { customRender: 'ratio' } },
                  {
                    title: '计入金额',
                    dataIndex: 'real_money',
                    componentType: 'we-number-range',
                    hiddenFilter: true,
                    slots: { customRender: 'real_money' },
                    numberFormat: '0,0.00',
                  },
                ]
              : []),
            ...(this.canBilling
              ? [
                  {
                    title: '操作',
                    flag: 'ACTION' as any,
                    dataIndex: 'action',
                    slots: { customRender: 'adjust_action' },
                  },
                ]
              : []),
          ],
          initItem: (item) => {
            item.count_in_str = item.count_in ? '计入' : '不计入';
            //TODO Wetable 刷3次
            // item.ratio = Number(item.ratio) * 100;
            item.count_num = item.count_num ? item.count_num + 1 : 1;
            if (item.count_num === 1) {
              item.ratio = Number(item.ratio) * 100;
            }
          },
        };
      },
      scalerBillingConfig(): any {
        return {
          columns: [
            ...(this.is_parent_bill ? [{ title: '业务线', dataIndex: 'platform' }] : []),
            ...[
              { title: '广告主名称', dataIndex: 'company_short_name' },
              { title: '账户名称', dataIndex: 'agency_name' },
              { title: '产品名称', dataIndex: 'adv_name' },
              { title: '地区', dataIndex: 'country' },
              { title: '产品包名', dataIndex: 'adv_bundle' },
              { title: 'Campaign名称', dataIndex: 'camp_name' },
              { title: '暂估转化数', dataIndex: 'install' },
              { title: '暂估金额', dataIndex: 'gmv', numberFormat: '0,0.00' },
              { title: '真实转化数', dataIndex: 'real_install' },
              { title: '结算金额', dataIndex: 'billing_money', numberFormat: '0,0.00' },
              ...(this.is_mix
                ? [
                    { title: '总计真实转化数', dataIndex: 'total_real_install' },
                    { title: '总计结算金额', dataIndex: 'total_billing_money', numberFormat: '0,0.00' },
                  ]
                : []),
              { title: '差值', dataIndex: 'diff', slots: { customRender: 'diff' } },
              { title: '调整说明', dataIndex: 'note', slots: { customRender: 'note' } },
            ],
            ...(this.show_bill_table_button
              ? [{ title: '操作', flag: 'ACTION', dataIndex: 'action', slots: { customRender: 'action' } }]
              : []),
          ],
          useSearchForm: false,
          immediate: false,
          title: '对账数据',
          pagination: { pageSize: 10 },
        };
      },
      cashBillingConfig(): BasicTableProps {
        if (this.bill.rd_currency_name === 'CNY') {
          return {
            title: '兑付对账',
            useSearchForm: false,
            columns: [
              { title: '日期', dataIndex: 'date', componentType: 'a-select', showTop: true },
              {
                title: '产品Code',
                dataIndex: 'product__code',
                componentType: 'a-select',
                slots: { customRender: 'code' },
                showTop: true,
              },
              { title: 'Buline', dataIndex: 'product__rd_buline_name', componentType: 'a-select', showTop: true },
              { title: '商户ID', dataIndex: 'mch_id', componentType: 'a-select', showTop: true },
              { title: '金额', dataIndex: 'cost', componentType: 'we-number-range' },
              { title: '含服务金额', dataIndex: 'cost_with_service_fee', componentType: 'we-number-range' },
            ],
          };
        } else {
          return {
            title: '兑付对账',
            useSearchForm: false,
            columns: [
              { title: '日期', dataIndex: 'date', componentType: 'a-select', showTop: true },
              {
                title: '产品Code',
                dataIndex: 'product__code',
                componentType: 'a-select',
                slots: { customRender: 'code' },
                showTop: true,
              },
              { title: 'Buline', dataIndex: 'product__rd_buline_name', componentType: 'a-select', showTop: true },
              { title: '商户名称', dataIndex: 'mch_name', componentType: 'a-select' },
              { title: '代理', dataIndex: 'agent_name', componentType: 'a-select', showTop: true },
              { title: '国家', dataIndex: 'country', componentType: 'a-select' },
              { title: '商户ID', dataIndex: 'mch_id', componentType: 'a-select' },
              { title: '金额', dataIndex: 'cost', componentType: 'we-number-range' },
              { title: '含服务金额', dataIndex: 'cost_with_service_fee', componentType: 'we-number-range' },
            ],
          };
        }
      },
    },
    emits: ['change'],
    methods: {
      formatNumberByNumeral,
      handle_scaler_import() {
        const headers = [
          { label: '*包名', key: '包名' },
          { label: '*地区', key: '地区' },
          { label: 'Campaign名称', key: 'Campaign' },
          { label: '*真实转化数', key: '真实转化数' },
          { label: '*结算金额', key: '结算金额' },
          ...(this.is_mix && this.bill.rd_buline_name === 'AppMeta'
            ? [
                { label: '总计真实转化数（非必填）', key: '总计真实转化数' },
                { label: '总计结算金额（非必填）', key: '总计结算金额' },
              ]
            : []),
          { label: '调整说明（非必填）', key: '调整说明' },
        ];
        this.$showDialog({
          title: '导入对账数据',
          width: '800px',
          blocks: [{ type: 'excel', result: 'excel_data', config: { headers } }],
          callback: (result) => {
            appStore.SET_WE_DIALOG(null);
            const params = {
              bill_id: this.bill.id,
              platform: this.get_platform,
              ...result,
            };
            upload_scaler_billing(params).then((res) => {
              this.$showOperLog({
                operation_group: res.operation_group,
                start_content: '开始',
                end_content: '结束',
                afterClose: () => {
                  this.$message.success('导入成功');
                  this.emitChange(res);
                },
              });
            });
          },
        });
      },
      handle_edit_scaler_record(record) {
        const inputs: DialogFormItem[] = [
          {
            label: 'Campaign名称',
            result: 'camp_name',
            type: 'text',
            rules: [],
            width: 24,
          },
          {
            label: '结算金额',
            result: 'billing_money',
            type: 'number',
            rules: [required()],
            width: 24,
            disabled: () => {
              return this.is_mix && this.bill.rd_buline_name === 'DSP' && !isEmptyData(record.total_billing_money);
            },
          },
          {
            label: '真实转化数',
            result: 'real_install',
            type: 'number',
            rules: [required()],
            width: 24,
            disabled: () => {
              return this.is_mix && this.bill.rd_buline_name === 'DSP' && !isEmptyData(record.total_billing_money);
            },
          },
          ...(this.is_mix && this.bill.rd_buline_name === 'AppMeta'
            ? [
                {
                  label: '总计结算金额',
                  result: 'total_billing_money',
                  type: 'number',
                  width: 24,
                },
                {
                  label: '总计真实转化数',
                  result: 'total_real_install',
                  type: 'number',
                  width: 24,
                },
              ]
            : []),
        ];
        const dialog: DialogConfig = {
          title: '编辑记录',
          width: '600px',
          labelCol: this.is_mix ? '120px' : '100px',
          init: record,
          blocks: [
            { type: 'input', value: inputs },
            { label: '调整说明', result: 'note', type: 'note', width: 24 },
          ],
          callback: (result) => {
            let item = {
              ...record,
              ...result,
            };
            if (!item.note) {
              item.note = '';
            }
            const index = this.billing.billing_list.findIndex((f) => f.id === item.id);
            this.billing.billing_list.splice(index, 1, item);
            const data = this.billing.billing_list.map((item) => {
              const res = {
                id: item.id,
                camp_name: item.camp_name || '',
                billing_money: item.billing_money,
                note: item.note,
                real_install: item.real_install,
              };
              if (this.is_mix && this.bill.rd_buline_name === 'AppMeta') {
                res['total_billing_money'] = item.total_billing_money;
                res['total_real_install'] = item.total_real_install;
              }
              return res;
            });
            edit_scaler_billing({ data }).then((res) => {
              this.$message.success('编辑成功');
              this.emitChange(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        };
        this.$showDialog(dialog);
      },
      get_diff(record) {
        if (isNumber(record.billing_money) && isNumber(Number(record.gmv))) {
          let diff = record.billing_money - record.gmv;
          if (this.eq(diff, 0)) {
            diff = 0;
          }
          return diff;
        }
        return 0;
      },
      formatCurrency(number, currency) {
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency }).format(Number(number));
      },
      isEmptyData,
      formatMoneyByRound,
      emitChange(res) {
        this.$emit('change', res);
      },
      editProductBilling() {
        if (this.getSelectRows().length === 0) {
          this.$message.warning('请勾选数据');
          return;
        }
        this.batchEditProductBilling(this, this.getSelectRows(), this.emitChange, this.bill.month);
      },
      downloadGcpBillingDetail() {
        const is_usd = this.bill.rd_currency_name === 'USD';
        let data = this.billing.billing_list.map((x) => ({
          Month: this.bill.month,
          'Account ID': x.billing_account_id,
          Project: x.project,
          Service: x.service,
          SKU: x.sku,
          Usage: formatNumberByNumeral(x.amount, '0.0000'),
          Amount: x.estimate,
        }));
        function addLine(k, v) {
          data.push({ Month: '', 'Account ID': '', Project: '', Service: '', SKU: '', Usage: k, Amount: v });
        }
        if (!is_usd) {
          const cloud_tax = this.billing.config.contract_config.tax;
          const currency_rate = this.billing.currency_rate;
          const currency_name = this.billing.currency_name;
          const use_revenue = this.billing.result_final / currency_rate / (cloud_tax + 1);
          addLine('总计', `$${formatNumberByNumeral(use_revenue, '0,0.00')}`);
          if (cloud_tax !== 0) {
            addLine('税率', formatNumberByNumeral(cloud_tax, '0.00%'));
          }
          addLine('汇率', formatNumberByNumeral(currency_rate, '0.0000'));
          addLine(`${currency_name}总计`, `${this.formatCurrency(this.billing.result_final, currency_name)}`);
        } else {
          addLine('总计', formatNumberByNumeral(this.billing.result_final, '$0,0.00'));
        }
        data.unshift({
          Month: `Month`,
          'Account ID': 'Account ID',
          Project: 'Project',
          Service: 'Service',
          SKU: 'SKU',
          Usage: 'Usage',
          Amount: 'Amount',
        });
        data.unshift({
          Month: `${this.bill.month} ${this.bill.rd_company_short_name} GCP消耗明细单`,
          'Account ID': '',
          Project: '',
          Service: '',
          SKU: '',
          Usage: '',
          Amount: '',
        });
        const merges = [
          {
            s: {
              c: 0,
              r: 0,
            },
            e: {
              c: 6,
              r: 0,
            },
          },
        ];
        data = formatJson(['Month', 'Account ID', 'Project', 'Service', 'SKU', 'Usage', 'Amount'], data);
        export_json_to_excel({
          merges,
          data,
          filename: `${this.bill.rd_company_short_name} GCP消耗明细表`,
        });
        // downloadExcelData(data, this.bill.rd_company_short_name + ' GCP 消耗明细表 ');
      },
      editAdjustment(item) {
        const isAdd = !item;
        item = item || {};

        const countInItems = [
          { text: '计入', value: true },
          { text: '不计入', value: false },
        ];
        let all_result_desc: Recordable[] = [];
        if (!isEmptyData(this.billing.ms_billings)) {
          all_result_desc = this.billing.ms_billings.map((item) => item.result_desc);
        }
        const platfromItems = [
          {
            text: 'Adwords',
            value: 'Adwords',
            disabled: isEmptyData(all_result_desc.find((item) => item.toLowerCase().includes('adwords'))),
          },
          {
            text: 'FB',
            value: 'FB',
            disabled: isEmptyData(
              all_result_desc.find(
                (item) => item.toLowerCase().includes('fb') || item.toLowerCase().includes('facebook')
              )
            ),
          },
          {
            text: 'TikTok',
            value: 'TikTok',
            disabled: isEmptyData(all_result_desc.find((item) => item.toLowerCase().includes('tiktok'))),
          },
          { text: 'All', value: 'All' },
        ];

        this.$showDialog({
          title: isAdd ? '添加Adjustment' : '编辑Adjustment',
          width: '520px',
          labelCol: this.isScaler ? '120px' : '80px',
          init: item,
          blocks: [
            {
              type: 'input',
              value: [
                ...(this.isPub
                  ? [
                      {
                        type: 'radio',
                        label: '是否计入',
                        result: 'count_in',
                        items: countInItems,
                        width: 24,
                        rules: [required()],
                      },
                    ]
                  : []),
                ...(this.isScaler
                  ? [
                      {
                        type: 'select',
                        label: '调整类型',
                        result: 'adjust_type',
                        items: [
                          { id: '核减', name: '核减' },
                          { id: '增加', name: '增加' },
                          { id: '增加（税额）', name: '增加（税额）' },
                        ],
                        width: 24,
                        rules: [required()],
                        change: (result, _input, config) => {
                          const find_item = findDialogInput(config, 'adjust_money');
                          const rules: Recordable[] = [required()];
                          let placeholder = '';
                          if (result.adjust_type === '核减') {
                            placeholder = '请填写负值';
                            rules.push({
                              validator: async (_rule, val: any) => {
                                // if (isEmptyData(val) || val.length <= 6) {
                                if (!isEmptyData(val) && val >= 0) {
                                  return Promise.reject('请填写负值');
                                } else {
                                  return Promise.resolve();
                                }
                              },
                              trigger: 'change',
                            });
                          } else if (result.adjust_type === '增加') {
                            placeholder = '请填写正值';
                            rules.push({
                              validator: async (_rule, val: any) => {
                                // if (isEmptyData(val) || val.length <= 6) {
                                if (!isEmptyData(val) && val <= 0) {
                                  return Promise.reject('请填写正值');
                                } else {
                                  return Promise.resolve();
                                }
                              },
                              trigger: 'change',
                            });
                          } else if (result.adjust_type === '增加（税额）') {
                            placeholder = '请填写正值';
                            rules.push({
                              validator: async (_rule, val: any) => {
                                // if (isEmptyData(val) || val.length <= 6) {
                                if (!isEmptyData(val) && val <= 0) {
                                  return Promise.reject('请填写正值');
                                } else {
                                  return Promise.resolve();
                                }
                              },
                              trigger: 'change',
                            });
                          }
                          find_item.placeholder = placeholder;
                          find_item.rules = rules;
                        },
                      },
                    ]
                  : []),
                ...(this.isUgsCustomer
                  ? [
                      {
                        width: '24',
                        label: '调整方式',
                        result: 'adjust_type',
                        type: 'radio',
                        items: [
                          { value: '按调整金额', text: '按调整金额' },
                          { value: '按最终账单金额', text: '按最终账单金额' },
                        ],
                        init: '按调整金额',
                      },
                      {
                        type: 'select',
                        label: '平台',
                        result: 'platform',
                        items: platfromItems,
                        rules: [required('string', '请选择平台')],
                        width: 24,
                      },
                    ]
                  : []),
                {
                  type: 'number',
                  result: 'adjust_money',
                  label: this.isScaler ? `金额(${this.bill.rd_currency_name})` : '金额',
                  rules: [required()],
                  placeholder: this.isScaler ? '请填写负值' : '',
                  width: 24,
                  change: (result, _input, _dialog) => {
                    if (!isEmptyData(result.adjust_money) && !isEmptyData(result.ratio) && result.count_in) {
                      result.real_money = (result.adjust_money * result.ratio) / 100;
                    }
                  },
                },
                {
                  type: 'number',
                  result: 'ratio',
                  label: '计入比例',
                  rules: [required()],
                  show: (result) => !!result.count_in,
                  width: 24,
                  componentProps: {
                    formatter: (value) => `${value}%`,
                    parser: (value) => value.replace('%', ''),
                    min: 0,
                    max: 100,
                  },
                  change: (result, _input, _dialog) => {
                    if (!isEmptyData(result.adjust_money) && !isEmptyData(result.ratio)) {
                      (result.real_money = (result.adjust_money * result.ratio) / 100), 2;
                    }
                  },
                },
                {
                  type: 'number',
                  result: 'real_money',
                  label: '计入金额',
                  rules: [required()],
                  show: (result) => !!result.count_in,
                  disabled: true,
                  width: 24,
                },
              ],
            },
            {
              type: 'note',
              label: '备注',
              result: 'note',
              rules: this.isScaler ? [] : [required()],
              width: 24,
              autoSize: { minRows: 5, maxRows: 20 },
            },
          ],
          callback: (result) => {
            if (result.adjust_type === '按最终账单金额') {
              if (result.platform && result.platform !== 'All') {
                const result_platform_text = platfromItems
                  .find((platform) => platform.value === result.platform)!
                  .text.toLowerCase();
                let find_item: Recordable = {};
                for (let i = 0; i < this.billing.ms_billings.length; i++) {
                  const platform_billings = this.billing.ms_billings[i].platform_billings;
                  for (let j = 0; j < platform_billings.length; j++) {
                    const platform_billing = platform_billings[j];
                    if (platform_billing.platform.toLowerCase() === result_platform_text) {
                      find_item = platform_billing;
                      break;
                    }
                  }
                }
                if (find_item) {
                  result.adjust_money = result.adjust_money - find_item.billing_amount;
                }
              } else {
                let all_amount = 0;
                this.billing.ms_billings.forEach((item) => {
                  all_amount += item.billing_amount;
                });
                result.platform = '';
                result.adjust_money = result.adjust_money - all_amount;
              }
            }
            if (!result.count_in) {
              result.real_money = result.adjust_money;
              result.ratio = 1;
            } else {
              result.ratio = result.ratio / 100;
            }
            if (!result.note) {
              result.note = '';
            }
            if (this.isUgsCustomer) {
              delete result.adjust_type;
            }
            edit_bill_adjust({ bill_id: this.bill.id, data: { id: item.id, ...result } }).then((res) => {
              this.emitChange(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      deleteAdjustment(item) {
        this.$showAlert('Warning', 'Are you sure to delete this Adjustment?', () => {
          delete_bill_adjust({ id: item.id }).then((res) => {
            this.emitChange(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      showGcpBillingConfig() {
        const config = this.billing.config;
        const cr = formatMoneyByRound(config.usd_to_cny, 4);
        const rr = formatMoneyByRound(config.rebate_ratio, 4);
        const tax = formatMoneyByRound(config.contract_config.tax || 0, 4);
        this.$showAlert(`1 USD = ${cr} CNY, Rebate Rate = ${rr}, Tax = ${tax}`, {
          config: {
            immediate: false,
            showTableHeader: false,
            useSearchForm: false,
            columns: [
              { title: 'Billing Account ID', dataIndex: 'billing_account_id' },
              { title: 'Project', dataIndex: 'project' },
              { title: 'Service', dataIndex: 'service' },
              { title: 'SKU', dataIndex: 'sku' },
              { title: 'Mode', dataIndex: 'mode_name' },
              { title: 'Discount', dataIndex: 'discount' },
              { title: 'Price/GD', dataIndex: 'price' },
            ],
          },
          items: config.config_list,
        });
      },
      clearBillingLog() {
        this.$showAlert('Warning', 'Clear billing log?', () => {
          clear_bill_billing_log({ id: this.bill.id }).then((res) => {
            this.emitChange(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      uploadInvoiceExcel() {
        const currencyItems = this.currencyList.map((c) => ({ name: c.name, id: c.id }));
        const inputs = [
          {
            type: 'select',
            label: '上传金额币种',
            result: 'data_currency_id',
            init: this.bill.currency_id,
            items: currencyItems,
            widths: 24,
          },
        ];
        const headers = this.isChinaMl
          ? ['Account ID', 'Customer Invoice', 'Customer Cost', 'Customer Reward']
          : ['Account ID', 'Customer Invoice'];
        const toStringHeaders = ['Account ID'];

        this.$showDialog({
          title: 'Upload Invoice Excel',
          width: '600px',
          labelCol: '100px',
          layout: 'vertical',
          blocks: [
            { type: 'input', value: inputs },
            { type: 'excel', result: 'excel_data', config: { headers: headers } },
          ],
          callback: (result) => {
            batch_edit_media_billing_by_excel({ bill_id: this.bill.id, ...result }).then((res) => {
              this.emitChange({});
              appStore.SET_WE_DIALOG(null);
              res.forEach((x) => toStringHeaders.forEach((h) => (x[h] = x[h] + '')));
              const resHeaders = headers.map((x) => {
                return { title: x, dataIndex: x, componentType: x === 'Account ID' ? 'a-input' : 'we-number-range' };
              });
              resHeaders.push({ title: 'Reason', dataIndex: 'Reason', componentType: 'a-input' });
              if (res.length > 0) {
                this.$showAlert('Failed Items', {
                  config: {
                    title: 'Failed Items',
                    immediate: false,
                    columns: resHeaders,
                  },
                  items: res,
                });
              }
            });
          },
        });
      },

      editPubBillingDivide(item, type) {
        const divideTypeItems = [
          { text: '按盈利', value: 'by_profit' },
          { text: '按收入', value: 'by_revenue' },
        ];
        const countInItems = [
          { text: '计入', value: true },
          { text: '不计入', value: false },
        ];

        item = cloneDeep(item);

        if (item.grant_ratio) {
          item.grant_ratio = item.grant_ratio * 100.0;
        }
        if (item.divide_ratio) {
          item.divide_ratio = item.divide_ratio * 100.0;
        }

        const title = `编辑${type === 'divide' ? '分成' : '补贴'}: ${item.product_code}`;
        const inputs: DialogFormItem[] = [];
        if (type === 'divide') {
          inputs.push({
            type: 'radio',
            label: '是否计入分成',
            result: 'divide_count_in',
            items: countInItems,
            width: 24,
          });
          inputs.push({
            type: 'select',
            label: '分成类型',
            result: 'divide_type',
            items: divideTypeItems,
            width: 24,
          });
          inputs.push({ type: 'text', label: '分成比例(%)', result: 'divide_ratio', width: 24 });
        } else {
          inputs.push({
            type: 'radio',
            label: '是否计入补贴',
            result: 'grant_count_in',
            items: countInItems,
            width: 24,
          });
          inputs.push({ type: 'text', label: '补贴比例(%)', result: 'grant_ratio', width: 24 });
        }

        this.$showDialog({
          title: title,
          width: '400px',
          labelCol: '120px',
          init: item,
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            result.grant_ratio = (result.grant_ratio || 0) / 100.0;
            result.divide_ratio = (result.divide_ratio || 0) / 100.0;
            pub_edit_billing_item({
              product_id: item.product_id,
              contract_id: this.bill.contract_id,
              month: this.bill.month,
              data: result,
            }).then((res) => {
              this.emitChange(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      showPubBillingRevenueDetail() {
        const platforms = uniq(this.billing.revenue_list.map((x) => x.platform));

        const data: Recordable[] = [];
        this.billing.product_list.forEach((product) => {
          const item = {
            product_id: product.id,
            product_code: product.code,
            product_name: product.name,
          };
          platforms.forEach((p: string) => {
            item[p] = 0;
            this.billing.revenue_list
              .filter((x) => p === x.platform && product.id === x.product_id)
              .forEach((x) => (item[p] += x.estimate_money_converted));
          });
          data.push(item);
        });

        const headers = [
          { title: 'Code', dataIndex: 'product_code', componentType: 'a-input' },
          { title: '产品名称', dataIndex: 'product_name', componentType: 'a-input' },
        ];
        this.$showAlert('分平台收入明细', {
          config: {
            title: '分平台收入明细',
            immediate: false,
            columns: [
              ...headers,
              ...platforms.map((x: string) => ({
                title: x,
                dataIndex: x,
                componentType: 'we-number-range',
              })),
            ],
          },
          items: data,
        });
      },
      genPubCfm() {
        const vm = this;
        function doShow(isManualPubCfm) {
          vm.showPubCfm = true;
          vm.showPubCfmHtml = genPubCfmHtmlText(vm.bill, isManualPubCfm);
          vm.savingPubCfm = false;
          vm.isManualPubCfm = isManualPubCfm;
        }

        if (this.billing.revenue_list.find((r) => r.is_finance_reviewed === false || r.status === 'Checking')) {
          this.$showAlert('Warning', '有一些收入项尚未对账完成，确定【强制】生成手工确认函？', () => {
            appStore.SET_ALERT_CONFIG(null);
            doShow(true);
          });
        } else {
          doShow(false);
        }
      },
      async savePubCfm() {
        this.savingPubCfm = true;
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

        const file = await generatorPDFByCanvas('pub_cfm_html_content', 'confirmation.pdf');
        let pdf = await toBase64(file);

        const cfmInfo = {
          is_manual: this.isManualPubCfm,
          cfm_invoice_info: document.getElementById('cfm_invoice_info')!.innerHTML,
          cfm_mail_info: document.getElementById('cfm_mail_info')!.innerHTML,
          cfm_note_info: document.getElementById('cfm_note_info')!.innerHTML,
        };
        gen_pub_confirmation_pdf({
          bill_id: this.bill.id,
          pdf: pdf,
          confirmation_info: cfmInfo,
        }).then((res) => {
          this.emitChange(res);
          this.showPubCfm = false;
        });
      },
      handleAdjustmentsActions(record: any, _column: BasicColumn): ActionItem[] {
        return [
          {
            label: 'Edit',
            color: 'error',
            onClick: () => this.editAdjustment(record),
          },
          {
            label: 'Delete',
            onClick: () => this.deleteAdjustment(record),
          },
        ];
      },
      setselectedBillingIds(rowsInfo) {
        this.selectedBillingIds = rowsInfo.rows.filter((x) => x.product_id).map((x) => x.product_id);
      },
      getCheckboxProps(record) {
        return {
          disabled: !record.product_id,
          name: String(record.product_id),
        };
      },
      batchEditDivideInfo() {
        if (this.selectedBillingIds.length === 0) {
          this.$showAlert('警告', '请勾选billing后重试！');
        } else {
          const countInItems = [
            { text: '计入', value: true },
            { text: '不计入', value: false },
          ];
          const divideTypeItems = [
            { text: '按盈利', value: 'by_profit' },
            { text: '按收入', value: 'by_revenue' },
          ];
          const inputs: DialogFormItem[] = [];
          inputs.push({
            type: 'radio',
            label: '是否计入分成',
            result: 'divide_count_in',
            items: countInItems,
            width: 24,
          });
          inputs.push({
            type: 'select',
            label: '分成类型',
            result: 'divide_type',
            show: (result) => !!result.divide_count_in,
            items: divideTypeItems,
            width: 24,
          });
          inputs.push({
            type: 'number',
            label: '分成比例(%)',
            result: 'divide_ratio',
            show: (result) => !!result.divide_count_in,
            width: 24,
            componentProps: {
              formatter: (value) => `${value}%`,
              parser: (value) => value.replace('%', ''),
              min: 0,
              max: 100,
            },
          });
          this.$showDialog({
            title: '批量填写分成信息',
            width: '500px',
            blocks: [{ type: 'input', value: inputs }],
            callback: (result) => {
              result.divide_ratio = ((result.divide_ratio || 0) / 100.0).toFixed(2);
              pub_batch_edit_billing({
                product_ids: this.selectedBillingIds,
                contract_id: this.bill.contract_id,
                month: this.bill.month,
                data: result,
              }).then((res) => {
                this.emitChange(res);
                this.selectedBillingIds = [];
                appStore.SET_WE_DIALOG(null);
              });
            },
          });
        }
      },
      downloadGsuiteBillingDetail() {
        const fields = [
          { title: '域名', dataIndex: 'domain_name' },
          { title: '类型', dataIndex: 'subscription' },
          { title: '描述', dataIndex: 'description' },
          { title: '起始日期', dataIndex: 'start_date' },
          { title: '结束日期', dataIndex: 'end_date' },
          { title: '使用天数', dataIndex: 'days' },
          { title: '席位数', dataIndex: 'quantity' },
          { title: '费用', dataIndex: 'revenue' },
        ];
        const tHeader = fields.map((item) => {
          return item.title;
        });
        const filterVal = fields.map((item) => {
          return item.dataIndex;
        });
        let tableData: any = cloneDeep(this.billing.billing_list);
        const cloud_tax = this.billing.config.contract_config.tax;
        const is_usd = this.billing.currency_name === 'USD';
        const currency_rate = this.billing.currency_rate;
        const curreny_name = this.billing.currency_name;
        let revenue = 0;
        tableData.forEach((item) => {
          const item_revenue = item.revenue;
          item.revenue = formatNumberByNumeral(item.revenue, '0,0.00');
          revenue += item_revenue;
        });
        const usd_final_item = {
          domain_name: '',
          subscription: '',
          description: '',
          start_date: '',
          end_date: '',
          days: '',
          quantity: '总计',
          revenue: `$${formatNumberByNumeral(revenue, '0,0.00')}`,
        };
        tableData.push(usd_final_item);
        if (!is_usd) {
          if (parseInt(cloud_tax) !== 0) {
            tableData.push({
              domain_name: '',
              subscription: '',
              description: '',
              start_date: '',
              end_date: '',
              days: '',
              quantity: '税率',
              revenue: formatNumberByNumeral(cloud_tax, '0.00%'),
            });
          }
          tableData.push({
            domain_name: '',
            subscription: '',
            description: '',
            start_date: '',
            end_date: '',
            days: '',
            quantity: '汇率',
            revenue: formatNumberByNumeral(currency_rate, '0.0000'),
          });
          revenue = revenue * currency_rate * (cloud_tax + 1);
          const cn_final_item = {
            domain_name: '',
            subscription: '',
            description: '',
            start_date: '',
            end_date: '',
            days: '',
            quantity: `${curreny_name}总计`,
            revenue: `${this.formatCurrency(revenue, curreny_name)}`,
          };
          tableData.push(cn_final_item);
        }
        const data = formatJson(filterVal, tableData);
        exportJson2Excel(tHeader, data, 'GSuite消耗明细表');
      },
      showAWSDetail() {
        const params = {
          bill_id: this.bill.id,
        };
        get_bill_aws_billing_detail(params).then((res) => {
          this.$showAlert('展示详情', {
            config: {
              title: '账单明细',
              columns: [
                { title: '月份', dataIndex: 'month' },
                { title: '账号ID', dataIndex: 'account_id' },
                { title: '项目', dataIndex: 'project' },
                { title: '服务', dataIndex: 'service' },
                { title: 'SKU', dataIndex: 'sku_desc' },
                { title: '金额', dataIndex: 'cost' },
                { title: '用量', dataIndex: 'amount' },
              ],
              immediate: false,
            },
            items: res,
          });
        });
      },
    },
  });
